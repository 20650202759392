import { useEffect } from 'react';
import { MODALS_CONFIG } from './modalConfigs';
import { ModalsSelectors } from './modals.selectors';

function Modals() {
    const { name, open_modal, props } = ModalsSelectors();
    const ModalComponent = MODALS_CONFIG[`${name}`];
    const ModalProps = props || {};

    useEffect(() => {
        if (open_modal && name && ModalProps) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [open_modal, name, ModalProps]);

    return (
        <>
            {open_modal && name && ModalProps && (
                <ModalComponent {...ModalProps} />
            )}
        </>
    );
}

export default Modals;
