import AppModal from '../../../AppModal';
import AppAuthSuccessForm from '../../../AppAuthSuccessForm';
import { useNavigate } from 'react-router-dom';
import AppButton from '../../../AppButton';
import { SuccessIcon } from '../../../../assets/icons/AuthSvg';

const ResetSuccessModal = () => {
    const navigate = useNavigate();

    const handleClick = e => {
        e.preventDefault();
        navigate('/login');
    };

    return (
        <AppModal>
            <AppAuthSuccessForm
                button={
                    <AppButton
                        className="btn-primary-height sm:w-fit w-full"
                        onClick={handleClick}
                        variant="primary"
                    >
                        Go to login page
                    </AppButton>
                }
                Icon={<SuccessIcon />}
                onClick={handleClick}
                text="Your password has been changed"
                title="Success :)"
            />
        </AppModal>
    );
};

export default ResetSuccessModal;
