import {
    ADD_SYMBOL_CLEAR,
    ADD_SYMBOL_FAILED,
    ADD_SYMBOL_REQUEST,
    ADD_SYMBOL_SUCCESS,
    DELETE_SYMBOL_FAILED,
    DELETE_SYMBOL_REQUEST,
    DELETE_SYMBOL_SUCCESS,
    GET_GROUPS_FAILED,
    GET_SYMBOLS_CLEAR,
    GET_SYMBOLS_REQUEST,
    GET_SYMBOLS_SUCCESS,
} from '../actions/actionTypes';

const initialSymbols = {
    loading: false,
    data: [],
};

const initialSymbol = {
    loading: false,
    addedSymbol: '',
    type: 'success',
};

const initialDeletedSymbol = {
    loading: false,
    deletedSymbol: '',
    type: 'success',
};

export function addSymbol(state = initialSymbol, action) {
    switch (action.type) {
        case ADD_SYMBOL_SUCCESS:
            return {
                loading: false,
                addedSymbol: action.data,
                type: 'success',
            };
        case ADD_SYMBOL_FAILED:
            return {
                loading: false,
                addedSymbol: action.error,
                type: 'danger',
            };
        case ADD_SYMBOL_REQUEST:
            return {
                loading: true,
                addedSymbol: '',
                type: '',
            };
        case ADD_SYMBOL_CLEAR:
            return {
                loading: false,
                addedSymbol: '',
                type: '',
            };

        default:
            return state;
    }
}

export function getSymbols(state = initialSymbols, action) {
    switch (action.type) {
        case GET_SYMBOLS_SUCCESS:
            return {
                loading: false,
                data: action.data,
            };
        case GET_GROUPS_FAILED:
            return {
                loading: false,
                data: action.error,
            };
        case GET_SYMBOLS_REQUEST:
            return {
                loading: true,
                data: [],
            };
        case GET_SYMBOLS_CLEAR:
            return {
                loading: false,
                data: [],
            };

        default:
            return state;
    }
}

export function deleteSymbol(state = initialDeletedSymbol, action) {
    switch (action.type) {
        case DELETE_SYMBOL_SUCCESS:
            return {
                deletedSymbol: action.data,
                loading: false,
                type: 'success',
            };
        case DELETE_SYMBOL_FAILED:
            return {
                deletedSymbol: action.error,
                loading: false,
                type: 'danger',
            };
        case DELETE_SYMBOL_REQUEST:
            return {
                deletedSymbol: '',
                loading: true,
                type: '',
            };

        default:
            return state;
    }
}
