import {
    ADD_GROUP_CLEAR,
    ADD_GROUP_FAILED,
    ADD_GROUP_REQUEST,
    ADD_GROUP_SUCCESS,
    DELETE_GROUP_FAILURE,
    DELETE_GROUP_REQUEST,
    DELETE_GROUP_SUCCESS,
    GET_GROUP_CLEAR,
    GET_GROUP_CLEAR_SYMBOLS,
    GET_GROUP_FAILED,
    GET_GROUP_FAILED_SYMBOLS,
    GET_GROUP_REQUEST,
    GET_GROUP_REQUEST_SYMBOLS,
    GET_GROUP_SUCCESS,
    GET_GROUP_SUCCESS_SYMBOLS,
    GET_GROUPS_CLEAR,
    GET_GROUPS_FAILED,
    GET_GROUPS_REQUEST,
    GET_GROUPS_SUCCESS,
    GET_REALIZED_GAIN_FAILED,
    GET_REALIZED_GAIN_REQUEST,
    GET_REALIZED_GAIN_SUCCESS,
    UPDATE_GROUP_CLEAR,
    UPDATE_GROUP_FAILED,
    UPDATE_GROUP_REQUEST,
    UPDATE_GROUP_SUCCESS,
} from '../actions/actionTypes';

const initialGroup = {
    loading: false,
    data: '',
    type: '',
};
const initialGroups = {
    loading: false,
    data: [],
};

const initialState = {
    loading: false,
    groupData: {},
};

const initialSymbolState = {
    loading: false,
    symbolData: [],
};

const initialRealizedGain = {
    loading: false,
    gainData: 0,
    groupGain: {},
};

export function addGroups(state = initialGroup, action) {
    switch (action.type) {
        case ADD_GROUP_SUCCESS:
            return {
                loading: false,
                data: action.data,
                type: 'success',
            };
        case ADD_GROUP_FAILED:
            return {
                loading: false,
                data: action.error,
                type: 'danger',
            };
        case ADD_GROUP_REQUEST:
            return {
                loading: true,
                data: '',
                type: '',
            };
        case ADD_GROUP_CLEAR:
            return {
                loading: false,
                data: '',
                type: '',
            };

        default:
            return state;
    }
}

export function updateGroup(state = initialGroup, action) {
    switch (action.type) {
        case UPDATE_GROUP_SUCCESS:
            return {
                loading: false,
                data: action.data,
                type: 'success',
            };
        case UPDATE_GROUP_FAILED:
            return {
                loading: false,
                data: action.error,
                type: 'danger',
            };
        case UPDATE_GROUP_REQUEST:
            return {
                loading: true,
                data: '',
                type: '',
            };
        case UPDATE_GROUP_CLEAR:
            return {
                loading: false,
                data: '',
                type: '',
            };
        default:
            return state;
    }
}

export function getGroup(state = initialState, action) {
    switch (action.type) {
        case GET_GROUP_SUCCESS:
            return {
                loading: false,
                groupData: action.data,
            };
        case GET_GROUP_FAILED:
            return {
                loading: false,
                groupData: action.error,
            };
        case GET_GROUP_REQUEST:
            return {
                loading: true,
                groupData: {},
            };
        case GET_GROUP_CLEAR:
            return {
                loading: false,
                groupData: {},
            };

        default:
            return state;
    }
}

export function getGroups(state = initialGroups, action) {
    switch (action.type) {
        case GET_GROUPS_SUCCESS:
            return {
                loading: false,
                data: action.data,
            };
        case GET_GROUPS_FAILED:
            return {
                loading: false,
                data: action.error,
            };
        case GET_GROUPS_REQUEST:
            return {
                loading: true,
                data: [],
            };
        case GET_GROUPS_CLEAR:
            return {
                loading: false,
                user: [],
            };

        default:
            return state;
    }
}

export function getGroupSymbol(state = initialSymbolState, action) {
    switch (action.type) {
        case GET_GROUP_SUCCESS_SYMBOLS:
            return {
                loading: false,
                symbolData: action.data,
            };
        case GET_GROUP_FAILED_SYMBOLS:
            return {
                loading: false,
                symbolData: action.error,
            };
        case GET_GROUP_REQUEST_SYMBOLS:
            return {
                loading: true,
                symbolData: [],
            };
        case GET_GROUP_CLEAR_SYMBOLS:
            return {
                loading: false,
                symbolData: [],
            };

        default:
            return state;
    }
}

export function getRealizedGain(state = initialRealizedGain, action) {
    switch (action.type) {
        case GET_REALIZED_GAIN_SUCCESS:
            return {
                loading: false,
                gainData: action.data?.realized_gain,
                groupGain: action.data,
            };
        case GET_REALIZED_GAIN_FAILED:
            return {
                loading: false,
                gainData: action.error,
                groupGain: {},
            };
        case GET_REALIZED_GAIN_REQUEST:
            return {
                loading: true,
                gainData: 0,
                groupGain: {},
            };

        default:
            return state;
    }
}

export function deleteGroup(state = initialGroup, action) {
    switch (action.type) {
        case DELETE_GROUP_SUCCESS:
            return {
                lotsData: action.data,
                loading: false,
                type: 'success',
            };
        case DELETE_GROUP_FAILURE:
            return {
                lotsData: action.error,
                loading: false,
                type: 'danger',
            };
        case DELETE_GROUP_REQUEST:
            return {
                lotsData: '',
                loading: true,
                type: '',
            };

        default:
            return state;
    }
}
