//currencies
export const GET_CURRENCIES_REQUEST = 'GET_CURRENCIES_REQUEST';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_FAILED = 'GET_CURRENCIES_FAILED';
//groups
export const ADD_GROUP_REQUEST = 'ADD_GROUP_REQUEST';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAILED = 'ADD_GROUP_FAILED';
export const ADD_GROUP_CLEAR = 'ADD_GROUP_CLEAR';
export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILED = 'UPDATE_GROUP_FAILED';
export const UPDATE_GROUP_CLEAR = 'UPDATE_GROUP_CLEAR';
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
export const GET_GROUP_REQUEST = 'GET_GROUP_REQUEST';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_FAILED = 'GET_GROUP_FAILED';
export const GET_GROUP_CLEAR = 'GET_GROUP_CLEAR';
export const GET_GROUP_REQUEST_SYMBOLS = 'GET_GROUP_REQUEST_SYMBOLS';
export const GET_GROUP_SUCCESS_SYMBOLS = 'GET_GROUP_SUCCESS_SYMBOLS';
export const GET_GROUP_FAILED_SYMBOLS = 'GET_GROUP_FAILED_SYMBOLS';
export const GET_GROUP_CLEAR_SYMBOLS = 'GET_GROUP_CLEAR_SYMBOLS';
export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILED = 'GET_GROUPS_FAILED';
export const GET_GROUPS_CLEAR = 'GET_GROUPS_CLEAR';
//realized gain
export const GET_REALIZED_GAIN_REQUEST = 'GET_REALIZED_GAIN_REQUEST';
export const GET_REALIZED_GAIN_SUCCESS = 'GET_REALIZED_GAIN_SUCCESS';
export const GET_REALIZED_GAIN_FAILED = 'GET_REALIZED_GAIN_FAILED';
//lots
export const GET_LOTS_LIST_REQUEST = 'GET_LOTS_LIST_REQUEST';
export const GET_LOTS_LIST_SUCCESS = 'GET_LOTS_LIST_SUCCESS';
export const GET_LOTS_LIST_FAILED = 'GET_LOTS_LIST_FAILED';
export const GET_LOTS_LIST_CLEAR = 'GET_LOTS_LIST_CLEAR';
export const DELETE_LOT_REQUEST = 'DELETE_LOT_REQUEST';
export const DELETE_LOT_SUCCESS = 'DELETE_LOT_SUCCESS';
export const DELETE_LOT_FAILED = 'DELETE_LOT_FAILED';
export const ADD_LOT_REQUEST = 'ADD_LOT_REQUEST';
export const ADD_LOT_CLEAR = 'ADD_LOT_CLEAR';
export const ADD_LOT_SUCCESS = 'ADD_LOT_SUCCESS';
export const ADD_LOT_FAILED = 'ADD_LOT_FAILED';
export const SELL_LOT_REQUEST = 'SELL_LOT_REQUEST';
export const SELL_LOT_SUCCESS = 'SELL_LOT_SUCCESS';
export const SELL_LOT_FAILED = 'SELL_LOT_FAILED';
export const SELL_LOT_CLEAR = 'SELL_LOT_CLEAR';
//cash
export const GET_CASH_REQUEST = 'GET_CASH_REQUEST';
export const GET_CASH_SUCCESS = 'GET_CASH_SUCCESS';
export const GET_CASH_FAILED = 'GET_CASH_FAILED';
// applications
export const DELETE_APPLICATION_REQUEST = 'DELETE_APPLICATION_REQUEST';
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS';
export const DELETE_APPLICATION_FAILED = 'DELETE_APPLICATION_FAILED';
export const UPDATE_APPLICATION_REQUEST = 'UPDATE_APPLICATION_REQUEST';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_FAILED = 'UPDATE_APPLICATION_FAILED';
export const GET_APPLICATION_REF_SUCCESS = 'GET_APPLICATION_REF_SUCCESS';
// notifications
export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED';
export const UPDATE_NOTIFICATIONS_REQUEST = 'UPDATE_NOTIFICATIONS_REQUEST';
export const UPDATE_NOTIFICATIONS_SUCCESS = 'UPDATE_NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATIONS_FAILED = 'UPDATE_NOTIFICATIONS_FAILED';
// symbols
export const GET_SYMBOLS_REQUEST = 'GET_SYMBOLS_REQUEST';
export const GET_SYMBOLS_SUCCESS = 'GET_SYMBOLS_SUCCESS';
export const GET_SYMBOLS_FAILED = 'GET_SYMBOLS_FAILED';
export const ADD_SYMBOL_REQUEST = 'ADD_SYMBOL_REQUEST';
export const ADD_SYMBOL_SUCCESS = 'ADD_SYMBOL_SUCCESS';
export const ADD_SYMBOL_FAILED = 'ADD_SYMBOL_FAILED';
export const ADD_SYMBOL_CLEAR = 'ADD_SYMBOL_CLEAR';
export const GET_SYMBOLS_CLEAR = 'GET_SYMBOLS_CLEAR';
export const DELETE_SYMBOL_REQUEST = 'DELETE_SYMBOL_REQUEST';
export const DELETE_SYMBOL_SUCCESS = 'DELETE_SYMBOL_SUCCESS';
export const DELETE_SYMBOL_FAILED = 'DELETE_SYMBOL_FAILED';
//chart
export const GET_PORTFOLIO_CHART_REQUEST = 'GET_PORTFOLIO_CHART_REQUEST';
export const GET_PORTFOLIO_CHART_SUCCESS = 'GET_PORTFOLIO_CHART_SUCCESS';
export const GET_PORTFOLIO_CHART_FAIL = 'GET_PORTFOLIO_CHART_FAIL';
//transactions
export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAIL = 'GET_TRANSACTIONS_FAIL';
export const GET_TRANSACTIONS_CLEAR = 'GET_TRANSACTIONS_CLEAR';
export const DELETE_TRANSACTION_REQUEST = 'DELETE_TRANSACTION_REQUEST';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAILED = 'DELETE_TRANSACTION_FAILED';
