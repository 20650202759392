import {
    addGroups,
    getGroup,
    getGroups,
    getGroupSymbol,
    getRealizedGain,
    updateGroup,
} from '../../manager/reducers';
import {
    allInstallments,
    allWithdrawals,
    installments,
    withdrawals,
} from './investments.reducer';
import { loginReducer } from './login.reducer';
import { passwordRecoveryReducer } from './passwordRecovery.reducer';
import { resetPasswordReducer } from './resetPasswort.reducer';
import { modals } from './modals.reducer';
import { userInfo } from './userInfo.reducer';
import {
    createdUser,
    deleteUser,
    newUser,
    updateProfile,
    userData,
    userHolding,
    users,
} from './user.reducer';
import { changeEmail } from './changeEmail.reducer';
import { changePassword } from './changePassword.reducer';
import { confirmPassword } from './confirmPassword.reducer';
import { addSymbol, deleteSymbol, getSymbols } from '../../manager/reducers';
import {
    addLot,
    deleteLots,
    getCash,
    getLotsList,
    sellLot,
} from '../../manager/reducers';
import {
    countryList,
    getTotalFund,
    industryList,
    styleGroupList,
} from './fund.reducer';
import { cashIn, cashOut } from './cash.reducer';
import { storedData } from './storedData.reducer';
import { chart } from './chart.reducer';
import { topHoldings } from './main.reducer';
import { getCurrencies } from '../../manager/reducers';
import {
    createApplication,
    createPasswordReducer,
} from '../../investor/reducers';
import { getApplications } from './application.reducer';
import {
    getApplicationRef,
    getNotifications,
    updateNotifications,
} from '../../manager/reducers';

export {
    loginReducer,
    modals,
    passwordRecoveryReducer,
    resetPasswordReducer,
    userInfo,
    userData,
    changeEmail,
    changePassword,
    confirmPassword,
    newUser,
    users,
    installments,
    withdrawals,
    updateProfile,
    deleteUser,
    createdUser,
    allInstallments,
    allWithdrawals,
    addGroups,
    updateGroup,
    getGroup,
    getGroupSymbol,
    getGroups,
    getSymbols,
    addSymbol,
    getLotsList,
    deleteLots,
    addLot,
    sellLot,
    deleteSymbol,
    getCash,
    getRealizedGain,
    getTotalFund,
    cashIn,
    cashOut,
    userHolding,
    industryList,
    countryList,
    styleGroupList,
    storedData,
    chart,
    topHoldings,
    createPasswordReducer,
    getCurrencies,
    createApplication,
    getApplications,
    getNotifications,
    getApplicationRef,
    updateNotifications,
};
