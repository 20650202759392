import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { debounce } from '../../../../helpers/debounce';
import AppModal from '../../../AppModal';
import UpdateForm from '../../ModalFormComponents/updateForm';

const DeleteDataModal = ({ onSubmit, message }) => {
    const { handleSubmit } = useForm({ mode: 'onChange' });
    const optimizedFn = useCallback(debounce(onSubmit), []);
    return (
        <AppModal>
            <UpdateForm
                btnText="Yes, delete"
                handleSubmit={handleSubmit}
                onSubmit={optimizedFn}
                title="Hey!"
            >
                <p className="font-normal text-[15px] text-center text-[#0B1F35] font-roboto">
                    {`Do you want to delete this ${message}?`}
                </p>
            </UpdateForm>
        </AppModal>
    );
};

export default DeleteDataModal;
