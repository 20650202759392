import dayjs from 'dayjs';
import AppInput from '../AppInput';

export function AppDatePicker({
    id,
    rest,
    handleChange,
    register,
    error = {},
    errorMessage = '',
}) {
    const currentDate = new Date();
    const date = dayjs(currentDate).format('YYYY-MM-DD');
    const minDate = '1900-01-01';

    return (
        <AppInput
            error={error}
            errorMessage={errorMessage}
            id={id}
            label="Date"
            max={date}
            min={minDate}
            optionObject={{
                onChange: handleChange,
                validate: value => {
                    const dateFromJson = new Date(value);
                    const minDate = new Date('1900-01-01');
                    return (
                        (dateFromJson <= currentDate &&
                            dateFromJson >= minDate) ||
                        'Please choose match value'
                    );
                },
            }}
            register={register}
            type="date"
            {...rest}
        />
    );
}
