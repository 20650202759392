import { userService } from '../../common/services';
import { fundActions } from '../../common/actions/fund.action';
import {
    ADD_LOT_CLEAR,
    ADD_LOT_FAILED,
    ADD_LOT_REQUEST,
    ADD_LOT_SUCCESS,
    DELETE_LOT_FAILED,
    DELETE_LOT_REQUEST,
    DELETE_LOT_SUCCESS,
    GET_CASH_FAILED,
    GET_CASH_REQUEST,
    GET_CASH_SUCCESS,
    GET_LOTS_LIST_CLEAR,
    GET_LOTS_LIST_FAILED,
    GET_LOTS_LIST_REQUEST,
    GET_LOTS_LIST_SUCCESS,
    SELL_LOT_CLEAR,
    SELL_LOT_FAILED,
    SELL_LOT_REQUEST,
    SELL_LOT_SUCCESS,
} from './actionTypes';
import { groupsActions } from './groups.actions';
import { modalActions } from '../../common/actions/modal.actions';

export const lotsActions = {
    getLotsList,
    clearLotsList,
    deleteLot,
    addLot,
    sellLot,
    addLotClear,
    sellLotClear,
    getCash,
};

function getLotsList(symbol) {
    const endpoint = `/funds/groups/symbols/${symbol}/lots`;
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_LOTS_LIST_REQUEST };
    }
    function success(data) {
        return { type: GET_LOTS_LIST_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_LOTS_LIST_FAILED, error };
    }
}

function deleteLot(lotId, symbol, groupId) {
    return dispatch => {
        dispatch(request());
        userService.DeleteInfo(`/funds/groups/lots/${lotId}`).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: { button: true },
                    }),
                );
                dispatch(groupsActions.getGroupSymbols(groupId));
                dispatch(lotsActions.getLotsList(symbol));
                dispatch(groupsActions.getRealizedGain(groupId));
                dispatch(groupsActions.getGroup(groupId));
                dispatch(groupsActions.getGroups());
                dispatch(fundActions.getTotalFund());
            } else {
                dispatch(fail(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'forbidden',
                        props: { text: `${data.data.message}` },
                    }),
                );
            }
        });
    };
    function request() {
        return { type: DELETE_LOT_REQUEST };
    }
    function success(data) {
        return { type: DELETE_LOT_SUCCESS, data };
    }
    function fail(error) {
        return { type: DELETE_LOT_FAILED, error };
    }
}

function clearLotsList() {
    return { type: GET_LOTS_LIST_CLEAR };
}

function addLot(lotData, groupId) {
    const endpoint = '/funds/groups/lots';
    return dispatch => {
        dispatch(request());
        userService.PostInfo(endpoint, lotData).then(data => {
            if (data.status === 200) {
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: { button: true },
                    }),
                );
                dispatch(groupsActions.getGroupSymbols(groupId));
                dispatch(lotsActions.getLotsList(lotData.company_symbol));
                dispatch(groupsActions.getRealizedGain(groupId));
                dispatch(groupsActions.getGroup(groupId));
                dispatch(groupsActions.getGroups());
                dispatch(fundActions.getTotalFund());
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: ADD_LOT_REQUEST };
    }
    function success(data) {
        return { type: ADD_LOT_SUCCESS, data };
    }
    function fail(error) {
        return { type: ADD_LOT_FAILED, error };
    }
}

function addLotClear() {
    return { type: ADD_LOT_CLEAR };
}

function sellLot(lotData, groupId) {
    const endpoint = '/funds/groups/lots-sell';
    return dispatch => {
        dispatch(request());
        userService.PostInfo(endpoint, lotData).then(data => {
            if (data.status === 200) {
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: { button: true },
                    }),
                );
                dispatch(groupsActions.getGroupSymbols(groupId));
                dispatch(lotsActions.getLotsList(lotData.company_symbol));
                dispatch(groupsActions.getRealizedGain(groupId));
                dispatch(groupsActions.getGroup(groupId));
                dispatch(groupsActions.getGroups());
                dispatch(fundActions.getTotalFund());
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: SELL_LOT_REQUEST };
    }
    function success(data) {
        return { type: SELL_LOT_SUCCESS, data };
    }
    function fail(error) {
        return { type: SELL_LOT_FAILED, error };
    }
}

function sellLotClear() {
    return { type: SELL_LOT_CLEAR };
}

function getCash() {
    const endpoint = '/cash';
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results?.cash.holding_sum));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_CASH_REQUEST };
    }
    function success(data) {
        return { type: GET_CASH_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_CASH_FAILED, error };
    }
}
