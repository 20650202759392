import { UserData } from '../../../../store/project/project.selectors';
import AppModal from '../../../AppModal';
import AppForm from '../../../AppForm';
import AppInput from '../../../AppInput';
import AppButton from '../../../AppButton';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../../actions/modal.actions';
import { useForm } from 'react-hook-form';
import { personalSettingsActions } from '../../../../actions/pesonalSettings.actions';

const PersonalDataModal = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: 'onChange' });
    const dispatch = useDispatch();
    const { user } = UserData();

    const handleBack = e => {
        e.preventDefault();
        dispatch(modalActions.close_modal());
    };

    const onSubmit = userData => {
        return dispatch(
            personalSettingsActions.changePersonalData(userData, `${user.id}`),
        );
    };
    return (
        <AppModal>
            <AppForm
                className="max-w-[400px] w-full"
                onSubmit={handleSubmit(onSubmit)}
                title="Personal data"
            >
                <div className="flex flex-col gap-3">
                    <AppInput
                        id="surname"
                        label="Surname"
                        register={register}
                        type="text"
                        value={user?.surname}
                    />
                    <AppInput
                        id="name"
                        label="Name"
                        register={register}
                        type="text"
                        value={user?.name}
                    />
                </div>
                <div className="flex flex-wrap items-center md:justify-self-auto justify-center gap-2.5">
                    <AppButton
                        className="btn-small-height sm:flex hidden"
                        onClick={handleBack}
                        type=""
                        variant="btn-secondary"
                    >
                        Back
                    </AppButton>
                    <AppButton
                        className="btn-small-height sm:w-fit w-full"
                        type=""
                        variant="primary"
                    >
                        Save
                    </AppButton>
                    <AppButton
                        className="btn-small-height sm:hidden w-full"
                        onClick={handleBack}
                        type=""
                        variant="btn-secondary"
                    >
                        Back
                    </AppButton>
                </div>
            </AppForm>
        </AppModal>
    );
};

export default PersonalDataModal;
