import AppModal from '../../../AppModal';
import AppForm from '../../../AppForm';
import AppButton from '../../../AppButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../../actions/modal.actions';
import { useForm } from 'react-hook-form';
import { authActions } from '../../../../actions/auth.actions';

const SignOutAllDevices = ({ text = '', btnText = 'Save' }) => {
    const { handleSubmit } = useForm({ mode: 'onChange' });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleBack = e => {
        e.preventDefault();
        dispatch(modalActions.close_modal());
    };

    const onSubmit = () => {
        return dispatch(authActions.logout(navigate));
    };

    return (
        <AppModal>
            <AppForm
                className="max-w-[400px] w-full"
                onSubmit={handleSubmit(onSubmit)}
                text={text}
                title="Hey!"
            >
                <div className="flex flex-wrap items-center md:justify-self-auto justify-center p-2.5 gap-2.5">
                    <AppButton
                        className="btn-small-height sm:flex hidden"
                        onClick={handleBack}
                        type=""
                        variant="btn-secondary"
                    >
                        Back
                    </AppButton>
                    <AppButton
                        className="btn-small-height sm:w-fit w-full"
                        type=""
                        variant="primary"
                    >
                        {btnText}
                    </AppButton>
                    <AppButton
                        className="btn-small-height sm:hidden w-full"
                        onClick={handleBack}
                        type=""
                        variant="btn-secondary"
                    >
                        Back
                    </AppButton>
                </div>
            </AppForm>
        </AppModal>
    );
};

export default SignOutAllDevices;
