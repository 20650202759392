export const APPLICATION_OPERATION_TYPE = [
    {
        value: 'Cash In',
        label: 'New investment',
        cash_type: 'Sum of investing, $',
        direction: 'Cash In',
    },
    {
        value: 'Cash Out',
        label: 'Request cash',
        cash_type: 'Sum of cash, $',
        direction: 'Cash Out',
    },
];
export const DURATION_LIST = [
    {
        value: 'In a week',
        label: 'In a week',
    },
    {
        value: 'In two weeks',
        label: 'In two weeks',
    },
    {
        value: 'In a month',
        label: 'In a month',
    },
];
