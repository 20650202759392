import { combineReducers } from 'redux';
import investorReducer from '../../investor/reducers/combineReducer';
import managerReducer from '../../manager/reducers/combineRecuser';
import { modals } from '../reducers';
import projectReducer from '../reducers/combineResducer';

const reducer = combineReducers({
    manager: managerReducer,
    investor: investorReducer,
    modals: modals,
    project: projectReducer,
});

export default reducer;
