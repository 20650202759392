import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { applicationsActions } from '../../../actions/applications.actions';
import { fundActions } from '../../../actions/fund.action';
import { dayChartActions } from '../../../../manager/actions/chart.actions';
import { groupsActions } from '../../../../manager/actions/groups.actions';
import { currencyActions } from '../../../../investor/actions/currency.actions';
import { lotsActions } from '../../../../manager/actions/lots.actions';
import { notificationsActions } from '../../../../manager/actions/notification.actions';
import { ROLES } from '../../../routes/costants';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { userActions } from '../../../actions/user.actions';
import { personalSettingsActions } from '../../../actions/pesonalSettings.actions';
import useAuth from '../../../hooks/useAuth';
import { TIMESPAN } from '../../HistoricalChart/chartConstants';

const ProtectedRoutes = ({ allowedRoles }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const verify = searchParams.get('verify_url');
    const [token, role, id] = useAuth();

    useEffect(() => {
        if (verify) {
            const url = verify.substring(
                verify.indexOf('/api/email/verify') + 4,
            );
            dispatch(personalSettingsActions.verifyEmail(url));
        }
    }, [verify]);

    useEffect(() => {
        if (token) {
            dispatch(userActions.getUser(id));
            dispatch(applicationsActions.getApplications());
            dispatch(fundActions.getTotalFund());
            dispatch(userActions.getUsers());
            dispatch(fundActions.getCountryList());
            dispatch(fundActions.getIndustryList());
            dispatch(fundActions.getStyleGroupList());
            if (role === ROLES.MANAGER) {
                dispatch(groupsActions.getGroups());
                dispatch(lotsActions.getCash());
                dispatch(
                    dayChartActions.getDayChart({
                        timespan: TIMESPAN.DAY_1,
                        adiam: 1,
                        nasdaq: 0,
                    }),
                );
                dispatch(notificationsActions.getNotifications());
            } else if (role === ROLES.INVESTOR) {
                dispatch(userActions.getUserHolding(id));
                dispatch(currencyActions.getCurrencyData());
            }
        }
    }, []);

    return allowedRoles?.includes(role) ? (
        <Outlet />
    ) : token ? (
        location.pathname === '/' && role === ROLES.MANAGER ? (
            <Navigate replace state={{ from: location }} to="/admin" />
        ) : (
            <Navigate replace state={{ from: location }} to="/unauthorized" />
        )
    ) : (
        <Navigate replace state={{ from: location }} to="/login" />
    );
};

export default ProtectedRoutes;
