import classnames from 'classnames';
import React from 'react';
import './index.css';

export default function AppBadge({
    disabled = false,
    className = '',
    icon,
    variant = 'primary',
    children,
    size = '',
}) {
    const classes = classnames([
        'app_badge',
        variant,
        className,
        size,
        {
            'bg-gray-100 text-gray-400': disabled,
        },
    ]);

    return (
        <div className={`${classes} ${variant}  text-[${size}px]`}>
            <>
                {icon}
                {children}
            </>
        </div>
    );
}
