export const getFirstLetter = name => {
    let initials = '';
    if (name) {
        const names = name.split(' ');
        if (names.length === 1) {
            initials = names[0].substring(0, 1).toUpperCase();
        } else if (names.length > 1) {
            names.forEach((n, i) => {
                initials += names[i].substring(0, 1).toUpperCase();
            });
        }
    }
    return initials;
};
