import {
    ADD_USER_CLEAR,
    ADD_USER_FAILURE,
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    DELETE_USER_FAILURE,
    DELETE_USER_FORBIDDEN,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    GET_CREATED_USER_CLEAR,
    GET_CREATED_USER_FAILED,
    GET_CREATED_USER_REQUEST,
    GET_CREATED_USER_SUCCESS,
    GET_USER_CLEAR,
    GET_USER_FAILED,
    GET_USER_HOLDING_CLEAR,
    GET_USER_HOLDING_FAILED,
    GET_USER_HOLDING_REQUEST,
    GET_USER_HOLDING_SUCCESS,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USERS_FAILED,
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    UPDATE_USER_PROFILE_FAILED,
    UPDATE_USER_PROFILE_REQUEST,
    UPDATE_USER_PROFILE_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
    loading: false,
    user: {},
};

const initialHoldingState = {
    loading: false,
    holdingData: {},
};

const initialUserState = {
    loading: false,
    user: '',
    type: '',
};

const initialUsersState = {
    loading: false,
    user: [],
};

const profileInitialState = {
    loading: false,
    data: '',
    type: '',
};

export function userData(state = initialState, action) {
    switch (action.type) {
        case GET_USER_SUCCESS:
            return {
                user: action.user,
                loading: false,
            };
        case GET_USER_FAILED:
            return {
                loading: false,
                user: action.error,
            };
        case GET_USER_REQUEST:
            return {
                loading: true,
                user: {},
            };
        case GET_USER_CLEAR:
            return {
                loading: false,
                user: {},
            };

        default:
            return state;
    }
}
export function newUser(state = initialUserState, action) {
    switch (action.type) {
        case ADD_USER_SUCCESS:
            return {
                user: action.user,
                loading: false,
                type: 'success',
            };
        case ADD_USER_FAILURE:
            return {
                loading: false,
                user: action.error,
                type: 'danger',
            };
        case ADD_USER_REQUEST:
            return {
                loading: true,
                user: '',
                type: '',
            };
        case ADD_USER_CLEAR:
            return {
                loading: false,
                user: '',
                type: '',
            };

        default:
            return state;
    }
}
export function users(state = initialUsersState, action) {
    switch (action.type) {
        case GET_USERS_SUCCESS:
            return {
                user: action.user,
                loading: false,
            };
        case GET_USERS_FAILED:
            return {
                loading: false,
                user: action.error,
            };
        case GET_USERS_REQUEST:
            return {
                loading: true,
                user: [],
            };

        default:
            return state;
    }
}
export function updateProfile(state = profileInitialState, action) {
    switch (action.type) {
        case UPDATE_USER_PROFILE_SUCCESS:
            return {
                data: action.data,
                loading: false,
                type: 'success',
            };
        case UPDATE_USER_PROFILE_FAILED:
            return {
                loading: false,
                data: action.error,
                type: 'danger',
            };
        case UPDATE_USER_PROFILE_REQUEST:
            return {
                loading: true,
                data: '',
                type: '',
            };

        default:
            return state;
    }
}
export function deleteUser(state = profileInitialState, action) {
    switch (action.type) {
        case DELETE_USER_SUCCESS:
            return {
                user: action.user,
                loading: false,
                type: 'success',
            };
        case DELETE_USER_FAILURE:
            return {
                loading: false,
                user: action.error,
                type: 'danger',
            };
        case DELETE_USER_REQUEST:
            return {
                loading: true,
                user: '',
                type: '',
            };
        case DELETE_USER_FORBIDDEN:
            return {
                loading: true,
                user: '',
                type: 'forbidden',
            };

        default:
            return state;
    }
}
export function createdUser(state = initialState, action) {
    switch (action.type) {
        case GET_CREATED_USER_SUCCESS:
            return {
                user: action.user,
                loading: false,
            };
        case GET_CREATED_USER_FAILED:
            return {
                loading: false,
                user: action.error,
            };
        case GET_CREATED_USER_REQUEST:
            return {
                loading: true,
                user: {},
            };
        case GET_CREATED_USER_CLEAR:
            return {
                loading: false,
                user: {},
            };

        default:
            return state;
    }
}

export function userHolding(state = initialHoldingState, action) {
    switch (action.type) {
        case GET_USER_HOLDING_SUCCESS:
            return {
                holdingData: action.data,
                loading: false,
            };
        case GET_USER_HOLDING_FAILED:
            return {
                loading: false,
                holdingData: action.error,
            };
        case GET_USER_HOLDING_REQUEST:
            return {
                loading: true,
                holdingData: {},
            };
        case GET_USER_HOLDING_CLEAR:
            return {
                loading: false,
                holdingData: {},
            };

        default:
            return state;
    }
}
