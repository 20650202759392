import classNames from 'classnames';
import './index.css';

export default function AppTooltip({
    message,
    children,
    variant = 'tooltip-left-bottom',
    classname,
    onClick,
    showTooltip,
    wrapperRef,
    onFocus,
}) {
    const classes = classNames('tooltip', `${variant} ${classname}`);
    return (
        <div
            className="relative inline-block"
            onClick={onClick}
            ref={wrapperRef}
        >
            <div onClick={onFocus}>{children}</div>
            {showTooltip && <span className={`${classes}`}>{message}</span>}
        </div>
    );
}
