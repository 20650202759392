import { GET_STORED_DATA } from '../actions/actionTypes';

const initialState = {
    loading: false,
    type: '',
    token: '',
    role: 'Public_user',
    id: '',
};
export function storedData(state = initialState, action) {
    switch (action.type) {
        case GET_STORED_DATA:
            return {
                userInfo: action.data,
            };

        default:
            return state;
    }
}
