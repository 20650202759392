import { useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import { cashActions } from '../../actions/cash.action';
import { modalActions } from '../../actions/modal.actions';
import { DeleteIcon } from '../../assets/icons/SettingsSvg';
import { preventValue } from '../../helpers/util';
import { CashIn, Users } from '../../store/project/project.selectors';
import AppButton from '../AppButton';
import { INVESTMENT_TYPE } from '../../../manager/components/AppCash/cashConstants';
import AppForm from '../AppForm';
import { AppInputFromArray } from '../AppInputFromArray';
import AppSelect from '../AppSelect';

const AppCashInForm = ({
    children,
    selectedInvestmentType,
    text = '',
    title = '',
}) => {
    const {
        register,
        handleSubmit,
        control,
        clearErrors,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            cash: [
                {
                    user_id: null,
                    amount: '',
                    created_at: '',
                },
            ],
        },
        mode: 'onChange' || '',
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'cash',
    });

    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const { user } = Users();
    const { investmentData, type } = CashIn();
    const [errorLength, setErrorLength] = useState(false);
    const watchFieldArray = watch('cash');

    useEffect(() => {
        if (investmentData && type === 'danger') {
            setErrorMessage(investmentData);
        }
    }, [investmentData, type]);

    useEffect(() => {
        if (investmentData && type === 'danger') {
            setErrorMessage('');
        }
    }, []);

    useEffect(() => {
        if (Object.keys(errors) && errors?.cash?.length > 0) {
            setErrorLength(true);
        }
    }, [errors.cash]);

    const onSubmit = userData => {
        return dispatch(
            cashActions.cashIn({ ...userData, type: selectedInvestmentType }),
        );
    };
    const handleBack = () => {
        dispatch(modalActions.close_modal());
    };

    const handleSelect = (selectedOption, index) => {
        const newArray = [...watchFieldArray];
        newArray[index].user_id = selectedOption;
        clearErrors(`cash[${index}].user_id`);
        setSelectedOptions(prevOptions => {
            const newOptions = [...prevOptions];
            newOptions[index] = selectedOption;
            return newOptions;
        });
        setErrorMessage('');
    };

    const userList = useMemo(
        () =>
            user.length > 0 &&
            user.map(item => {
                return {
                    value: item.id,
                    label: item.full_name,
                };
            }),
        [user],
    );

    const handleChange = () => {
        setErrorMessage('');
    };

    const preventMinus = e => {
        preventValue(e);
    };

    const handleAdd = e => {
        e.preventDefault();
        append({
            user_id: null,
            amount: '',
            created_at: '',
        });
    };

    return (
        <AppForm
            className="max-w-[400px] w-full max-h-[calc(100vh_-_210px)]"
            onSubmit={handleSubmit(onSubmit)}
            text={text}
            title={title}
        >
            <>
                {children}
                <div className="flex flex-col gap-[12px]">
                    {fields.map((field, index) => {
                        return (
                            <>
                                {index > 0 && (
                                    <span className="flex justify-end">
                                        <button onClick={() => remove(index)}>
                                            <DeleteIcon />
                                        </button>
                                    </span>
                                )}
                                {selectedInvestmentType ===
                                    INVESTMENT_TYPE.Installment && (
                                    <AppSelect
                                        errorMessage={
                                            errorLength &&
                                            errors.cash?.[`${index}`]?.user_id
                                                ?.message
                                        }
                                        id={`cash[${index}].user_id`}
                                        list={userList}
                                        {...register(`cash[${index}].user_id`, {
                                            required: 'This field is required.',
                                        })}
                                        onChange={selectedCash => {
                                            handleSelect(selectedCash, index);
                                        }}
                                        placeholder="Choose user"
                                        selectedValue={selectedOptions}
                                    />
                                )}
                                <AppInputFromArray
                                    errorMessage={
                                        errorLength &&
                                        errors.cash?.[`${index}`]?.amount
                                            ?.message
                                    }
                                    id={`cash.${index}.amount`}
                                    label="Sum of invest, $"
                                    min="0"
                                    onKeyPress={preventMinus}
                                    optionObject={{
                                        onChange: handleChange,
                                    }}
                                    register={register}
                                    step="any"
                                    type="number"
                                />
                                <AppInputFromArray
                                    errorMessage={
                                        errorLength &&
                                        errors.cash?.[`${index}`]?.created_at
                                            ?.message
                                    }
                                    handleChange={handleChange}
                                    id={`cash.${index}.created_at`}
                                    register={register}
                                    type="date"
                                />
                            </>
                        );
                    })}
                    {errorMessage && (
                        <p className="text-sm text-[#C90000] dark:text-red-500 font-[500]">
                            {errorMessage}
                        </p>
                    )}
                    <p className="font-bold text-[15px] text-black font-roboto">
                        <button onClick={handleAdd}>Add another one</button>
                    </p>
                </div>
                <div className="flex flex-wrap items-center md:justify-self-auto justify-center p-2.5 gap-2.5 h-[79px]">
                    <AppButton
                        className="max-w-[134px] btn-small-height"
                        onClick={handleBack}
                        type=""
                        variant="btn-secondary"
                    >
                        Back
                    </AppButton>
                    <AppButton
                        className="max-w-fit btn-small-height"
                        // disabled={disable}
                        type=""
                        variant="primary"
                    >
                        Save
                    </AppButton>
                </div>
            </>
        </AppForm>
    );
};

export default AppCashInForm;
