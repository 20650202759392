import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { storedDataActions } from '../actions/storedData.actions';
import { StoredData } from '../store/project/project.selectors';

const useAuth = () => {
    const dispatch = useDispatch();
    const { userInfo } = StoredData();

    useEffect(() => {
        dispatch(storedDataActions.getStoredUser());
    }, []);

    const token = useMemo(() => userInfo?.token, [userInfo]);
    const role = useMemo(() => userInfo?.role, [userInfo]);
    const id = useMemo(() => userInfo?.id, [userInfo]);

    return [token, role, id];
};
export default useAuth;
