import AdminNavbar from '../../manager/components/AdminNavbar';
import InvestorNavbar from '../../investor/components/InvestorNavbar';
import useAuth from '../hooks/useAuth';
import { ROLES } from '../routes/costants';

export default function ({ children }) {
    const [token, role, id] = useAuth();

    return (
        <div className={'flex flex-col  gap-8 md:h-screen'}>
            {token && role && role === ROLES.MANAGER && (
                <div className="sm:px-[60px] px-4">
                    <AdminNavbar />
                </div>
            )}
            {token && role && role === ROLES.INVESTOR && (
                <div className="sm:px-[60px] px-4">
                    <InvestorNavbar />
                </div>
            )}
            {children}
        </div>
    );
}
