import {
    LOG_IN_CLEAR,
    LOG_IN_FAILED,
    LOG_IN_REQUEST,
    LOG_IN_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
    loading: false,
    type: '',
    token: '',
    role: '',
    id: '',
};
export function userInfo(state = initialState, action) {
    switch (action.type) {
        case LOG_IN_SUCCESS:
            return {
                loading: false,
                type: 'success',
                token: action.token,
                role: action.role,
                id: action.data.id,
            };
        case LOG_IN_FAILED:
            return {
                loading: false,
                type: 'danger',
                token: '',
                role: '',
                id: '',
            };
        case LOG_IN_REQUEST:
            return {
                loading: true,
                type: 'loading',
                token: '',
                role: '',
            };
        case LOG_IN_CLEAR:
            return {
                loading: false,
                type: 'success',
                token: '',
                role: '',
                id: '',
            };

        default:
            return state;
    }
}
