import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { symbolActions } from '../../../../../manager/actions/symbols.actions';
import AppModal from '../../../AppModal';
import UpdateForm from '../../ModalFormComponents/updateForm';

const DeleteSymbolModal = ({ symbol, groupId }) => {
    const dispatch = useDispatch();
    const { handleSubmit } = useForm({ mode: 'onChange' });

    const onSubmit = () => {
        return dispatch(dispatch(symbolActions.deleteSymbol(symbol, groupId)));
    };

    return (
        <AppModal>
            <UpdateForm
                btnText="Yes, delete"
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                title="Hey!"
            >
                <p className="font-normal text-[15px] text-center text-[#0B1F35] font-roboto">
                    Are you sure, that you want to delete
                    <strong>{` ${symbol}`}</strong> ?
                </p>
            </UpdateForm>
        </AppModal>
    );
};

export default DeleteSymbolModal;
