import { modalActions } from '../../../actions/modal.actions';
import { CloseButton, MenuIcon } from '../../../assets/icons/NavbarSvg';
import { UserData } from '../../../store/project/project.selectors';
import AppLogo from '../../AppLogo';
import AdminActionPopup from '../../../../manager/components/AdminNavbar/AdminActionPopup';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import AppAvatar from '../../AppAvatar';
import { useLocation, useNavigate } from 'react-router-dom';
import TooltipWithClick from '../../AppTooltip/TooltipWithClick';
import useAuth from '../../../hooks/useAuth';
export default function ({
    children,
    notification = null,
    logoUrl,
    settingUrl = '/settings',
}) {
    const navRef = useRef();
    const navigate = useNavigate();
    const { user, loading } = UserData();
    const [userInfo, setUserInfo] = useState({});
    const location = useLocation();
    const dispatch = useDispatch();
    const [url, setUrl] = useState(null);
    const [token, role, id] = useAuth();

    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    useEffect(() => {
        if (user) {
            setUserInfo(user);
        }
    }, [user]);

    const showNavbar = () => {
        navRef.current.classList.toggle('responsive_nav');
    };

    const handleSignOut = () => {
        return dispatch(
            modalActions.open_modal({
                name: 'signOutAllDevices',
                props: { text: 'Are you sure to logout?', btnText: 'Logout' },
            }),
        );
    };

    const handleManageSettings = e => {
        e.preventDefault();
        navigate(settingUrl);
    };

    return (
        <header>
            <nav className="dark:bg-gray-900 h-[69.18px] pt-4 pb-0 flex justify-between">
                <div className="nav-menu" ref={navRef}>
                    <div className="flex w-full justify-end items-start gap-2.5">
                        <span onClick={showNavbar}>
                            <CloseButton />
                        </span>
                    </div>
                    <div className="flex items-center gap-2">
                        <AppAvatar
                            height="h-[38px]"
                            name={userInfo.name}
                            width="w-[38px]"
                        />
                        <div className="flex items-end flex-col">
                            <p className="font-roboto font-bold sm:text-[15px] text-base">
                                Hello, {userInfo.name}
                            </p>
                            <p className="font-roboto font-normal sm:text-[8px] text-xs">
                                {role}
                            </p>
                        </div>
                    </div>
                    <div
                        className="flex flex-col items-start gap-3 text-black sm:text-[15px] text-base font-bold font-roboto"
                        onClick={showNavbar}
                    >
                        {children}
                        <button onClick={handleManageSettings}>Settings</button>
                        <button onClick={handleSignOut}>Logout</button>
                    </div>
                </div>
                <div className="flex sm:gap-[60px]">
                    <AppLogo to={logoUrl} />
                    <div className="sm:flex hidden sm:flex-row flex-col sm:gap-[36px] gap-2 items-center">
                        {children}
                    </div>
                </div>
                <span className="sm:hidden block" onClick={showNavbar}>
                    <MenuIcon />
                </span>
                {!userInfo?.name && !loading ? null : (
                    <div className="sm:flex hidden gap-[12px] items-center">
                        {notification}
                        <div className="flex flex-row justify-center">
                            <AppAvatar
                                height="h-[37px]"
                                name={userInfo.name}
                                width="w-[37px]"
                            />
                        </div>
                        <TooltipWithClick
                            classname="right-[-12px]"
                            message={
                                <AdminActionPopup settingUrl={settingUrl} />
                            }
                            variant="tooltip-left-bottom"
                        >
                            <button className="flex items-end flex-col">
                                <p className="font-roboto font-bold text-[15px]">
                                    Hello, {userInfo.name}
                                </p>
                                <p className="font-roboto font-normal text-[8px]">
                                    {role}
                                </p>
                            </button>
                        </TooltipWithClick>
                        <div className=" w-px h-[34px] bg-black"></div>
                        <button
                            className="text-black text-[15px] not-italic font-bold font-roboto"
                            onClick={handleSignOut}
                        >
                            Logout
                        </button>
                    </div>
                )}
            </nav>
        </header>
    );
}
