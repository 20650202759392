import { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { applicationActions } from '../../../../../investor/actions/application.actions';
import { modalActions } from '../../../../actions/modal.actions';
import AppModal from '../../../AppModal';
import AppSelect from '../../../AppSelect';
import UpdateForm from '../../ModalFormComponents/updateForm';

const ApplicationModal = ({ title, operationList }) => {
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        mode: 'onChange' || '',
    });
    const dispatch = useDispatch();
    const [errorLength, setErrorLength] = useState(false);
    const { field } = useController({
        name: 'operation',
        rules: { required: 'This field is required.' },
        control,
    });
    const { value: operationValue, onChange: operationOnChange } = field;

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setErrorLength(true);
        }
    }, [errors]);

    const createApplication = (userData, direction) => {
        return dispatch(
            applicationActions.createApplication({
                ...userData,
                direction: direction,
            }),
        );
    };

    const onSubmit = data => {
        dispatch(modalActions.close_modal);
        dispatch(
            modalActions.open_modal({
                name: 'selectedApplication',
                props: {
                    investmentType: `${data.operation}`,
                    rules: { required: 'This field is required.' },
                    onSubmit: userData =>
                        createApplication(userData, data.operation),
                },
            }),
        );
    };

    return (
        <AppModal>
            <UpdateForm
                btnText="Next"
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                text="Please, choose type of operation"
                title={title}
            >
                <AppSelect
                    errorMessage={errorLength && errors.operation?.message}
                    list={operationList}
                    onChange={operationOnChange}
                    placeholder="Operation"
                    selectedValue={operationValue}
                />
            </UpdateForm>
        </AppModal>
    );
};

export default ApplicationModal;
