export function getPathName(str) {
    return str.substring(1).split('/').splice(0).join(' / ');
}

export function roundNumber(value, count = 2) {
    const defaultResult = 0;
    if (value) {
        const roundedValue =
            typeof value === 'string' ? parseFloat(value) : value;

        return roundedValue < 0
            ? ((Math.floor(Math.abs(value) * 100) * -1) / 100).toFixed(2)
            : roundedValue.toFixed(count);
    }
    return defaultResult.toFixed(count);
}

export function preventValue(e) {
    if (
        e.code === 'Minus' ||
        e.code === 'NumpadSubtract' ||
        e.code === 'KeyE'
    ) {
        e.preventDefault();
    }
}

export function formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function kFormatter(num, precision = 2) {
    const map = [
        { suffix: 'T', threshold: 1e12 },
        { suffix: 'B', threshold: 1e9 },
        { suffix: 'M', threshold: 1e6 },
        { suffix: 'K', threshold: 1e3 },
        { suffix: '', threshold: 1 },
    ];

    const found = map.find(x => Math.abs(num) >= x.threshold);
    if (found) {
        return (num / found.threshold).toFixed(precision) + found.suffix;
    }

    return num;
}

export function lastElement(array) {
    return array === undefined || array.length === 0
        ? undefined
        : array[array.length - 1];
}

export function getFirstNNumber(array, n) {
    if (Array.isArray(array)) {
        return array.slice(0, n);
    }
    return [];
}

export function sortObjectByMaxValue(obj) {
    // Step 1: Calculate the maximum value in each array
    const arrays = {};
    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            arrays[key] = obj[key];
        }
    }

    // Step 2: Sort the object based on the minimum max value
    const sortedKeys = Object.keys(arrays).sort((a, b) => {
        const maxA = Math.max(...arrays[a]);
        const maxB = Math.max(...arrays[b]);
        return maxA === maxB ? 0 : maxA > maxB ? 1 : -1;
    });

    // Combine the sorted keys with the original object
    const sortedObject = {};
    sortedKeys.forEach(key => {
        sortedObject[key] = obj[key];
    });

    return sortedObject;
}
