import { useDispatch } from 'react-redux';
import { modalActions } from '../../../../actions/modal.actions';
import AppButton from '../../../AppButton';
import AppModal from '../../../AppModal';
import AppAuthSuccessForm from '../../../AppAuthSuccessForm';
import { SuccessIcon } from '../../../../assets/icons/AuthSvg';

const SuccessModal = ({
    message = '',
    button = false,
    isNavigated = false,
}) => {
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(modalActions.close_modal());
    };

    return (
        <AppModal isNavigated={isNavigated}>
            <AppAuthSuccessForm
                button={
                    button ? (
                        <AppButton
                            className="btn-primary-height sm:w-fit w-full"
                            onClick={handleClose}
                        >
                            Good
                        </AppButton>
                    ) : null
                }
                Icon={<SuccessIcon />}
                text={`${message}`}
                title="Success :)"
            />
        </AppModal>
    );
};

export default SuccessModal;
