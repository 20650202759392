import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { managerApplicationActions } from '../../actions/managerApplication.actions';
import { notificationsActions } from '../../actions/notification.actions';
import { isToday } from '../../../common/helpers/dateHelper';
import { GetNotifications } from '../../manager.selectors';

const AdminNotificationPopup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { notifications } = GetNotifications();

    const handleClick = () => {
        navigate('/admin');
        dispatch(managerApplicationActions.getApplicationRef(true));
        dispatch(notificationsActions.updateNotifications());
    };

    return (
        <div className=" md:w-max">
            {Array.isArray(notifications) &&
                notifications?.map((item, index) => {
                    return (
                        <button
                            className="flex items-start gap-2.5 p-3"
                            key={index}
                            onClick={handleClick}
                        >
                            {isToday(item.created_at) ? (
                                <span className=" w-2 h-[13px] pt-[5px] pb-0 px-0">
                                    <svg
                                        fill="none"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        width="8"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx="4"
                                            cy="4"
                                            fill="#9169F6"
                                            r="4"
                                        />
                                    </svg>
                                </span>
                            ) : (
                                <span className="w-2"></span>
                            )}
                            <div className="flex flex-col items-start gap-1">
                                <span className="font-normal text-[15px] font-helvetica text-[#0B1F35]">
                                    {item.message}
                                </span>
                                <span className="font-normal text-[15px] text-[#B5B2B2] font-helvetica">
                                    {`${
                                        isToday(item.created_at)
                                            ? 'Today'
                                            : dayjs(item.created_at).format(
                                                  'MMMM DD, YYYY',
                                              )
                                    }`}
                                </span>
                            </div>
                        </button>
                    );
                })}
        </div>
    );
};

export default AdminNotificationPopup;
