import { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../../actions/modal.actions';
import AppModal from '../../../AppModal';
import AppSelect from '../../../AppSelect';
import UpdateForm from '../../ModalFormComponents/updateForm';

const CashModal = ({ title, cashInOperationList, type = 'cash_in' }) => {
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        mode: 'onChange' || '',
    });
    const dispatch = useDispatch();
    const [errorLength, setErrorLength] = useState(false);
    const { field } = useController({
        name: 'operation',
        rules: { required: 'This field is required.' },
        control,
    });
    const { value: operationValue, onChange: operationOnChange } = field;

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setErrorLength(true);
        }
    }, [errors]);

    const onSubmit = data => {
        dispatch(modalActions.close_modal);
        if (type === 'cash_out') {
            dispatch(
                modalActions.open_modal({
                    name: 'cashOut',
                    props: { investmentType: `${data.operation}` },
                }),
            );
        } else {
            dispatch(
                modalActions.open_modal({
                    name: 'cashIn',
                    props: { investmentType: `${data.operation}` },
                }),
            );
        }
    };

    return (
        <AppModal>
            <UpdateForm
                btnText="Next"
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                text="Please, choose type of operation"
                title={title}
            >
                <AppSelect
                    errorMessage={errorLength && errors.operation?.message}
                    list={cashInOperationList}
                    onChange={operationOnChange}
                    placeholder="Operation"
                    selectedValue={operationValue}
                />
            </UpdateForm>
        </AppModal>
    );
};

export default CashModal;
