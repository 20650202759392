import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useChangedPathname() {
    const { pathname } = useLocation();
    const [pathName, setPathName] = useState(pathname);
    const [changedPathname, setChangedPathname] = useState(false);

    useEffect(() => {
        setPathName(pathname);
    }, []);

    useEffect(() => {
        setChangedPathname(pathname !== pathName);
    }, [pathname]);

    return [changedPathname];
}

export default useChangedPathname;
