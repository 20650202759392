import { userService } from '../../common/services';
import { applicationsActions } from '../../common/actions/applications.actions';
import { modalActions } from '../../common/actions/modal.actions';
import {
    DELETE_APPLICATION_FAILED,
    DELETE_APPLICATION_REQUEST,
    DELETE_APPLICATION_SUCCESS,
    GET_APPLICATION_REF_SUCCESS,
    UPDATE_APPLICATION_FAILED,
    UPDATE_APPLICATION_REQUEST,
    UPDATE_APPLICATION_SUCCESS,
} from './actionTypes';

export const managerApplicationActions = {
    deleteApplication,
    updateApplication,
    getApplicationRef,
};

function deleteApplication(dataId) {
    return dispatch => {
        dispatch(request());
        userService.DeleteInfo(`/applications/${dataId}`).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: {
                            message: `${data.data.message}`,
                            button: true,
                        },
                    }),
                );
                dispatch(applicationsActions.getApplications());
            } else {
                dispatch(fail(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'forbidden',
                        props: { text: `${data.data.message}` },
                    }),
                );
            }
        });
    };
    function request() {
        return { type: DELETE_APPLICATION_REQUEST };
    }
    function success(data) {
        return { type: DELETE_APPLICATION_SUCCESS, data };
    }
    function fail(error) {
        return { type: DELETE_APPLICATION_FAILED, error };
    }
}

function updateApplication(data, dataId) {
    const endpoint = `/applications/${dataId}`;
    return dispatch => {
        dispatch(request());
        userService.PatchInfo(endpoint, data).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: {
                            message: `${data.data.message}`,
                            button: true,
                        },
                    }),
                );
                dispatch(applicationsActions.getApplications());
            } else {
                dispatch(fail(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'forbidden',
                        props: { text: `${data.data.message}` },
                    }),
                );
            }
        });
    };
    function request() {
        return { type: UPDATE_APPLICATION_REQUEST };
    }
    function success(data) {
        return { type: UPDATE_APPLICATION_SUCCESS, data };
    }
    function fail(error) {
        return { type: UPDATE_APPLICATION_FAILED, error };
    }
}

function getApplicationRef(data) {
    return { type: GET_APPLICATION_REF_SUCCESS, data };
}
