export const CASH_IN_OPERATION_TYPE = [
    {
        value: 1,
        label: 'Add payment from investor',
    },
    {
        value: 2,
        label: 'Add dividends',
    },
];

export const CASH_OUT_OPERATION_TYPE = [
    {
        value: 1,
        label: 'Inside the fund',
    },
    {
        value: 2,
        label: 'Outside the fund',
    },
];

export const INVESTMENT_TYPE = {
    Installment: 'Installment',
    Dividend: 'Dividend',
};

export const CASH_OUT_TYPE = {
    Inside: 'Inside',
    Outside: 'Outside',
};
