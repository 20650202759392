import { useEffect, useState } from 'react';
import { formatNumber, roundNumber } from '../helpers/util';
import { GetCash } from '../../manager/manager.selectors';

function useTotalCash() {
    const { cashData, loading } = GetCash();
    const [cash, setCash] = useState('');

    useEffect(() => {
        if (!loading) {
            const rounded = formatNumber(roundNumber(cashData));
            setCash(rounded);
        }
    }, [cashData, loading]);

    return [cash];
}

export default useTotalCash;
