import { userActions } from '../../../../actions/user.actions';
import { CreatedUser } from '../../../../store/project/project.selectors';
import AppModal from '../../../AppModal';
import AppInput from '../../../AppInput';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import UpdateForm from '../../ModalFormComponents/updateForm';

const UpdateUserPasswordModal = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: 'onChange' });
    const dispatch = useDispatch();
    const { user } = CreatedUser();

    const onSubmit = userData => {
        return dispatch(userActions.updateProfile(userData, `${user.id}`));
    };

    return (
        <AppModal>
            <UpdateForm
                btnText="Save"
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                title="Personal data"
            >
                <AppInput
                    error={errors}
                    id="phone"
                    label="Phone number"
                    register={register}
                    type="phone"
                    value={user?.phone_number}
                />
            </UpdateForm>
        </AppModal>
    );
};

export default UpdateUserPasswordModal;
