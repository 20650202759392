import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../actions/user.actions';
import { roundNumber } from '../helpers/util';
import { UserHolding } from '../store/project/project.selectors';

function useUserHoldings(id = '') {
    const dispatch = useDispatch();
    const { holdingData, loading } = UserHolding();
    const [roundedHolding, setRoundedHolding] = useState({
        current_holdings: 0,
        holders_structure: 0,
        total_gain: 0,
        total_gain_percent: 0,
    });

    useEffect(() => {
        if (id) {
            dispatch(userActions.getUserHolding(id));
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            return () => {
                dispatch(userActions.getUserHoldingClear());
            };
        }
    }, [id]);

    useEffect(() => {
        if (!loading) {
            setRoundedHolding(() => {
                return {
                    ...holdingData,
                    current_holdings: roundNumber(
                        holdingData?.current_holdings,
                    ),
                    holders_structure: roundNumber(
                        holdingData?.holders_structure,
                    ),
                    total_gain: roundNumber(holdingData?.total_gain),
                    total_gain_percent: roundNumber(
                        holdingData?.total_gain_percent,
                    ),
                };
            });
        }
    }, [
        holdingData?.current_holdings,
        holdingData?.holders_structure,
        holdingData?.total_gain,
        holdingData?.total_gain_percent,
    ]);

    return [roundedHolding];
}

export default useUserHoldings;
