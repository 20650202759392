import { LeftArrowIcon, RightArrowIcon } from '../../assets/icons/Users';

const Pagination = ({
    paginate,
    className,
    currentPage,
    previousPage,
    nextPage,
    disableLeftArrow,
    disableRightArrow,
    count = 0,
}) => {
    const pageNumbers = [];

    for (let i = 1; i <= count; i++) {
        pageNumbers.push(i);
    }
    const handlePaginate = (e, number) => {
        e.preventDefault();
        paginate(number);
    };

    return (
        <nav className={className}>
            <ul className="flex flex-row justify-center items-center gap-2 w-72 h-10">
                <li className="cursor-pointer" onClick={previousPage}>
                    <LeftArrowIcon
                        color={`${disableLeftArrow ? '#B5B2B2' : '#000000'}`}
                    />
                </li>
                {pageNumbers.map(number => (
                    <>
                        <li
                            className={`flex flex-col justify-center items-center gap-2.5 w-10 h-10  rounded-sm cursor-pointer  ${
                                currentPage === number
                                    ? ' border border-[#000000] border-solid'
                                    : ''
                            } font-inter `}
                            key={number}
                            onClick={e => handlePaginate(e, number)}
                        >
                            <div className="font-normal text-base leading-[18px] flex items-center text-center uppercase text-[#000000] font-helvetica">
                                {number}
                            </div>
                        </li>
                    </>
                ))}
                <li className="cursor-pointer" onClick={nextPage}>
                    <RightArrowIcon
                        color={`${disableRightArrow ? '#B5B2B2' : '#000000'} `}
                    />
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
