import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react';
import LazyLoad from '../components/LazyLoad';
import ProtectedRoutes from '../components/AppRouter/ProtectedRoutes';
import PublicRoutes from '../components/AppRouter/PublicRoutes';
import { ROLES } from './costants';

const InnerContent = lazy(() => import('../components/InnerContent'));
const LazyInnerContent = props => (
    <LazyLoad component={InnerContent} {...props} />
);
//global
const Signin = lazy(() => import('../pages/Signin'));
const LazySignin = props => <LazyLoad component={Signin} {...props} />;

const ForgotPassword = lazy(() => import('../pages/Forgot-password'));
const LazyForgotPassword = props => (
    <LazyLoad component={ForgotPassword} {...props} />
);

const VerifyEmail = lazy(() => import('../pages/Email-verify'));
const LazyEmailVerify = props => (
    <LazyLoad component={VerifyEmail} {...props} />
);

const ResetPassword = lazy(() => import('../pages/Reset-password'));
const LazyResetPassword = props => (
    <LazyLoad component={ResetPassword} {...props} />
);

const CreatePassword = lazy(() =>
    import('../../investor/pages/Create-password'),
);
const LazyCreatePassword = props => (
    <LazyLoad component={CreatePassword} {...props} />
);
//investor
const Home = lazy(() => import('../../investor/pages/Home'));
const LazyHome = props => <LazyLoad component={Home} {...props} />;

const Fund = lazy(() => import('../../investor/pages/Fund'));
const LazyFund = props => <LazyLoad component={Fund} {...props} />;

//manager
const Admin = lazy(() => import('../../manager/pages/Admin'));
const LazyAdmin = props => <LazyLoad component={Admin} {...props} />;

const PersonalSettings = lazy(() => import('../pages/PersonalSettings'));
const LazyPersonalSettings = props => (
    <LazyLoad component={PersonalSettings} {...props} />
);

const Holders = lazy(() => import('../../manager/pages/Holders'));
const LazyHolders = props => <LazyLoad component={Holders} {...props} />;

const UsersManagement = lazy(() =>
    import('../../manager/pages/UsersManagement'),
);
const LazyUsersManagement = props => (
    <LazyLoad component={UsersManagement} {...props} />
);

const ManagementSettings = lazy(() =>
    import('../../manager/pages/ManagementSettings'),
);
const LazyManagementSettings = props => (
    <LazyLoad component={ManagementSettings} {...props} />
);

const FundManagement = lazy(() => import('../../manager/pages/FundManagement'));
const LazyFundManagement = props => (
    <LazyLoad component={FundManagement} {...props} />
);

const Assets = lazy(() => import('../../manager/pages/Assets'));
const LazyAssets = props => <LazyLoad component={Assets} {...props} />;

const Groups = lazy(() => import('../../manager/pages/Group'));
const LazyGroups = props => <LazyLoad component={Groups} {...props} />;

const Cash = lazy(() => import('../../manager/pages/Cash'));
const LazyCash = props => <LazyLoad component={Cash} {...props} />;

const Transactions = lazy(() => import('../../manager/pages/Transactions'));
const LazyTransactions = props => (
    <LazyLoad component={Transactions} {...props} />
);
//not found
const PageNotFound = lazy(() => import('../pages/PageNotFound'));
const LazyPageNotFound = props => (
    <LazyLoad component={PageNotFound} {...props} />
);

//public
const Unauthorized = lazy(() => import('../pages/Unauthorized'));
const LazyUnauthorized = props => (
    <LazyLoad component={Unauthorized} {...props} />
);

export default function getRoutes() {
    return (
        <Routes>
            <Route element={<LazyInnerContent />} path="/">
                {/*public routes*/}
                <Route
                    element={
                        <PublicRoutes allowedRoles={[ROLES.PUBLIC_USER]} />
                    }
                >
                    <Route element={<LazySignin />} exact path="/login" />
                    <Route
                        element={<LazyForgotPassword />}
                        exact
                        path="/admin/forgot-password"
                    />
                    <Route
                        element={<LazyEmailVerify />}
                        exact
                        path="/email-verify"
                    />
                    <Route
                        element={<LazyResetPassword />}
                        exact
                        path="/password/reset"
                    />
                    <Route
                        element={<LazyCreatePassword />}
                        exact
                        path="/password/create"
                    />
                </Route>
                <Route element={<LazyUnauthorized />} path="unauthorized" />
                {/*<Route element={<AdminPublicRoutes />}>*/}
                {/*    <Route*/}
                {/*        element={<LazyForgotPassword />}*/}
                {/*        exact*/}
                {/*        path="/admin/forgot-password"*/}
                {/*    />*/}
                {/*    <Route*/}
                {/*        element={<LazyEmailVerify />}*/}
                {/*        exact*/}
                {/*        path="/admin/email-verify"*/}
                {/*    />*/}
                {/*    <Route*/}
                {/*        element={<LazyResetPassword />}*/}
                {/*        exact*/}
                {/*        path="/admin/password/reset"*/}
                {/*    />*/}
                {/*</Route>*/}
                {/*<Route element={<InvestorPublicRoutes />}>*/}
                {/*    <Route*/}
                {/*        element={<LazyCreatePassword />}*/}
                {/*        exact*/}
                {/*        path="/password/create"*/}
                {/*    />*/}
                {/*    <Route*/}
                {/*        element={<LazyResetPassword />}*/}
                {/*        exact*/}
                {/*        path="/password/reset"*/}
                {/*    />*/}
                {/*</Route>*/}
                protected routes
                <Route
                    element={
                        <ProtectedRoutes allowedRoles={[ROLES.INVESTOR]} />
                    }
                >
                    <Route element={<LazyHome />} path="/" />
                    <Route element={<LazyFund />} path="/fund" />
                    <Route
                        element={<LazyPersonalSettings />}
                        path="/settings"
                    />
                </Route>
                <Route
                    element={<ProtectedRoutes allowedRoles={[ROLES.MANAGER]} />}
                >
                    <Route element={<LazyAdmin />} path="admin" />
                    <Route
                        element={<LazyFundManagement />}
                        path="/admin/fund"
                    />
                    <Route element={<LazyAssets />} path="/admin/fund/assets" />
                    <Route path="/admin/fund/assets">
                        <Route element={<LazyGroups />} path=":groupId" />
                    </Route>
                    <Route element={<LazyCash />} path="/admin/fund/cash" />
                    <Route
                        element={<LazyTransactions />}
                        path="/admin/fund/cash/transactions"
                    />
                    <Route
                        element={<LazyPersonalSettings />}
                        path="/admin/settings"
                    />
                    <Route element={<LazyHolders />} path="/admin/holders" />
                    <Route path="/admin/holders">
                        <Route element={<LazyUsersManagement />} path=":id" />
                        <Route
                            element={<LazyManagementSettings />}
                            path=":id/settings"
                        />
                    </Route>
                </Route>
                {/* catch all */}
                <Route element={<LazyPageNotFound />} path="*" />
            </Route>
        </Routes>
    );
}
