import { getFirstLetter } from '../../helpers/avatarHelpers';

export default function AppAvatar({
    name,
    width = 'w-[32px]',
    height = 'h-[32px]',
}) {
    const firstLetter = getFirstLetter(name?.replace(/ /g, ''));
    return (
        <div
            className={`m-1 mr-2 ${width} ${height} relative flex justify-center items-center rounded-full bg-[#eaeaea] uppercase text-base font-semibold text-gray-900 font-inter`}
        >
            {firstLetter}
        </div>
    );
}
