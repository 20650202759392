import {
    ADD_INVESTMENT_FAILED,
    ADD_INVESTMENT_REQUEST,
    ADD_INVESTMENT_SUCCESS,
    CASH_OUT_FAILED,
    CASH_OUT_REQUEST,
    CASH_OUT_SUCCESS,
    CLEAR_CASH_OUT,
} from '../actions/actionTypes';

const initialInvestment = {
    loading: false,
    investmentData: '',
    type: '',
};

const initialCashOut = {
    loading: false,
    cashOutData: '',
    type: '',
};

export function cashIn(state = initialInvestment, action) {
    switch (action.type) {
        case ADD_INVESTMENT_SUCCESS:
            return {
                loading: false,
                investmentData: action.data,
                type: 'success',
            };
        case ADD_INVESTMENT_FAILED:
            return {
                loading: false,
                investmentData: action.error,
                type: 'danger',
            };
        case ADD_INVESTMENT_REQUEST:
            return {
                loading: true,
                investmentData: '',
                type: '',
            };

        default:
            return state;
    }
}

export function cashOut(state = initialCashOut, action) {
    switch (action.type) {
        case CASH_OUT_SUCCESS:
            return {
                loading: false,
                cashOutData: action.data,
                type: 'success',
            };
        case CASH_OUT_FAILED:
            return {
                loading: false,
                cashOutData: action.error,
                type: 'danger',
            };
        case CASH_OUT_REQUEST:
            return {
                loading: true,
                cashOutData: '',
                type: '',
            };
        case CLEAR_CASH_OUT:
            return {
                loading: false,
                cashOutData: '',
                type: '',
            };

        default:
            return state;
    }
}
