import { useAppSelector } from '../common/store/store.helper';

export const GetCurrencies = () =>
    useAppSelector(state => state.manager.getCurrencies);
export const AddGroups = () => useAppSelector(state => state.manager.addGroups);
export const UpdateGroup = () =>
    useAppSelector(state => state.manager.updateGroup);
export const GetGroup = () => useAppSelector(state => state.manager.getGroup);
export const GetGroups = () => useAppSelector(state => state.manager.getGroups);
export const GetGroupSymbol = () =>
    useAppSelector(state => state.manager.getGroupSymbol);
export const GetRealizedGain = () =>
    useAppSelector(state => state.manager.getRealizedGain);
export const DeleteGroup = () =>
    useAppSelector(state => state.manager.deleteGroup);
export const GetLotsList = () =>
    useAppSelector(state => state.manager.getLotsList);
export const DeleteLots = () =>
    useAppSelector(state => state.manager.deleteLots);
export const AddLot = () => useAppSelector(state => state.manager.addLot);
export const SellLot = () => useAppSelector(state => state.manager.sellLot);
export const GetCash = () => useAppSelector(state => state.manager.getCash);
export const DeleteApplication = () =>
    useAppSelector(state => state.manager.deleteApplication);
export const UpdateApplication = () =>
    useAppSelector(state => state.manager.updateApplication);
export const GetApplicationRef = () =>
    useAppSelector(state => state.manager.getApplicationRef);
export const GetNotifications = () =>
    useAppSelector(state => state.manager.getNotifications);
export const UpdateNotifications = () =>
    useAppSelector(state => state.manager.updateNotifications);
export const AddSymbol = () => useAppSelector(state => state.manager.addSymbol);
export const GetSymbols = () =>
    useAppSelector(state => state.manager.getSymbols);
export const DeleteSymbol = () =>
    useAppSelector(state => state.manager.deleteSymbol);
export const PortfolioChart = () =>
    useAppSelector(state => state.manager.portfolioChart);
export const DayGain = () => useAppSelector(state => state.manager.dayGain);
export const Transactions = () =>
    useAppSelector(state => state.manager.transactions);
