import {
    ADD_LOT_CLEAR,
    ADD_LOT_FAILED,
    ADD_LOT_REQUEST,
    ADD_LOT_SUCCESS,
    DELETE_LOT_FAILED,
    DELETE_LOT_REQUEST,
    DELETE_LOT_SUCCESS,
    GET_CASH_FAILED,
    GET_CASH_REQUEST,
    GET_CASH_SUCCESS,
    GET_LOTS_LIST_CLEAR,
    GET_LOTS_LIST_FAILED,
    GET_LOTS_LIST_REQUEST,
    GET_LOTS_LIST_SUCCESS,
    SELL_LOT_CLEAR,
    SELL_LOT_FAILED,
    SELL_LOT_REQUEST,
    SELL_LOT_SUCCESS,
} from '../actions/actionTypes';

const initialLotsState = {
    loading: false,
    lotsData: [],
};

const initialCash = {
    loading: false,
    cashData: '',
};

const initialDeletedLotsState = {
    lotsData: '',
    loading: false,
    type: '',
};

const initialAddedLotsState = {
    lotsData: '',
    loading: false,
    type: '',
};

export function getLotsList(state = initialLotsState, action) {
    switch (action.type) {
        case GET_LOTS_LIST_SUCCESS:
            return {
                loading: false,
                lotsData: action.data,
            };
        case GET_LOTS_LIST_FAILED:
            return {
                loading: false,
                lotsData: action.error,
            };
        case GET_LOTS_LIST_REQUEST:
            return {
                loading: true,
                lotsData: [],
            };
        case GET_LOTS_LIST_CLEAR:
            return {
                loading: false,
                lotsData: [],
            };

        default:
            return state;
    }
}

export function deleteLots(state = initialDeletedLotsState, action) {
    switch (action.type) {
        case DELETE_LOT_SUCCESS:
            return {
                lotsData: action.data,
                loading: false,
                type: 'success',
            };
        case DELETE_LOT_FAILED:
            return {
                lotsData: action.error,
                loading: false,
                type: 'danger',
            };
        case DELETE_LOT_REQUEST:
            return {
                lotsData: '',
                loading: true,
                type: '',
            };

        default:
            return state;
    }
}

export function addLot(state = initialAddedLotsState, action) {
    switch (action.type) {
        case ADD_LOT_SUCCESS:
            return {
                loading: false,
                lotData: action.data,
                type: 'success',
            };
        case ADD_LOT_FAILED:
            return {
                loading: false,
                lotData: action.error,
                type: 'danger',
            };
        case ADD_LOT_REQUEST:
            return {
                loading: true,
                lotData: '',
                type: '',
            };
        case ADD_LOT_CLEAR:
            return {
                loading: true,
                lotData: '',
                type: '',
            };

        default:
            return state;
    }
}

export function sellLot(state = initialAddedLotsState, action) {
    switch (action.type) {
        case SELL_LOT_SUCCESS:
            return {
                loading: false,
                sellData: action.data,
                type: 'success',
            };
        case SELL_LOT_FAILED:
            return {
                loading: false,
                sellData: action.error,
                type: 'danger',
            };
        case SELL_LOT_REQUEST:
            return {
                loading: true,
                sellData: '',
                type: '',
            };
        case SELL_LOT_CLEAR:
            return {
                loading: false,
                sellData: '',
                type: '',
            };

        default:
            return state;
    }
}

export function getCash(state = initialCash, action) {
    switch (action.type) {
        case GET_CASH_SUCCESS:
            return {
                loading: false,
                cashData: action.data,
            };
        case GET_CASH_FAILED:
            return {
                loading: false,
                cashData: action.error,
            };
        case GET_CASH_REQUEST:
            return {
                loading: true,
                cashData: '',
            };

        default:
            return state;
    }
}
