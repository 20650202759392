import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { lotsActions } from '../../../../../manager/actions/lots.actions';
import { DeleteIcon } from '../../../../assets/icons/SettingsSvg';
import { debounce } from '../../../../helpers/debounce';
import { totalLotsCalculation } from '../../../../helpers/lotsHelper';
import { AddLot, GetCash } from '../../../../../manager/manager.selectors';
import AppBadge from '../../../AppBadge';
import { LOT_TYPE } from '../../../../../manager/components/AppGroup/lots.constants';
import { AppInputFromArray } from '../../../AppInputFromArray';
import AppModal from '../../../AppModal';
import AppForm from '../../../AppForm';
import AppButton from '../../../AppButton';
import AppInput from '../../../AppInput';
import { modalActions } from '../../../../actions/modal.actions';
import { formatNumber, roundNumber } from '../../../../helpers/util';

const BuyLotModal = ({ symbol, groupId }) => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            lots: [{ shares_count: '', traded_at: '', price: '' }],
        },
        mode: 'onChange',
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'lots',
    });
    const watchFieldArray = watch('lots');
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index],
        };
    });
    const { lotData, type } = AddLot();
    const { cashData, loading } = GetCash();
    const [errorMessage, setErrorMessage] = useState('');
    const [totalLot, setTotalLot] = useState(0);
    const [disable, setDisable] = useState(false);
    const [cash, setCash] = useState('');
    const [totalCash, setTotalCash] = useState(0);
    const [errorLength, setErrorLength] = useState(false);

    useEffect(() => {
        dispatch(lotsActions.getCash());
    }, []);

    useEffect(() => {
        if (lotData && type === 'danger') {
            dispatch(lotsActions.addLotClear());
        }
    }, []);

    useEffect(() => {
        if (!loading) {
            setCash(formatNumber(roundNumber(cashData, 2)));
        }
    }, [cashData, loading]);

    useEffect(() => {
        if (!loading) {
            setTotalCash(Number(cashData));
        }
    }, [cashData, loading]);

    useEffect(() => {
        const totalData = totalLotsCalculation(controlledFields);
        setErrorMessage(
            totalData > Number(totalCash) || (lotData && type === 'danger')
                ? lotData ||
                      'The amount of the purchased lot exceeds the cash amount!'
                : '',
        );
        debounce(setTotalLot(formatNumber(roundNumber(totalData, 2))));
    }, [controlledFields, totalCash, type, lotData]);

    useEffect(() => {
        setDisable(!!errorMessage);
    }, [errorMessage]);

    useEffect(() => {
        if (Object.keys(errors).length && errors?.lots?.length > 0) {
            setErrorLength(true);
        }
    }, [errors.lots]);

    const onSubmit = userData => {
        const data = {
            ...userData,
            company_symbol: symbol,
            type: LOT_TYPE.buy,
        };
        return dispatch(lotsActions.addLot(data, groupId));
    };

    const preventMinus = e => {
        if (
            e.code === 'Minus' ||
            e.code === 'NumpadSubtract' ||
            e.code === 'KeyE'
        ) {
            e.preventDefault();
        }
    };

    const handleBack = () => {
        dispatch(modalActions.close_modal());
    };

    const handleAdd = e => {
        e.preventDefault();
        append({
            shares_count: '',
            traded_at: '',
            price: '',
        });
    };
    const handleChange = () => {
        if (lotData && type === 'danger') {
            dispatch(lotsActions.addLotClear());
        }
    };

    return (
        <AppModal>
            <AppForm
                className="max-w-[400px] w-full max-h-[calc(100vh_-_210px)] "
                onSubmit={handleSubmit(onSubmit)}
                title={`Buy ${symbol} lot`}
            >
                <AppBadge
                    className="w-fit m-auto"
                    size={21}
                    variant={'badge-green'}
                >
                    {`${totalLot} $`}
                </AppBadge>
                <div className="flex flex-col gap-3">
                    <AppInput
                        label="Total cash"
                        readOnly={true}
                        type="text"
                        value={cash}
                    />
                    {fields.map((item, index) => {
                        return (
                            <div className="flex flex-col gap-3" key={index}>
                                {index > 0 && (
                                    <span className="flex justify-end">
                                        <button onClick={() => remove(index)}>
                                            <DeleteIcon />
                                        </button>
                                    </span>
                                )}
                                <AppInputFromArray
                                    errorMessage={
                                        errorLength &&
                                        errors.lots?.[`${index}`]?.shares_count
                                            ?.message
                                    }
                                    id={`lots.${index}.shares_count`}
                                    label="Number of shares"
                                    min="0"
                                    onKeyPress={preventMinus}
                                    optionObject={{
                                        onChange: handleChange,
                                    }}
                                    register={register}
                                    type="number"
                                />
                                <AppInputFromArray
                                    errorMessage={
                                        errorLength &&
                                        errors.lots?.[`${index}`]?.price
                                            ?.message
                                    }
                                    id={`lots.${index}.price`}
                                    label="Price per share"
                                    min="0"
                                    onKeyPress={preventMinus}
                                    optionObject={{
                                        onChange: handleChange,
                                    }}
                                    register={register}
                                    step="any"
                                    type="number"
                                />

                                <AppInputFromArray
                                    errorMessage={
                                        errorLength &&
                                        errors.lots?.[`${index}`]?.traded_at
                                            ?.message
                                    }
                                    handleChange={handleChange}
                                    id={`lots.${index}.traded_at`}
                                    label="Date"
                                    register={register}
                                    type="date"
                                />
                            </div>
                        );
                    })}
                    <p className="font-bold text-[15px] text-black font-roboto ">
                        <button onClick={handleAdd}>Add another one</button>
                    </p>
                    {errorMessage && (
                        <p className="text-sm text-[#C90000] dark:text-red-500 font-[500]">
                            {errorMessage}
                        </p>
                    )}
                </div>
                <div className="flex flex-wrap items-center md:justify-self-auto justify-center gap-2.5 h-[79px]">
                    <AppButton
                        className="max-w-[134px] btn-small-height"
                        onClick={handleBack}
                        type=""
                        variant="btn-secondary"
                    >
                        Back
                    </AppButton>
                    <AppButton
                        className="max-w-fit btn-small-height"
                        disabled={disable}
                        type=""
                        variant="primary"
                    >
                        Save
                    </AppButton>
                </div>
            </AppForm>
        </AppModal>
    );
};

export default BuyLotModal;
