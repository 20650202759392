import { GET_CURRENCY, SET_CURRENCY, SET_CURRENCY_CLEAR } from './actionTypes';

export const currencyActions = {
    setCurrencyData,
    CurrencyDataClear,
    getCurrencyData,
};

function setCurrencyData(data) {
    return dispatch => {
        dispatch(success(data));
        dispatch(currencyActions.getCurrencyData());
    };
    function success(data) {
        return { type: SET_CURRENCY, data };
    }
}

function CurrencyDataClear() {
    return { type: SET_CURRENCY_CLEAR };
}

function getCurrencyData() {
    return dispatch => {
        const data = JSON.parse(localStorage.getItem('currentCurrency'));
        dispatch(success(data));
    };
    function success(data) {
        return { type: GET_CURRENCY, data };
    }
}
