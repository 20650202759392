export const ROLES = {
    PUBLIC_USER: 'Public_user',
    INVESTOR: 'Investor',
    MANAGER: 'Manager',
};

export const NAME_BY_ROLES = {
    Public_user: 'login',
    Investor: '',
    Manager: 'admin',
};
