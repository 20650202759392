import React from 'react';
import Modals from '../Modals';

const ModalsWrapper = ({ children }) => {
    return (
        <>
            {children}
            <Modals />
        </>
    );
};

export default ModalsWrapper;
