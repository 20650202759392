import { managerCashActions } from '../../manager/actions/cash.actions';
import { userService } from '../services';
import {
    ADD_INVESTMENT_FAILED,
    ADD_INVESTMENT_REQUEST,
    ADD_INVESTMENT_SUCCESS,
    CASH_OUT_FAILED,
    CASH_OUT_REQUEST,
    CASH_OUT_SUCCESS,
    CLEAR_CASH_OUT,
} from './actionTypes';
import { modalActions } from './modal.actions';

export const cashActions = {
    cashIn,
    cashOut,
    clearCashOut,
};

function cashIn(data) {
    const endpoint = '/cash/cash-in';
    return dispatch => {
        dispatch(request());
        userService.PostInfo(endpoint, data).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: {
                            message: 'Your operation has been created',
                            button: true,
                        },
                    }),
                );
                dispatch(managerCashActions.getTransactions());
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: ADD_INVESTMENT_REQUEST };
    }
    function success(data) {
        return { type: ADD_INVESTMENT_SUCCESS, data };
    }
    function fail(error) {
        return { type: ADD_INVESTMENT_FAILED, error };
    }
}

function cashOut(data) {
    const endpoint = '/cash/cash-out';
    return dispatch => {
        dispatch(request());
        userService.PostInfo(endpoint, data).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: {
                            message: 'Your operation has been created',
                            button: true,
                        },
                    }),
                );
                dispatch(managerCashActions.getTransactions());
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: CASH_OUT_REQUEST };
    }
    function success(data) {
        return { type: CASH_OUT_SUCCESS, data };
    }
    function fail(error) {
        return { type: CASH_OUT_FAILED, error };
    }
}

function clearCashOut() {
    return { type: CLEAR_CASH_OUT };
}
