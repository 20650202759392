import { NAME_BY_ROLES } from '../../../common/routes/costants';
import NavbarLink from '../../../common/components/Navbars/NavbarLink';
import NavbarUser from '../../../common/components/Navbars/NavbarUser';

export default function () {
    return (
        <NavbarUser logoUrl={`/${NAME_BY_ROLES.Investor}`}>
            <NavbarLink name="Fund" navigationLink="/fund" />
        </NavbarUser>
    );
}
