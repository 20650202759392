import { useDispatch } from 'react-redux';
import { modalActions } from '../../../../actions/modal.actions';
import { userActions } from '../../../../actions/user.actions';
import { getActiveCurrency } from '../../../../helpers/currencyHelper';
import { formatNumber, roundNumber } from '../../../../helpers/util';
import { AllWithdrawals } from '../../../../store/project/project.selectors';
import AppBadge from '../../../AppBadge';
import AppModal from '../../../AppModal';
import AppForm from '../../../AppForm';
import AppButton from '../../../AppButton';
import { useEffect, useState } from 'react';
import Pagination from '../../../Pagination';
import { CURRENCIES } from '../../../CurrencyComponent/currencyConstants';
import { StoredCurrentCurrency } from '../../../../../investor/investor.selectors';

const TotalResultsWithdrawalModal = ({
    params,
    coefficient = 1,
    symbol = '$',
}) => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [paramsObject, setParamsObject] = useState(params);
    const [disableLeftArrow, setDisableLeftArrow] = useState(true);
    const [disableRightArrow, setDisableRightArrow] = useState(false);
    const {
        withdrawalsList,
        page_count_withdrawals,
        next_page_withdrawals,
        totalAmount_withdrawals,
        currency_totalAmount_withdrawals,
    } = AllWithdrawals();
    const [withdrawalList, setWithdrawalList] = useState([]);
    const [nextPageWithdrawal, setNextWithdrawal] = useState(0);
    const [withdrawalPageCount, setWithdrawalPageCount] = useState(0);
    const [withdrawalTotalAmount, setWithdrawalTotalAmount] = useState(0);
    const [currencyWithdrawalTotalAmount, setCurrencyWithdrawalTotalAmount] =
        useState(0);
    const { currency } = StoredCurrentCurrency();
    const isUsdCurrency = currency === CURRENCIES.USD;

    useEffect(() => {
        const data = { ...params, page: currentPage };
        setParamsObject(data);
    }, [currentPage]);

    useEffect(() => {
        setDisableLeftArrow(currentPage === 1);
    }, [currentPage]);

    useEffect(() => {
        setDisableRightArrow(nextPageWithdrawal === 0);
    }, [nextPageWithdrawal]);

    useEffect(() => {
        if (withdrawalsList && withdrawalsList.length) {
            setWithdrawalList(withdrawalsList);
            setNextWithdrawal(next_page_withdrawals);
            setWithdrawalPageCount(page_count_withdrawals);
            setWithdrawalTotalAmount(totalAmount_withdrawals);
            setCurrencyWithdrawalTotalAmount(currency_totalAmount_withdrawals);
        }
    }, [withdrawalsList]);

    useEffect(() => {
        dispatch(
            userActions.getAllWithdrawal({
                ...paramsObject,
                page: currentPage,
            }),
        );
    }, [currentPage]);

    const handleClose = () => {
        dispatch(modalActions.close_modal());
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < withdrawalPageCount) {
            setCurrentPage(currentPage + 1);
        }
    };

    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
    };

    return (
        <AppModal>
            <AppForm
                className="max-w-[400px] w-full max-h-[calc(100vh_-_210px)] app-modal"
                title="Total withdrawals"
            >
                <AppBadge
                    className="w-fit m-auto"
                    size={21}
                    variant={'badge-green'}
                >
                    {`${formatNumber(
                        roundNumber(
                            isUsdCurrency
                                ? withdrawalTotalAmount
                                : currencyWithdrawalTotalAmount,
                            2,
                        ),
                    )} ${symbol}`}
                </AppBadge>
                <div className="flex flex-col items-start gap-3 p-0">
                    {withdrawalList.length > 0 &&
                        withdrawalList.map((item, index) => (
                            <div
                                className="flex justify-between items-center gap-2.5 w-full bg-white sm:p-5 p-3 rounded-[10px] border-[1.5px] border-solid border-[#B5B2B2] sm:text-[15px] text-base text-black font-inter"
                                key={index}
                            >
                                <p className="font-normal">{item.created_at}</p>
                                <span className="font-bold ">{`${formatNumber(
                                    roundNumber(
                                        item[
                                            isUsdCurrency
                                                ? 'amount'
                                                : 'currency_amount'
                                        ],
                                        2,
                                    ),
                                )} ${symbol}`}</span>
                            </div>
                        ))}
                </div>
                <Pagination
                    className="m-auto"
                    count={withdrawalPageCount}
                    currentPage={currentPage}
                    disableLeftArrow={disableLeftArrow}
                    disableRightArrow={disableRightArrow}
                    nextPage={nextPage}
                    paginate={paginate}
                    previousPage={previousPage}
                />
                <AppButton
                    className="m-auto sm:w-fit w-full"
                    onClick={handleClose}
                    type=""
                    variant="primary"
                >
                    Close
                </AppButton>
            </AppForm>
        </AppModal>
    );
};

export default TotalResultsWithdrawalModal;
