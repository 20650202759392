export const EmailIcon = ({ color = 'currentColor' }) => (
    <svg
        fill="none"
        height="102"
        viewBox="0 0 102 102"
        width="102"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.51214 101.875H85.5776C89.7724 101.875 93.2482 98.3993 93.2482 94.2044V39.0721C93.2482 34.8772 89.7724 31.4015 85.5776 31.4015H8.51214C4.31729 31.4015 0.841553 34.8772 0.841553 39.0721V94.2044C0.841553 98.3993 4.31729 101.875 8.51214 101.875Z"
            fill="#F4B253"
        />
        <path
            d="M90.9708 51.1771L59.8091 69.1551L90.3716 96.8411C90.7311 96.122 90.9708 95.4029 90.9708 94.5639V51.1771Z"
            fill="#FFBF56"
        />
        <path
            d="M86.0568 99.5978C87.0157 99.5978 87.9745 99.2382 88.8135 98.7588H88.9333C88.9333 98.7588 89.0532 98.7588 89.0532 98.6389L57.8914 70.3536L50.8201 74.4286C49.7414 75.0279 48.423 75.3875 47.2245 75.3875C46.6252 75.3875 46.026 75.2676 45.3068 75.1478C44.7076 75.0279 44.1083 74.7882 43.509 74.4286L36.4377 70.3536L5.75537 98.8786C6.47449 99.2382 7.31346 99.4779 8.15243 99.4779H86.0568V99.5978Z"
            fill="#FFBF56"
        />
        <path
            d="M90.9709 38.7124C90.9709 35.9558 88.6937 33.7985 86.0569 33.7985H8.1525C5.39588 33.7985 3.23853 36.0757 3.23853 38.7124V48.5404L36.9172 67.9565L44.7076 72.3911C46.2657 73.2301 48.1834 73.2301 49.6216 72.3911L90.9709 48.5404V38.7124Z"
            fill="#FFBF56"
        />
        <path
            d="M3.23851 94.5639C3.23851 95.6426 3.59806 96.4815 4.07748 97.3205L34.2804 69.1551L3.11865 51.1771V94.5639H3.23851Z"
            fill="#FFBF56"
        />
        <path
            d="M57.7718 41.2294H65.2027L62.0865 50.9375C61.8468 51.6566 62.6858 52.2559 63.2851 51.8963L80.0645 41.2294H93.0086C97.563 41.2294 101.159 37.514 101.159 33.0794V8.15C101.159 3.59559 97.4432 0 93.0086 0H57.7718C53.2174 0 49.6218 3.71544 49.6218 8.15V33.0794C49.6218 37.6338 53.2174 41.2294 57.7718 41.2294Z"
            fill="#FF9374"
        />
        <path
            d="M67.1203 15.3412H83.66C84.6189 15.3412 85.338 14.6221 85.338 13.6633C85.338 12.7045 84.6189 11.9854 83.66 11.9854H67.1203C66.1615 11.9854 65.4424 12.7045 65.4424 13.6633C65.4424 14.6221 66.1615 15.3412 67.1203 15.3412Z"
            fill="white"
        />
        <path
            d="M90.6113 19.536H60.2885C59.3297 19.536 58.6106 20.2551 58.6106 21.214C58.6106 22.1728 59.3297 22.8919 60.2885 22.8919H90.6113C91.5701 22.8919 92.2893 22.1728 92.2893 21.214C92.2893 20.2551 91.4503 19.536 90.6113 19.536Z"
            fill="white"
        />
        <path
            d="M83.66 27.0868H67.1203C66.1615 27.0868 65.4424 27.8059 65.4424 28.7647C65.4424 29.7236 66.1615 30.4427 67.1203 30.4427H83.66C84.6189 30.4427 85.338 29.7236 85.338 28.7647C85.338 27.8059 84.6189 27.0868 83.66 27.0868Z"
            fill="white"
        />
    </svg>
);
export const SuccessIcon = ({ color = 'currentColor' }) => (
    <svg
        fill="none"
        height="100"
        viewBox="0 0 100 100"
        width="100"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M27.6787 35.7142H2.67871V99.9999H27.6787V35.7142Z"
            fill="#4B6DAA"
        />
        <path
            d="M15.1785 91.0715C19.1234 91.0715 22.3214 87.8735 22.3214 83.9286C22.3214 79.9837 19.1234 76.7858 15.1785 76.7858C11.2336 76.7858 8.03564 79.9837 8.03564 83.9286C8.03564 87.8735 11.2336 91.0715 15.1785 91.0715Z"
            fill="#D8A852"
        />
        <path
            d="M95.5359 46.4286C95.5359 42.4839 92.3376 39.2857 88.393 39.2857H72.3216H66.9644H60.5323L62.2966 31.0375C63.643 24.7446 63.2823 17.075 62.4823 11.6875C61.6823 6.3 56.8573 0 51.8091 0H51.3823C47.0787 0 45.5359 4.74821 45.5359 10.7143C45.5359 39.7321 31.2501 39.2857 31.2501 39.2857H27.6787V96.4286H54.4644H72.3216H79.4644C83.4091 96.4286 86.6073 93.2304 86.6073 89.2857C86.6073 85.3411 83.4091 82.1429 79.4644 82.1429H84.8216C88.7662 82.1429 91.9644 78.9446 91.9644 75C91.9644 71.0554 88.7662 67.8571 84.8216 67.8571H90.1787C94.1234 67.8571 97.3216 64.6589 97.3216 60.7143C97.3216 56.2625 94.4412 54.5679 90.1787 53.9357C88.1019 53.6268 84.3287 53.5714 83.0359 53.5714H88.393C92.3376 53.5714 95.5359 50.3732 95.5359 46.4286Z"
            fill="#FBCE9D"
        />
        <path
            d="M93.0714 51.7858H70.5357C69.55 51.7858 68.75 52.584 68.75 53.5715C68.75 54.559 69.55 55.3572 70.5357 55.3572H94.5643C93.3911 54.6376 91.9071 54.1911 90.1786 53.9358C88.1018 53.6268 84.3286 53.5715 83.0357 53.5715H88.3929C90.1929 53.5715 91.8161 52.884 93.0714 51.7858Z"
            fill="#F7B563"
        />
        <path
            d="M94.8571 66.0714H70.5357C69.55 66.0714 68.75 66.8696 68.75 67.8571C68.75 68.8446 69.55 69.6428 70.5357 69.6428H89.5018C88.2464 68.5446 86.6214 67.8571 84.8214 67.8571H90.1786C91.9786 67.8571 93.6018 67.1696 94.8571 66.0714Z"
            fill="#F7B563"
        />
        <path
            d="M89.5001 80.3572H66.9644C65.9787 80.3572 65.1787 81.1554 65.1787 82.1429C65.1787 83.1304 65.9787 83.9286 66.9644 83.9286H84.1448C82.8894 82.8304 81.2644 82.1429 79.4644 82.1429H84.8216C86.6216 82.1429 88.2448 81.4554 89.5001 80.3572Z"
            fill="#F7B563"
        />
    </svg>
);
