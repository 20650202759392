import { userService } from '../services';
import {
    GET_COUNTRIES_FAILED,
    GET_COUNTRIES_REQUEST,
    GET_COUNTRIES_SUCCESS,
    GET_INDUSTRIES_FAILED,
    GET_INDUSTRIES_REQUEST,
    GET_INDUSTRIES_SUCCESS,
    GET_STYLES_GROUP_FAILED,
    GET_STYLES_GROUP_REQUEST,
    GET_STYLES_GROUP_SUCCESS,
    GET_TOP_HOLDINGS_FAILED,
    GET_TOP_HOLDINGS_REQUEST,
    GET_TOP_HOLDINGS_SUCCESS,
    GET_TOTAL_FUND_FAILED,
    GET_TOTAL_FUND_REQUEST,
    GET_TOTAL_FUND_SUCCESS,
} from './actionTypes';

export const fundActions = {
    getTotalFund,
    getIndustryList,
    getCountryList,
    getStyleGroupList,
};

function getTotalFund() {
    const endpoint = '/funds';
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results.fund));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_TOTAL_FUND_REQUEST };
    }
    function success(data) {
        return { type: GET_TOTAL_FUND_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_TOTAL_FUND_FAILED, error };
    }
}
function getIndustryList() {
    const endpoint = '/funds/industries';
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results.industries));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_INDUSTRIES_REQUEST };
    }
    function success(data) {
        return { type: GET_INDUSTRIES_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_INDUSTRIES_FAILED, error };
    }
}
function getCountryList() {
    const endpoint = '/funds/countries';
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results.countries));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_COUNTRIES_REQUEST };
    }
    function success(data) {
        return { type: GET_COUNTRIES_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_COUNTRIES_FAILED, error };
    }
}
function getStyleGroupList() {
    const endpoint = '/funds/style-groups';
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results.groups));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_STYLES_GROUP_REQUEST };
    }
    function success(data) {
        return { type: GET_STYLES_GROUP_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_STYLES_GROUP_FAILED, error };
    }
}
function getTopHoldings() {
    const endpoint = '/top-holdings';
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results.industries));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_TOP_HOLDINGS_REQUEST };
    }
    function success(data) {
        return { type: GET_TOP_HOLDINGS_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_TOP_HOLDINGS_FAILED, error };
    }
}
