import { apiUrl } from '../costants';

const defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
};

function handleResponse(response) {
    try {
        return response.json().then(data => {
            switch (response.status) {
                case 404:
                    return Promise.reject(data);
                case 500:
                    return Promise.reject({ errorCode: 500 });
                case 401:
                    localStorage.removeItem('userInfo');
                    !window.location.pathname?.includes('/login') &&
                        window.location.reload();
                    return { data, status: response.status };
                default:
                    return { data, status: response.status };
            }
        });
    } catch (e) {
        return Promise.reject({ errorCode: 500, error: e });
    }
}

const getAuthorizationHeaders = () => {
    const data = JSON.parse(localStorage.getItem('userInfo'));
    const token = data?.token;
    let authHeaders = {};
    if (token) {
        authHeaders = {
            Authorization: `Bearer ${token}`,
        };
    }
    return authHeaders;
};

export const doFetch = (url, options) => {
    options.headers = { ...options.headers, ...defaultHeaders };

    return fetch(`${apiUrl}${url}`, options)
        .then(handleResponse)
        .then(data => {
            return data;
        });
};

export const doFetchWithToken = (url, options) => {
    options.headers = { ...options.headers, ...getAuthorizationHeaders() };

    return doFetch(url, options);
};
