import AppTooltip from '../index';
import { useEffect, useRef, useState } from 'react';

export default function TooltipWithClick({
    children,
    variant,
    message,
    classname,
    isDataLength = true,
}) {
    const [showTooltip, setShowTooltip] = useState(false);
    const wrapperRef = useRef(null);

    const handleClick = e => {
        e.stopPropagation();
        if (isDataLength) {
            setShowTooltip(!showTooltip);
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event, ref) => {
            event.stopPropagation();
            if (ref?.current && !ref?.current.contains(event.target)) {
                setShowTooltip(false);
            }
        };

        document.addEventListener('mousedown', e =>
            handleOutsideClick(e, wrapperRef),
        );
        return () => {
            document.removeEventListener('mousedown', e =>
                handleOutsideClick(e, wrapperRef),
            );
        };
    }, [wrapperRef]);

    return (
        <AppTooltip
            classname={classname}
            message={message}
            onClick={handleClick}
            showTooltip={showTooltip}
            variant={variant}
            wrapperRef={wrapperRef}
        >
            {children}
        </AppTooltip>
    );
}
