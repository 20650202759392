export const CURRENCIES = {
    RUB: 'RUB',
    CAD: 'CAD',
    AUD: 'AUD',
    EUR: 'EUR',
    GBP: 'GBP',
    USD: 'USD',
};
export const _CURRENCY_SYMBOLS = {
    RUB: '₽',
    CAD: 'C$',
    AUD: 'A$',
    EUR: '€',
    GBP: '£',
    USD: '$',
};

export const CURRENCY_COEFFICIENT = {
    DEFAULT: 1,
};
