import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useController, useForm } from 'react-hook-form';
import { applicationActions } from '../../../../../../investor/actions/application.actions';
import { modalActions } from '../../../../../actions/modal.actions';
import { CreateApplication } from '../../../../../../investor/investor.selectors';
import AppButton from '../../../../AppButton';
import AppForm from '../../../../AppForm';
import AppInput from '../../../../AppInput';
import AppSelect from '../../../../AppSelect';
import { DURATION_LIST } from '../../../../../../investor/components/AppHome/applicationConstants';

const AppNewApplication = ({
    children,
    cashLabel = '',
    text = '',
    title = 'New application',
    onSubmit,
    prevValues,
    rules = null,
}) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        mode: 'onChange' || '',
    });
    const { field } = useController({
        name: 'duration',
        control,
        rules: rules,
    });
    const dispatch = useDispatch();
    const { value: timeValue, onChange: timeOnChange } = field;
    const { applicationData, type } = CreateApplication();
    const [errorMessage, setErrorMessage] = useState('');
    const [errorLength, setErrorLength] = useState(false);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setErrorLength(true);
        }
    }, [errors]);

    useEffect(() => {
        if (applicationData && type === 'danger') {
            return () => {
                dispatch(applicationActions.clearApplication());
            };
        }
    }, [applicationData]);

    useEffect(() => {
        if (applicationData && type === 'danger') {
            setDisable(false);
        }
    }, [applicationData, type]);

    useEffect(() => {
        if (applicationData && type === 'danger') {
            setErrorMessage(applicationData);
        }
    }, [applicationData]);

    const handleSelect = selected => {
        timeOnChange(selected);
        setErrorMessage('');
    };

    const handleBack = () => {
        dispatch(modalActions.close_modal());
    };

    const preventMinus = e => {
        if (
            e.code === 'Minus' ||
            e.code === 'NumpadSubtract' ||
            e.code === 'KeyE'
        ) {
            e.preventDefault();
        }
    };
    const onSave = data => {
        onSubmit(data);
        setDisable(true);
    };

    return (
        <AppForm
            className="max-w-[400px] w-full max-h-[calc(100vh_-_210px)]"
            onSubmit={handleSubmit(onSave)}
            text={text}
            title={title}
        >
            <>
                {children}
                <div className="flex flex-col gap-[12px]">
                    <AppInput
                        error={errors}
                        id="amount"
                        label={cashLabel}
                        min="0"
                        onKeyPress={preventMinus}
                        register={register}
                        step="any"
                        type="number"
                        value={prevValues?.amount}
                    />
                    <AppSelect
                        errorMessage={errorLength && errors?.duration?.message}
                        id="duration"
                        list={DURATION_LIST}
                        onChange={handleSelect}
                        placeholder="When"
                        selectedValue={timeValue || prevValues?.when}
                    />
                    {errorMessage && (
                        <p className="text-sm text-[#C90000] dark:text-red-500 font-[500]">
                            {errorMessage}
                        </p>
                    )}
                </div>
                <div className="flex sm:flex-row flex-col items-center md:justify-self-auto justify-center p-2.5 sm:gap-2.5 gap-2 sm:h-[79px] sm:mb-[25px]">
                    <AppButton
                        className="btn-small-height sm:w-fit w-full"
                        onClick={handleBack}
                        type=""
                        variant="btn-secondary"
                    >
                        Back
                    </AppButton>
                    <AppButton
                        className="btn-small-height sm:w-fit w-full"
                        disabled={disable}
                        type=""
                        variant="primary"
                    >
                        Save
                    </AppButton>
                </div>
            </>
        </AppForm>
    );
};

export default AppNewApplication;
