import { useEffect, useState } from 'react';
import { GetNotifications } from '../../manager.selectors';
import { NAME_BY_ROLES } from '../../../common/routes/costants';
import TooltipWithClick from '../../../common/components/AppTooltip/TooltipWithClick';
import NavbarLink from '../../../common/components/Navbars/NavbarLink';
import NavbarUser from '../../../common/components/Navbars/NavbarUser';
import AdminNotificationPopup from './AdminNotificationPopup';

export default function () {
    const { notifications } = GetNotifications();
    const [isDataLength, setIsDataLength] = useState(false);

    useEffect(() => {
        setIsDataLength(
            Array.isArray(notifications) && notifications?.length > 0,
        );
    }, [notifications]);

    return (
        <NavbarUser
            logoUrl={`/${NAME_BY_ROLES.Manager}`}
            notification={
                <TooltipWithClick
                    classname="right-[-0.6rem]"
                    isDataLength={isDataLength}
                    message={<AdminNotificationPopup />}
                    variant="tooltip-left-bottom"
                >
                    <button className="flex  relative">
                        <span className="w-8 h-8 flex items-center justify-center">
                            <svg
                                fill="none"
                                height="21"
                                viewBox="0 0 18 21"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.775 17.0637C11.775 18.5963 10.5326 19.8387 8.99996 19.8387C7.46737 19.8387 6.22496 18.5963 6.22496 17.0637M10.6617 5.25938C11.0646 4.84321 11.3125 4.27616 11.3125 3.65121C11.3125 2.37406 10.2771 1.33871 8.99996 1.33871C7.7228 1.33871 6.68746 2.37406 6.68746 3.65121C6.68746 4.27616 6.93536 4.84321 7.3382 5.25938M14.55 9.84871C14.55 8.57302 13.9652 7.34958 12.9244 6.44753C11.8836 5.54548 10.4719 5.03871 8.99996 5.03871C7.52801 5.03871 6.11635 5.54548 5.07552 6.44753C4.03469 7.34958 3.44996 8.57302 3.44996 9.84871C3.44996 11.9594 2.92653 13.503 2.27342 14.6075C1.52905 15.8664 1.15686 16.4959 1.17156 16.6462C1.18838 16.8182 1.21934 16.8725 1.35885 16.9745C1.48079 17.0637 2.09346 17.0637 3.31879 17.0637H14.6811C15.9065 17.0637 16.5191 17.0637 16.6411 16.9745C16.7806 16.8725 16.8115 16.8182 16.8284 16.6462C16.8431 16.4959 16.4709 15.8664 15.7265 14.6075C15.0734 13.503 14.55 11.9594 14.55 9.84871Z"
                                    stroke="black"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                />
                            </svg>
                        </span>
                        {Array.isArray(notifications) &&
                        notifications?.length > 0 ? (
                            <span className=" absolute w-2.5 h-2.5 bg-[#9169f6] rounded-full  right-1.5 top-1.5"></span>
                        ) : null}
                    </button>
                </TooltipWithClick>
            }
            settingUrl="/admin/settings"
        >
            <NavbarLink name="Fund" navigationLink="/admin/fund" />
            <NavbarLink name="Holders" navigationLink="/admin/holders" />
        </NavbarUser>
    );
}
