import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { lotsActions } from '../../../../../manager/actions/lots.actions';
import AppModal from '../../../AppModal';
import UpdateForm from '../../ModalFormComponents/updateForm';

const DeleteLotModal = ({ lotId, symbol, groupId }) => {
    const dispatch = useDispatch();
    const { handleSubmit } = useForm({ mode: 'onChange' });

    const onSubmit = () => {
        return dispatch(lotsActions.deleteLot(lotId, symbol, groupId));
    };

    return (
        <AppModal>
            <UpdateForm
                btnText="Yes, delete"
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                title="Hey!"
            >
                <p className="font-normal text-[15px] text-center text-[#0B1F35] font-roboto">
                    Are you sure, that you want to delete lot?
                </p>
            </UpdateForm>
        </AppModal>
    );
};

export default DeleteLotModal;
