import { useEffect } from 'react';
import { groupsActions } from '../../../../../manager/actions/groups.actions';
import { AddGroups } from '../../../../../manager/manager.selectors';
import AppModal from '../../../AppModal';
import AppInput from '../../../AppInput';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import UpdateForm from '../../ModalFormComponents/updateForm';

const AddNewGroupModal = () => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({ mode: 'onChange' });
    const { data, type } = AddGroups();

    useEffect(() => {
        return () => {
            dispatch(groupsActions.addGroupClear());
        };
    }, []);

    useEffect(() => {
        if (data && type === 'danger') {
            setError('name', { type: 'custom', message: `${data}` });
        }
    }, [data, type]);

    const onSubmit = userData => {
        return dispatch(groupsActions.addGroup(userData));
    };

    return (
        <AppModal>
            <UpdateForm
                btnText="Save"
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                title="Adding new group"
            >
                <AppInput
                    error={errors}
                    id="name"
                    label="Group name"
                    register={register}
                    type="text"
                    value=""
                />
            </UpdateForm>
        </AppModal>
    );
};

export default AddNewGroupModal;
