import {
    GET_ALL_INSTALLMENTS_FAIL,
    GET_ALL_INSTALLMENTS_REQUEST,
    GET_ALL_INSTALLMENTS_SUCCESS,
    GET_ALL_WITHDRAWAL_FAIL,
    GET_ALL_WITHDRAWAL_REQUEST,
    GET_ALL_WITHDRAWAL_SUCCESS,
    GET_INSTALLMENT_CLEAR,
    GET_INVESTMENTS_FAILED,
    GET_INVESTMENTS_REQUEST,
    GET_INVESTMENTS_SUCCESS,
    GET_INVESTMENTS_WITHDRAWALS_CLEAR,
    GET_INVESTMENTS_WITHDRAWALS_FAILED,
    GET_INVESTMENTS_WITHDRAWALS_REQUEST,
    GET_INVESTMENTS_WITHDRAWALS_SUCCESS,
} from '../actions/actionTypes';

const initialInstallment = {
    loading: false,
    installmentList: [],
    page_count_installment: 0,
    next_page_instalments: 0,
    totalAmount_instalments: 0,
    currency_totalAmount_instalments: 0,
};
const initialWithdrawals = {
    loading: false,
    withdrawalsList: [],
    page_count_withdrawals: 0,
    next_page_withdrawals: 0,
    currency_totalAmount_withdrawals: 0,
};

export function installments(state = initialInstallment, action) {
    switch (action.type) {
        case GET_INVESTMENTS_SUCCESS:
            return {
                loading: false,
                installmentList: action.data.data,
                page_count_installment: action.data.page_count,
                next_page_instalments: action.data.next_page,
                totalAmount_instalments: action.data.total_amount,
                currency_totalAmount_instalments:
                    action.data.currency_total_amount,
            };
        case GET_INVESTMENTS_FAILED:
            return {
                loading: false,
                installmentList: action.error,
                page_count_installment: 0,
                next_page_instalments: 0,
                totalAmount_instalments: 0,
                currency_totalAmount_instalments: 0,
            };
        case GET_INVESTMENTS_REQUEST:
            return {
                loading: true,
                installmentList: [],
                page_count_installment: 0,
                next_page_instalments: 0,
                totalAmount_instalments: 0,
                currency_totalAmount_instalments: 0,
            };
        case GET_INSTALLMENT_CLEAR:
            return {
                loading: false,
                installmentList: [],
                page_count_installment: 0,
                next_page_instalments: 0,
                totalAmount_instalments: 0,
                currency_totalAmount_instalments: 0,
            };

        default:
            return state;
    }
}
export function withdrawals(state = initialWithdrawals, action) {
    switch (action.type) {
        case GET_INVESTMENTS_WITHDRAWALS_SUCCESS:
            return {
                loading: false,
                withdrawalsList: action.data?.data,
                page_count_withdrawals: action.data?.page_count,
                next_page_withdrawals: action.data?.next_page,
                totalAmount_withdrawals: action.data?.total_amount,
                currency_totalAmount_withdrawals:
                    action.data?.currency_total_amount,
            };
        case GET_INVESTMENTS_WITHDRAWALS_FAILED:
            return {
                loading: false,
                withdrawalsList: action.error,
                page_count_withdrawals: 0,
                next_page_withdrawals: 0,
                totalAmount_withdrawals: 0,
                currency_totalAmount_withdrawals: 0,
            };
        case GET_INVESTMENTS_WITHDRAWALS_REQUEST:
            return {
                loading: true,
                withdrawalsList: [],
                page_count_withdrawals: 0,
                next_page_withdrawals: 0,
                totalAmount_withdrawals: 0,
                currency_totalAmount_withdrawals: 0,
            };
        case GET_INVESTMENTS_WITHDRAWALS_CLEAR:
            return {
                loading: false,
                withdrawalsList: [],
                page_count_withdrawals: 0,
                next_page_withdrawals: 0,
                totalAmount_withdrawals: 0,
                currency_totalAmount_withdrawals: 0,
            };

        default:
            return state;
    }
}
export function allInstallments(state = initialInstallment, action) {
    switch (action.type) {
        case GET_ALL_INSTALLMENTS_SUCCESS:
            return {
                loading: false,
                installmentList: action.data?.data,
                page_count_installment: action.data?.page_count,
                next_page_instalments: action.data?.next_page,
                totalAmount_instalments: action.data?.total_amount,
                currency_totalAmount_instalments:
                    action.data?.currency_total_amount,
            };
        case GET_ALL_INSTALLMENTS_FAIL:
            return {
                loading: false,
                installmentList: action.error,
                page_count_installment: 0,
                next_page_instalments: 0,
                totalAmount_instalments: 0,
                currency_totalAmount_instalments: 0,
            };
        case GET_ALL_INSTALLMENTS_REQUEST:
            return {
                loading: true,
                installmentList: [],
                page_count_installment: 0,
                next_page_instalments: 0,
                totalAmount_instalments: 0,
                currency_totalAmount_instalments: 0,
            };
        case GET_INSTALLMENT_CLEAR:
            return {
                loading: false,
                installmentList: [],
                page_count_installment: 0,
                next_page_instalments: 0,
                totalAmount_instalments: 0,
                currency_totalAmount_instalments: 0,
            };

        default:
            return state;
    }
}
export function allWithdrawals(state = initialWithdrawals, action) {
    switch (action.type) {
        case GET_ALL_WITHDRAWAL_SUCCESS:
            return {
                loading: false,
                withdrawalsList: action.data.data,
                page_count_withdrawals: action.data.page_count,
                next_page_withdrawals: action.data.next_page,
                totalAmount_withdrawals: action.data.total_amount,
                currency_totalAmount_withdrawals:
                    action.data.currency_total_amount,
            };
        case GET_ALL_WITHDRAWAL_FAIL:
            return {
                loading: false,
                withdrawalsList: action.error,
                page_count_withdrawals: 0,
                next_page_withdrawals: 0,
                totalAmount_withdrawals: 0,
                currency_totalAmount_withdrawals: 0,
            };
        case GET_ALL_WITHDRAWAL_REQUEST:
            return {
                loading: true,
                withdrawalsList: [],
                page_count_withdrawals: 0,
                next_page_withdrawals: 0,
                totalAmount_withdrawals: 0,
                currency_totalAmount_withdrawals: 0,
            };
        case GET_INVESTMENTS_WITHDRAWALS_CLEAR:
            return {
                loading: false,
                withdrawalsList: [],
                page_count_withdrawals: 0,
                next_page_withdrawals: 0,
                totalAmount_withdrawals: 0,
                currency_totalAmount_withdrawals: 0,
            };
        default:
            return state;
    }
}
