import {
    CLEAR_APPLICATION,
    CREATE_APPLICATION_FAILED,
    CREATE_APPLICATION_REQUEST,
    CREATE_APPLICATION_SUCCESS,
} from '../actions/actionTypes';

const initialApplication = {
    loading: false,
    applicationData: '',
    type: '',
};

export function createApplication(state = initialApplication, action) {
    switch (action.type) {
        case CREATE_APPLICATION_SUCCESS:
            return {
                loading: false,
                applicationData: action.data,
                type: 'success',
            };
        case CREATE_APPLICATION_FAILED:
            return {
                loading: false,
                applicationData: action.error,
                type: 'danger',
            };
        case CREATE_APPLICATION_REQUEST:
            return {
                loading: true,
                applicationData: '',
                type: '',
            };
        case CLEAR_APPLICATION:
            return {
                loading: false,
                applicationData: '',
                type: '',
            };

        default:
            return state;
    }
}
