import { combineReducers } from 'redux';
import {
    createApplication,
    createPasswordReducer,
    setCurrentCurrency,
    storedCurrentCurrency,
} from './index';

const investorReducer = combineReducers({
    storedCurrentCurrency,
    createApplication,
    createPasswordReducer,
    setCurrentCurrency,
});

export default investorReducer;
