export const MenuIcon = () => (
    <svg
        fill="none"
        height="36"
        viewBox="0 0 36 36"
        width="36"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clipRule="evenodd"
            d="M4 7H32C33.1046 7 34 7.89543 34 9C34 10.1046 33.1046 11 32 11H4C2.89543 11 2 10.1046 2 9C2 7.89543 2.89543 7 4 7ZM4 16H32C33.1046 16 34 16.8955 34 18C34 19.1046 33.1046 20 32 20H4C2.89543 20 2 19.1046 2 18C2 16.8955 2.89543 16 4 16ZM4 25.0001H32C33.1046 25.0001 34 25.8955 34 27.0001C34 28.1046 33.1046 29.0001 32 29.0001H4C2.89543 29.0001 2 28.1046 2 27.0001C2 25.8955 2.89543 25.0001 4 25.0001Z"
            fill="black"
            fillRule="evenodd"
        />
    </svg>
);
export const CloseButton = () => (
    <svg
        fill="none"
        height="26"
        viewBox="0 0 26 26"
        width="26"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M23 23L13 13L3 23"
            stroke="black"
            strokeLinecap="round"
            strokeWidth="4"
        />
        <path
            d="M3 3L13 13L23 3"
            stroke="black"
            strokeLinecap="round"
            strokeWidth="4"
        />
    </svg>
);
