import { CLOSE_MODAL, OPEN_MODAL } from '../actions/actionTypes';

const initialState = {
    name: '',
    open_modal: false,
    props: {},
};

export function modals(state = initialState, action) {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                name: action.name,
                open_modal: true,
                props: action?.props,
            };
        case CLOSE_MODAL:
            return { open_modal: false, name: '', props: {} };
        default:
            return state;
    }
}
