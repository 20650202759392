import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { lotsActions } from '../../../../../manager/actions/lots.actions';
import { SellLot } from '../../../../../manager/manager.selectors';
import AppBadge from '../../../AppBadge';
import { AppDatePicker } from '../../../AppDatePicker';
import { LOT_TYPE } from '../../../../../manager/components/AppGroup/lots.constants';
import AppModal from '../../../AppModal';
import AppForm from '../../../AppForm';
import AppButton from '../../../AppButton';
import AppInput from '../../../AppInput';
import { debounce } from '../../../../helpers/debounce';
import { modalActions } from '../../../../actions/modal.actions';
import { formatNumber, roundNumber } from '../../../../helpers/util';

const SellLotModal = ({ symbol = '', total = 0, groupId }) => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        mode: 'onChange' || '',
    });
    const watchShareCount = watch('shares_count', '');
    const watchPrice = watch('price', '');
    const { sellData, type } = SellLot();
    const [errorMessage, setErrorMessage] = useState('');
    const [disable, setDisable] = useState(false);
    const [totalLot, setTotalLot] = useState(0);

    useEffect(() => {
        if (sellData && type === 'danger') {
            setErrorMessage(sellData);
        }
        return () => {
            dispatch(lotsActions.sellLotClear());
        };
    }, [sellData]);

    useEffect(() => {
        const totalData = Number(watchShareCount) * Number(watchPrice);
        debounce(setTotalLot(formatNumber(roundNumber(totalData, 2))));
    }, [watchShareCount, watchPrice]);

    useEffect(() => {
        setErrorMessage(
            Number(watchShareCount) > total
                ? "You can't sell that many lots!"
                : '',
        );
    }, [watchShareCount]);

    useEffect(() => {
        if (watchShareCount && Number(watchShareCount) === 0) {
            setErrorMessage("You can't sell 0 lot");
        }
    }, [watchShareCount]);

    useEffect(() => {
        setDisable(!!errorMessage);
    }, [errorMessage]);

    const onSubmit = userData => {
        const data = {
            ...userData,
            company_symbol: symbol,
            type: LOT_TYPE.sell,
        };
        return dispatch(lotsActions.sellLot(data, groupId));
    };

    const preventMinus = e => {
        if (
            e.code === 'Minus' ||
            e.code === 'NumpadSubtract' ||
            e.code === 'KeyE'
        ) {
            e.preventDefault();
        }
    };

    const handleBack = () => {
        dispatch(modalActions.close_modal());
    };

    const totalShare = useMemo(() => {
        return formatNumber(roundNumber(total, 2));
    }, [total]);

    const handleChange = () => {
        setErrorMessage('');
    };

    return (
        <AppModal>
            <AppForm
                className="max-w-[400px] w-full max-h-[calc(100vh_-_210px)] "
                onSubmit={handleSubmit(onSubmit)}
                title={`Sell ${symbol} lot`}
            >
                <AppBadge
                    className="w-fit m-auto"
                    size={21}
                    variant={'badge-green'}
                >
                    {`${totalLot} $`}
                </AppBadge>
                <div className="flex flex-col gap-3">
                    <AppInput
                        error={errors}
                        id="shares_portfolio"
                        label="Shares in portfolio"
                        readOnly={true}
                        type="text"
                        value={totalShare}
                    />
                    <AppInput
                        error={errors}
                        id="shares_count"
                        label="Number of shares"
                        min="0"
                        onKeyPress={preventMinus}
                        register={register}
                        type="number"
                    />
                    <AppInput
                        error={errors}
                        id="price"
                        label="Price per share"
                        min="0"
                        onKeyPress={preventMinus}
                        register={register}
                        step="any"
                        type="number"
                    />
                    <AppDatePicker
                        error={errors}
                        handleChange={handleChange}
                        id="traded_at"
                        register={register}
                    />
                    {errorMessage && (
                        <p className="text-sm text-[#C90000] dark:text-red-500 font-[500]">
                            {errorMessage}
                        </p>
                    )}
                </div>
                <div className="flex flex-wrap items-center md:justify-self-auto justify-center gap-2.5 h-[79px]">
                    <AppButton
                        className="max-w-[134px] btn-small-height"
                        onClick={handleBack}
                        type=""
                        variant="btn-secondary"
                    >
                        Back
                    </AppButton>
                    <AppButton
                        className="max-w-fit btn-small-height"
                        disabled={disable}
                        type=""
                        variant="primary"
                    >
                        Save
                    </AppButton>
                </div>
            </AppForm>
        </AppModal>
    );
};

export default SellLotModal;
