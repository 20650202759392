import { combineReducers } from 'redux';
import {
    allInstallments,
    allWithdrawals,
    cashIn,
    cashOut,
    changeEmail,
    changePassword,
    chart,
    confirmPassword,
    countryList,
    createdUser,
    deleteUser,
    getApplications,
    getTotalFund,
    installments,
    loginReducer,
    newUser,
    passwordRecoveryReducer,
    resetPasswordReducer,
    storedData,
    styleGroupList,
    topHoldings,
    updateProfile,
    userData,
    userHolding,
    userInfo,
    users,
    withdrawals,
} from './index';
import { industryList } from './fund.reducer';

const projectReducer = combineReducers({
    loginReducer,
    passwordRecoveryReducer,
    resetPasswordReducer,
    userInfo,
    userData,
    changeEmail,
    changePassword,
    confirmPassword,
    newUser,
    users,
    installments,
    withdrawals,
    updateProfile,
    deleteUser,
    createdUser,
    allInstallments,
    allWithdrawals,
    getTotalFund,
    cashIn,
    cashOut,
    userHolding,
    industryList,
    countryList,
    styleGroupList,
    storedData,
    chart,
    topHoldings,
    getApplications,
});

export default projectReducer;
