import { userService } from '../../common/services';
import { fundActions } from '../../common/actions/fund.action';
import {
    ADD_SYMBOL_CLEAR,
    ADD_SYMBOL_FAILED,
    ADD_SYMBOL_REQUEST,
    ADD_SYMBOL_SUCCESS,
    DELETE_SYMBOL_FAILED,
    DELETE_SYMBOL_REQUEST,
    DELETE_SYMBOL_SUCCESS,
    GET_SYMBOLS_CLEAR,
    GET_SYMBOLS_FAILED,
    GET_SYMBOLS_REQUEST,
    GET_SYMBOLS_SUCCESS,
} from './actionTypes';
import { groupsActions } from './groups.actions';
import { modalActions } from '../../common/actions/modal.actions';

export const symbolActions = {
    addSymbol,
    getSymbols,
    deleteSymbol,
    addSymbolClear,
};

function addSymbol(symbolData) {
    const endpoint = '/funds/groups/symbols';
    return dispatch => {
        dispatch(request());
        userService.PostInfo(endpoint, symbolData).then(data => {
            if (data.status === 200) {
                dispatch(groupsActions.getGroupSymbols(symbolData.group_id));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: ADD_SYMBOL_REQUEST };
    }
    function success(data) {
        return { type: ADD_SYMBOL_SUCCESS, data };
    }
    function fail(error) {
        return { type: ADD_SYMBOL_FAILED, error };
    }
}

function addSymbolClear() {
    return { type: ADD_SYMBOL_CLEAR };
}

function getSymbols(query) {
    const endpoint = `/funds/groups/symbols?search=${query}`;
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_SYMBOLS_REQUEST };
    }
    function success(data) {
        return { type: GET_SYMBOLS_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_SYMBOLS_FAILED, error };
    }
}

function getSymbolsClear() {
    return { type: GET_SYMBOLS_CLEAR };
}

function deleteSymbol(symbol, groupId) {
    return dispatch => {
        dispatch(request());
        userService.DeleteInfo(`/funds/groups/symbols/${symbol}`).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: { button: true },
                    }),
                );
                dispatch(groupsActions.getGroupSymbols(groupId));
                dispatch(groupsActions.getRealizedGain(groupId));
                dispatch(groupsActions.getGroup(groupId));
                dispatch(groupsActions.getGroups());
                dispatch(fundActions.getTotalFund());
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: DELETE_SYMBOL_REQUEST };
    }
    function success(data) {
        return { type: DELETE_SYMBOL_SUCCESS, data };
    }
    function fail(error) {
        return { type: DELETE_SYMBOL_FAILED, error };
    }
}
