import { useEffect } from 'react';
import { userActions } from '../../../../actions/user.actions';
import {
    CreatedUser,
    UpdateProfile,
} from '../../../../store/project/project.selectors';
import AppModal from '../../../AppModal';
import AppInput from '../../../AppInput';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import UpdateForm from '../../ModalFormComponents/updateForm';

const UpdateUserModal = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({ mode: 'onChange' });
    const dispatch = useDispatch();
    const { user } = CreatedUser();
    const { data, type } = UpdateProfile();

    useEffect(() => {
        if (data && type === 'danger') {
            setError('email', { type: 'custom', message: `${data}` });
        }
    }, [data, type]);

    const onSubmit = userData => {
        return dispatch(userActions.updateProfile(userData, `${user.id}`));
    };

    return (
        <AppModal>
            <UpdateForm
                btnText="Save"
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                title="Personal data"
            >
                <AppInput
                    error={errors}
                    id="email"
                    label="Email"
                    optionObject={{
                        required: true,
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Incorrect email format',
                        },
                    }}
                    register={register}
                    type="email"
                    value={user?.email}
                />
            </UpdateForm>
        </AppModal>
    );
};

export default UpdateUserModal;
