import AddNewGroupModal from './ModalComponents/AddNewGroupModal';
import ApplicationModal from './ModalComponents/ApplicationModal';
import SelectedApplicationModal from './ModalComponents/ApplicationModal/SelectedApplicationModal';
import BuyLotModal from './ModalComponents/BuyLotModal';
import CashModal from './ModalComponents/CashModal';
import AddInvestmentModal from './ModalComponents/CashModal/AddInvestment';
import CashInSelected from './ModalComponents/CashModal/CashInSelected';
import CashOutSelected from './ModalComponents/CashModal/CashOutSelected';
import DeleteAccountModal from './ModalComponents/DeleteAccountModal';
import DeleteDataModal from './ModalComponents/DeleteDataModal';
import DeleteGroupModal from './ModalComponents/DeleteGroup';
import DeleteLotModal from './ModalComponents/DeleteLotModal';
import DeleteSymbolModal from './ModalComponents/DeleteSymbol';
import GroupRenameModal from './ModalComponents/GroupRenameModal';
import HoldersListModal from './ModalComponents/HoldersListModal';
import OopsModal from './ModalComponents/ForbiddenModal';
import ResendInvitationModal from './ModalComponents/ResendInvitationModal';
import SellLotModal from './ModalComponents/SellLotModal';
import SuccessModal from './ModalComponents/SuccessModal';
import PersonalDataModal from './ModalComponents/PersonalDataModal';
import PasswordChangeModal from './ModalComponents/PasswordeChangeModal';
import EmailChangeModal from './ModalComponents/EmailChangeModal';
import SignOutAllDevices from './ModalComponents/SignOutAllDevices';
import CheckEmailModal from './ModalComponents/CheckEmailModal';
import PasswordChangeConfirmModal from './ModalComponents/PasswordChangeConfirmModal';
import ResetSuccessModal from './ModalComponents/ResetSuccessModal';
import AddNewUserModal from './ModalComponents/AddNewUserModal';
import TotalResultsModal from './ModalComponents/TotalResultsModal';
import TotalResultsWithdrawalModal from './ModalComponents/TotalResultsWithdrawalModal';
import UpdateUserModal from './ModalComponents/UpdateUserModal';
import UpdateUserPasswordModal from './ModalComponents/UpdateUserPasswordModal';
import DeleteTransaction from './ModalComponents/DeleteTransaction';

export const MODALS_CONFIG = {
    successReset: ResetSuccessModal,
    authSuccess: SuccessModal,
    personalData: PersonalDataModal,
    passwordChangeModal: PasswordChangeModal,
    emailChangeModal: EmailChangeModal,
    signOutAllDevices: SignOutAllDevices,
    checkEmailModal: CheckEmailModal,
    passwordChangeConfirmModal: PasswordChangeConfirmModal,
    addNewUser: AddNewUserModal,
    totalResults: TotalResultsModal,
    totalResultsWithdrawal: TotalResultsWithdrawalModal,
    resendInvitationEmail: ResendInvitationModal,
    deleteAccount: DeleteAccountModal,
    forbidden: OopsModal,
    updateUser: UpdateUserModal,
    updateUserPassword: UpdateUserPasswordModal,
    deleteSymbol: DeleteSymbolModal,
    deleteGroup: DeleteGroupModal,
    deleteLot: DeleteLotModal,
    renameGroup: GroupRenameModal,
    buyBump: BuyLotModal,
    sellBump: SellLotModal,
    addGroupModal: AddNewGroupModal,
    addInvestment: AddInvestmentModal,
    cash: CashModal,
    cashIn: CashInSelected,
    cashOut: CashOutSelected,
    deleteTransaction: DeleteTransaction,
    application: ApplicationModal,
    selectedApplication: SelectedApplicationModal,
    deleteData: DeleteDataModal,
    holdersList: HoldersListModal,
};
