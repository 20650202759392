import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../actions/modal.actions';
import useChangedPathname from '../../hooks/useChangedPathname';

const AppModal = ({ children, isNavigated = false, ...props }) => {
    const dispatch = useDispatch();
    const [changedPathname] = useChangedPathname();

    useEffect(() => {
        if (changedPathname && !isNavigated) {
            onClose();
        }
    }, [changedPathname]);

    const onClose = () => {
        dispatch(modalActions.close_modal());
    };

    return (
        <div className="fixed z-10">
            <div
                className={`h-screen w-full 
                     fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-9 overflow-hidden`}
                onClick={onClose}
            />
            <div
                className={
                    'fixed w-full  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 bg-white rounded-xl shadow-lg sm:py-[60px] py-6 sm:max-w-[520px] max-w-[380px] w-full flex flex-col items-center justify-center'
                }
                {...props}
            >
                <div className="overflow-y-auto overflow-x-hidden sm:px-[60px] px-6 w-full">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AppModal;
