import { modalActions } from '../../../../actions/modal.actions';
import { ConfirmPassword } from '../../../../store/project/project.selectors';
import AppModal from '../../../AppModal';
import AppForm from '../../../AppForm';
import AppButton from '../../../AppButton';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import PasswordInput from '../../../AppForm/PasswordInput';
import { personalSettingsActions } from '../../../../actions/pesonalSettings.actions';
import { useForm } from 'react-hook-form';

const PasswordChangeConfirmModal = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: 'onChange' });
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const token = searchParams.get('token');
    const { data, type } = ConfirmPassword();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (data && type && type === 'danger') {
            setErrorMessage(data);
        }
    }, [data, type]);
    const onSubmit = userData => {
        return dispatch(
            personalSettingsActions.confirmNewPassword({
                ...userData,
                email: email,
                token: token,
            }),
        );
    };

    const handleChange = () => {
        if (data && type && type === 'danger') {
            setErrorMessage('');
        }
    };

    const handleCancel = () => {
        return dispatch(modalActions.close_modal());
    };

    return (
        <AppModal>
            <AppForm
                className="max-w-[400px] w-full"
                onSubmit={handleSubmit(onSubmit)}
                text="Thanks! Now you can create a new password"
                title="Password change"
            >
                <div className="flex flex-col gap-3">
                    <PasswordInput
                        id="password"
                        label="New password"
                        register={register}
                    />
                    <PasswordInput
                        id="password_confirmation"
                        label="Repeat password"
                        optionObject={{
                            onChange: handleChange,
                        }}
                        register={register}
                    />
                    {errorMessage && (
                        <p className="text-sm text-[#C90000] dark:text-red-500 font-[500]">
                            {errorMessage}
                        </p>
                    )}
                </div>
                <div className="flex flex-wrap items-center md:justify-self-auto justify-center gap-2.5">
                    <AppButton
                        className="btn-primary-height sm:w-fit w-full"
                        type=""
                        variant="primary"
                    >
                        Save
                    </AppButton>
                    <AppButton
                        className="sm:hidden btn-primary-height w-full"
                        onClick={handleCancel}
                        type=""
                        variant="btn-secondary"
                    >
                        Cancel
                    </AppButton>
                </div>
            </AppForm>
        </AppModal>
    );
};

export default PasswordChangeConfirmModal;
