import { currencyActions } from '../../../../../manager/actions/currency.actions';
import { GetCurrencies } from '../../../../../manager/manager.selectors';
import { NewUser } from '../../../../store/project/project.selectors';
import { AppInputFromArray } from '../../../AppInputFromArray';
import AppModal from '../../../AppModal';
import AppForm from '../../../AppForm';
import AppInput from '../../../AppInput';
import AppButton from '../../../AppButton';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useController, useFieldArray, useForm } from 'react-hook-form';
import { DeleteIcon } from '../../../../assets/icons/SettingsSvg';
import { userActions } from '../../../../actions/user.actions';
import AppSelect from '../../../AppSelect';

const AddNewUserModal = () => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            investments: [{ amount: '', created_at: '' }],
        },
        mode: 'onChange' || '',
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'investments',
    });
    const dispatch = useDispatch();
    const { user, type } = NewUser();
    const { currencies, loading } = GetCurrencies();
    const [errorMessage, setErrorMessage] = useState('');
    const [currencyList, setCurrencyList] = useState([]);
    const { field } = useController({
        name: 'currency_id',
        rules: { required: 'This field is required.' },
        control,
    });
    const { value: currencyValue, onChange: currencyOnChange } = field;
    const [errorLength, setErrorLength] = useState(false);

    useEffect(() => {
        if (user && type === 'danger') {
            return () => {
                dispatch(userActions.clearUsers());
            };
        }
    }, [user]);

    useEffect(() => {
        if (user && type === 'danger') {
            setErrorMessage(user);
        }
    }, [user]);
    const onSubmit = userData => {
        return dispatch(userActions.addUser(userData));
    };

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setErrorLength(true);
        }
    }, [errors]);

    useEffect(() => {
        dispatch(currencyActions.getCurrencies());
    }, []);

    useEffect(() => {
        if (!loading && currencies?.length > 0) {
            const newList = currencies.map(item => {
                return { value: item.id, label: item.type };
            });
            setCurrencyList(newList);
        }
    }, [currencies]);

    const handleAdd = e => {
        e.preventDefault();
        append({ sum: '', created_at: '' });
        setErrorMessage('');
    };
    const handleChange = () => {
        setErrorMessage('');
    };

    const handleSelect = selected => {
        currencyOnChange(selected);
        setErrorMessage('');
    };

    const preventMinus = e => {
        if (
            e.code === 'Minus' ||
            e.code === 'NumpadSubtract' ||
            e.code === 'KeyE'
        ) {
            e.preventDefault();
        }
    };

    return (
        <AppModal>
            <AppForm
                className="max-w-[400px] w-full max-h-[calc(100vh_-_210px)] app-modal"
                onSubmit={handleSubmit(onSubmit)}
                text="Information of new “adiaman participant”"
                title="New user"
            >
                <div className="flex flex-col gap-3">
                    <AppInput
                        error={errors}
                        id="name"
                        label="Name"
                        optionObject={{
                            onChange: handleChange,
                        }}
                        register={register}
                        type="text"
                    />
                    <AppInput
                        error={errors}
                        id="surname"
                        label="Surname"
                        optionObject={{
                            onChange: handleChange,
                        }}
                        register={register}
                        type="text"
                    />
                    <AppInput
                        error={errors}
                        id="email"
                        label="Email"
                        optionObject={{
                            pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Incorrect email format',
                            },
                            onChange: handleChange,
                        }}
                        register={register}
                        type="email"
                    />
                    <AppInput
                        error={errors}
                        id="phone"
                        label="Phone number"
                        optionObject={{
                            onChange: handleChange,
                        }}
                        register={register}
                        type="phone"
                    />
                    {/*<AppInput*/}
                    {/*    error={errors}*/}
                    {/*    id="holder_structure"*/}
                    {/*    label="Holdings percent"*/}
                    {/*    optionObject={{*/}
                    {/*        onChange: handleChange,*/}
                    {/*    }}*/}
                    {/*    register={register}*/}
                    {/*    type="text"*/}
                    {/*/>*/}
                    <AppSelect
                        errorMessage={
                            errorLength && errors?.currency_id?.message
                        }
                        id="currency_id"
                        list={currencyList}
                        onChange={handleSelect}
                        options={currencyList}
                        placeholder="Home currency"
                        selectedValue={currencyValue}
                    />
                </div>
                <p className="font-normal text-[15px] text-center text-[#0B1F35] font-roboto">
                    Please enter information of user`s investment in usd
                </p>
                <div className="flex flex-col gap-[12px]">
                    {fields.map((item, index) => {
                        return (
                            <>
                                {index > 0 && (
                                    <span className="flex justify-end">
                                        <button onClick={() => remove(index)}>
                                            <DeleteIcon />
                                        </button>
                                    </span>
                                )}
                                <AppInputFromArray
                                    errorMessage={
                                        errorLength &&
                                        errors?.investments?.[`${index}`]
                                            ?.amount?.message
                                    }
                                    id={`investments.${index}.amount`}
                                    label="Sum of investment"
                                    min="0"
                                    onKeyPress={preventMinus}
                                    optionObject={{
                                        onChange: handleChange,
                                    }}
                                    register={register}
                                    step="any"
                                    type="number"
                                />
                                <AppInputFromArray
                                    errorMessage={
                                        errorLength &&
                                        errors?.investments?.[`${index}`]
                                            ?.created_at?.message
                                    }
                                    handleChange={handleChange}
                                    id={`investments.${index}.created_at`}
                                    label="Date"
                                    register={register}
                                    type="date"
                                />
                            </>
                        );
                    })}
                    {errorMessage && (
                        <p className="text-sm text-[#C90000] dark:text-red-500 font-[500]">
                            {errorMessage}
                        </p>
                    )}
                    <p className="font-bold text-[15px] text-black font-roboto">
                        <button onClick={handleAdd}>Add another one</button>
                    </p>
                </div>
                <AppButton
                    className="max-w-[178px] btn-primary-height m-auto"
                    type=""
                    variant="primary"
                >
                    Create user
                </AppButton>
            </AppForm>
        </AppModal>
    );
};

export default AddNewUserModal;
