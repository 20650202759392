import { userService } from '../services';
import {
    CHANGE_CLEAR,
    CHANGE_PERSONAL_DATA_FAILED,
    CHANGE_PERSONAL_DATA_REQUEST,
    CHANGE_PERSONAL_DATA_SUCCESS,
    CHANGE_USER_EMAIL_FAILED,
    CHANGE_USER_EMAIL_REQUEST,
    CHANGE_USER_EMAIL_SUCCESS,
    CHANGE_USER_PASSWORD_CLEAR,
    CHANGE_USER_PASSWORD_FAILED,
    CHANGE_USER_PASSWORD_REQUEST,
    CHANGE_USER_PASSWORD_SUCCESS,
    CONFIRM_NEW_PASSWORD_FAILED,
    CONFIRM_NEW_PASSWORD_REQUEST,
    CONFIRM_NEW_PASSWORD_SUCCESS,
    VERIFY_EMAIL_FAILURE,
    VERIFY_EMAIL_REQUEST,
    VERIFY_EMAIL_SUCCESS,
} from './actionTypes';
import { modalActions } from './modal.actions';
import { userActions } from './user.actions';

export const personalSettingsActions = {
    changeEmail,
    changePassword,
    changePersonalData,
    confirmNewPassword,
    clearChange,
    verifyEmail,
    changePasswordClear,
};

function changeEmail(user) {
    const endpoint = '/users/update-email';
    return dispatch => {
        dispatch(request());
        userService.PostInfo(endpoint, user).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'checkEmailModal',
                        props: {
                            user: user,
                            type: 'email',
                        },
                    }),
                );
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: CHANGE_USER_EMAIL_REQUEST };
    }
    function success(data) {
        return { type: CHANGE_USER_EMAIL_SUCCESS, data };
    }
    function fail(error) {
        return { type: CHANGE_USER_EMAIL_FAILED, error };
    }
}

function changePassword(user) {
    const endpoint = '/users/update-password';
    return dispatch => {
        dispatch(request());
        userService.PostInfo(endpoint, user).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'checkEmailModal',
                        props: {
                            user: user,
                            type: 'password',
                        },
                    }),
                );
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: CHANGE_USER_PASSWORD_REQUEST };
    }
    function success(data) {
        return { type: CHANGE_USER_PASSWORD_SUCCESS, data };
    }
    function fail(error) {
        return { type: CHANGE_USER_PASSWORD_FAILED, error };
    }
}
function changePasswordClear() {
    return { type: CHANGE_USER_PASSWORD_CLEAR };
}

function verifyEmail(url) {
    return dispatch => {
        dispatch(requestLoader());
        userService.VerifyEmail(`${url}`).then(data => {
            if (data.status === 200) {
                dispatch(successLoader(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: { message: 'Your email has been changed' },
                    }),
                );
            } else {
                dispatch(failureLoader(data.data.message));
            }
        });
    };

    function requestLoader() {
        return { type: VERIFY_EMAIL_REQUEST };
    }

    function successLoader(data) {
        return { type: VERIFY_EMAIL_SUCCESS, data };
    }

    function failureLoader(error) {
        return { type: VERIFY_EMAIL_FAILURE, error };
    }
}

function changePersonalData(user, userId) {
    const endpoint = `/users/${userId}`;
    return dispatch => {
        dispatch(request());
        userService.PatchInfo(endpoint, user).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data));
                dispatch(userActions.getUser(userId));
                dispatch(modalActions.close_modal());
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: CHANGE_PERSONAL_DATA_REQUEST };
    }
    function success(data) {
        return { type: CHANGE_PERSONAL_DATA_SUCCESS, data };
    }
    function fail(error) {
        return { type: CHANGE_PERSONAL_DATA_FAILED, error };
    }
}

function confirmNewPassword(user) {
    const endpoint = '/users/confirm-new-password';
    return dispatch => {
        dispatch(request());
        userService.PostInfo(endpoint, user).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: { message: 'Your password has been changed' },
                    }),
                );
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: CONFIRM_NEW_PASSWORD_REQUEST };
    }
    function success(data) {
        return { type: CONFIRM_NEW_PASSWORD_SUCCESS, data };
    }
    function fail(error) {
        return { type: CONFIRM_NEW_PASSWORD_FAILED, error };
    }
}
function clearChange() {
    return { type: CHANGE_CLEAR };
}
