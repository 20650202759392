import { currencyActions } from '../../investor/actions/currency.actions';
import {
    CURRENCIES,
    CURRENCY_COEFFICIENT,
} from '../components/CurrencyComponent/currencyConstants';
import { NAME_BY_ROLES, ROLES } from '../routes/costants';
import {
    LOG_IN_CLEAR,
    LOG_IN_FAILED,
    LOG_IN_REQUEST,
    LOG_IN_SUCCESS,
    PASSWORD_RECOVERY_FAILURE,
    PASSWORD_RECOVERY_REQUEST,
    PASSWORD_RECOVERY_SUCCESS,
    RESET_PASSWORD_CLEAR,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    SIGN_OUT_SUCCESS,
} from './actionTypes';
import { userService } from '../services';
import { modalActions } from './modal.actions';
import { createSearchParams } from 'react-router-dom';
import { storedDataActions } from './storedData.actions';

export const authActions = {
    login,
    forgotPassword,
    resetPassword,
    logout,
    clearLogin,
    clearResetPassword,
    resendEmail,
};

function login(user, navigate) {
    const endpoint = '/sign-in';
    return dispatch => {
        dispatch(request());
        userService.Login(endpoint, user).then(data => {
            if (data.status === 200) {
                const token = data?.data?.results?.access_token;
                const role = data?.data?.results?.user?.role;
                const currency = data?.data?.results?.user?.currency;
                const from =
                    location.state?.from?.pathname || `/${NAME_BY_ROLES[role]}`;
                dispatch(success(data.data?.results?.user, token, role));
                dispatch(storedDataActions.getStoredUser());

                navigate(from, { replace: true });
                if (role === ROLES.INVESTOR) {
                    dispatch(
                        currencyActions.setCurrencyData({
                            currency: currency?.type,
                            coefficient: currency?.coefficient,
                        }),
                    );
                } else if (role === ROLES.MANAGER) {
                    dispatch(
                        currencyActions.setCurrencyData({
                            currency: CURRENCIES.USD,
                            coefficient: CURRENCY_COEFFICIENT.DEFAULT,
                        }),
                    );
                }
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: LOG_IN_REQUEST };
    }
    function success(data, token, role) {
        return { type: LOG_IN_SUCCESS, data, token, role };
    }
    function fail(error) {
        return { type: LOG_IN_FAILED, error };
    }
}

function forgotPassword(user, navigate) {
    return dispatch => {
        dispatch(requestLoader());
        userService.PostInfo('/password/recovery', user).then(data => {
            if (data.status === 200) {
                navigate({
                    pathname: '/email-verify',
                    search: `?${encodeURIComponent(
                        createSearchParams(user).toString(),
                    )}`,
                });
            } else {
                dispatch(failureLoader(data?.data?.message));
            }
        });
    };

    function requestLoader() {
        return { type: PASSWORD_RECOVERY_REQUEST };
    }

    function successLoader(data) {
        return { type: PASSWORD_RECOVERY_SUCCESS, data };
    }

    function failureLoader(error) {
        return { type: PASSWORD_RECOVERY_FAILURE, error };
    }
}

function resendEmail(user) {
    return dispatch => {
        dispatch(requestLoader());
        userService.PostInfo('/password/recovery', user);
    };
    function requestLoader() {
        return { type: PASSWORD_RECOVERY_REQUEST };
    }
}

function resetPassword(user) {
    return dispatch => {
        dispatch(requestLoader());
        userService.PostInfo('/password/reset', user).then(data => {
            if (data.status === 200) {
                dispatch(modalActions.open_modal({ name: 'successReset' }));
            } else {
                dispatch(failureLoader(data?.data?.message));
            }
        });
    };

    function requestLoader() {
        return { type: RESET_PASSWORD_REQUEST };
    }

    function successLoader(data) {
        return { type: RESET_PASSWORD_SUCCESS, data };
    }

    function failureLoader(error) {
        return { type: RESET_PASSWORD_FAILURE, error };
    }
}

function logout(navigate) {
    const endpoint = '/sign-out';
    return dispatch => {
        dispatch(requestLoader());
        userService.LogOut(endpoint).then(data => {
            if (data.status === 200) {
                localStorage.removeItem('userInfo');
                dispatch(clearLogin());
                dispatch(modalActions.close_modal());
                navigate('/login');
                dispatch(storedDataActions.getStoredUser());
            } else {
                dispatch(failureLoader(data.data));
            }
        });
    };

    function requestLoader() {
        return { type: 'SIGN_OUT_REQUEST' };
    }

    function successLoader() {
        return { type: SIGN_OUT_SUCCESS };
    }

    function failureLoader() {
        return { type: 'SIGN_OUT_FAILURE' };
    }
}

function clearLogin() {
    return { type: LOG_IN_CLEAR };
}

function clearResetPassword() {
    return { type: RESET_PASSWORD_CLEAR };
}
