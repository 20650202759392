const AppForm = ({ title, text, children, className, onSubmit, ...rest }) => {
    return (
        <div
            className={`${className} flex flex-col sm:gap-[32px] gap-5 w-full`}
        >
            {title && (
                <h1 className="font-normal leading-snug tracking-tight text-black sm:text-[35px] text-[28px] dark:text-white text-center leading-[30px] ">
                    {title}
                </h1>
            )}
            {text && (
                <p className="sm:text-[15px] text-base leading-[18px] font-normal text-[#0B1F35] text-center font-roboto">
                    {text}
                </p>
            )}

            <form
                className="flex flex-col sm:gap-[32px] gap-5"
                noValidate
                onSubmit={onSubmit}
                {...rest}
            >
                {children}
            </form>
        </div>
    );
};
export default AppForm;
