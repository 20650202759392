import {
    DELETE_APPLICATION_FAILED,
    DELETE_APPLICATION_REQUEST,
    DELETE_APPLICATION_SUCCESS,
    GET_APPLICATION_REF_SUCCESS,
    UPDATE_APPLICATION_FAILED,
    UPDATE_APPLICATION_REQUEST,
    UPDATE_APPLICATION_SUCCESS,
} from '../actions/actionTypes';

const initialApplication = {
    lotsData: '',
    loading: false,
    type: '',
};

const initialUpdateIApplication = {
    loading: false,
    data: '',
    type: '',
};

const initialApplicationRef = {
    applicationRef: false,
};

export function deleteApplication(state = initialApplication, action) {
    switch (action.type) {
        case DELETE_APPLICATION_SUCCESS:
            return {
                lotsData: action.data,
                loading: false,
                type: 'success',
            };
        case DELETE_APPLICATION_FAILED:
            return {
                lotsData: action.error,
                loading: false,
                type: 'danger',
            };
        case DELETE_APPLICATION_REQUEST:
            return {
                lotsData: '',
                loading: true,
                type: '',
            };

        default:
            return state;
    }
}

export function updateApplication(state = initialUpdateIApplication, action) {
    switch (action.type) {
        case UPDATE_APPLICATION_SUCCESS:
            return {
                data: action.data,
                loading: false,
                type: 'success',
            };
        case UPDATE_APPLICATION_FAILED:
            return {
                loading: false,
                data: action.error,
                type: 'danger',
            };
        case UPDATE_APPLICATION_REQUEST:
            return {
                loading: true,
                data: '',
                type: '',
            };

        default:
            return state;
    }
}

export function getApplicationRef(state = initialApplicationRef, action) {
    switch (action.type) {
        case GET_APPLICATION_REF_SUCCESS:
            return {
                applicationRef: action.data,
            };
        default:
            return state;
    }
}
