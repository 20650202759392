import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { currencyActions } from '../../../../investor/actions/currency.actions';
import { storedDataActions } from '../../../actions/storedData.actions';

const PublicRoutes = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        localStorage.removeItem('userInfo');
    }, []);

    useEffect(() => {
        dispatch(storedDataActions.getStoredUser());
        dispatch(currencyActions.CurrencyDataClear());
    }, []);

    return <Outlet />;
};

export default PublicRoutes;
