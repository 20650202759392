import { useNavigate } from 'react-router-dom';

export default function ({ className = '', fontSize = 'text-4xl', to }) {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(to);
    };
    return (
        <button
            className={`self-center whitespace-nowrap ${className} text-gray-700 font-extrabold text-ho-600 dark:text-white ${fontSize}`}
            onClick={handleClick}
        >
            <span>
                <svg
                    fill="none"
                    height="36"
                    viewBox="0 0 55 36"
                    width="55"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M17.6758 6.7627L8.49609 35.5469H0L13.1592 0H18.5303L17.6758 6.7627ZM25.293 35.5469L16.0889 6.7627L15.1367 0H20.5811L33.7891 35.5469H25.293ZM24.9023 22.3145V28.4668H6.37207V22.3145H24.9023Z"
                        fill="black"
                    />
                    <path
                        d="M47 35.5469L33.5449 0H41.4551L55 35.5469H47Z"
                        fill="black"
                    />
                </svg>
            </span>
        </button>
    );
}
