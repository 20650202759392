import { objectToQuery } from '../helpers/queryHelper';
import { userById } from '../helpers/userHelper';
import { userService } from '../services';
import {
    ADD_USER_CLEAR,
    ADD_USER_FAILURE,
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    DELETE_USER_FAILURE,
    DELETE_USER_FORBIDDEN,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    GET_ALL_INSTALLMENTS_FAIL,
    GET_ALL_INSTALLMENTS_REQUEST,
    GET_ALL_INSTALLMENTS_SUCCESS,
    GET_ALL_WITHDRAWAL_FAIL,
    GET_ALL_WITHDRAWAL_REQUEST,
    GET_ALL_WITHDRAWAL_SUCCESS,
    GET_CREATED_USER_CLEAR,
    GET_CREATED_USER_FAILED,
    GET_CREATED_USER_REQUEST,
    GET_CREATED_USER_SUCCESS,
    GET_INSTALLMENT_CLEAR,
    GET_INVESTMENTS_FAILED,
    GET_INVESTMENTS_REQUEST,
    GET_INVESTMENTS_SUCCESS,
    GET_INVESTMENTS_WITHDRAWALS_CLEAR,
    GET_INVESTMENTS_WITHDRAWALS_FAILED,
    GET_INVESTMENTS_WITHDRAWALS_REQUEST,
    GET_INVESTMENTS_WITHDRAWALS_SUCCESS,
    GET_USER_FAILED,
    GET_USER_HOLDING_CLEAR,
    GET_USER_HOLDING_FAILED,
    GET_USER_HOLDING_REQUEST,
    GET_USER_HOLDING_SUCCESS,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USERS_FAILED,
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    UPDATE_USER_PROFILE_FAILED,
    UPDATE_USER_PROFILE_REQUEST,
    UPDATE_USER_PROFILE_SUCCESS,
} from './actionTypes';
import { modalActions } from './modal.actions';

export const userActions = {
    getUser,
    addUser,
    getUsers,
    clearUsers,
    updateProfile,
    resendInvitation,
    deleteUser,
    getCreatedUser,
    instalmentClear,
    withdrawalsClear,
    getInstallment,
    getWithdrawal,
    getAllInstallment,
    getAllWithdrawal,
    getUserHolding,
    getUserHoldingClear,
    userClear,
};

function getUser(id) {
    const endpoint = userById(id);
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results.user));
            } else {
                dispatch(fail(data.data.message));
                localStorage.clear();
            }
        });
    };
    function request() {
        return { type: GET_USER_REQUEST };
    }
    function success(user) {
        return { type: GET_USER_SUCCESS, user };
    }
    function fail(error) {
        return { type: GET_USER_FAILED, error };
    }
}

function getCreatedUser(id) {
    const endpoint = userById(id);
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results.user));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };

    function request() {
        return { type: GET_CREATED_USER_REQUEST };
    }
    function success(user) {
        return { type: GET_CREATED_USER_SUCCESS, user };
    }
    function fail(error) {
        return { type: GET_CREATED_USER_FAILED, error };
    }
}

function addUser(user) {
    return dispatch => {
        dispatch(requestLoader());
        userService.PostInfo('/users', user).then(data => {
            if (data.status === 200) {
                successLoader(data?.data?.message);
                dispatch(userActions.getUsers());
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: {
                            message:
                                'We send a welcome letter to our new “adiaman participant”',
                        },
                    }),
                );
            } else {
                dispatch(failureLoader(data?.data?.message));
            }
        });
    };

    function requestLoader() {
        return { type: ADD_USER_REQUEST };
    }

    function successLoader(data) {
        return { type: ADD_USER_SUCCESS, data };
    }

    function failureLoader(error) {
        return { type: ADD_USER_FAILURE, error };
    }
}

function getUsers() {
    const endpoint = '/users';
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data?.results?.users));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_USERS_REQUEST };
    }
    function success(user) {
        return { type: GET_USERS_SUCCESS, user };
    }
    function fail(error) {
        return { type: GET_USERS_FAILED, error };
    }
}

function clearUsers() {
    return { type: ADD_USER_CLEAR };
}

function getInstallment(dataObject) {
    const paramsObject = {
        ...dataObject,
        modal: 0,
        page: 0,
        type: 'Installment',
    };
    const queryParams = objectToQuery(paramsObject);
    const endpoint = `/users/investments?${queryParams}`;
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_INVESTMENTS_REQUEST };
    }
    function success(data) {
        return { type: GET_INVESTMENTS_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_INVESTMENTS_FAILED, error };
    }
}
function getWithdrawal(dataObject) {
    const paramsObject = {
        ...dataObject,
        modal: 0,
        page: 0,
        type: 'Withdrawal',
    };
    const queryParams = objectToQuery(paramsObject);
    const endpoint = `/users/investments?${queryParams}`;
    return dispatch => {
        dispatch(requestWithdrawals());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(successWithdrawals(data.data?.results));
            } else {
                dispatch(failWithdrawals(data.data.message));
            }
        });
    };
    function requestWithdrawals() {
        return { type: GET_INVESTMENTS_WITHDRAWALS_REQUEST };
    }
    function successWithdrawals(data) {
        return { type: GET_INVESTMENTS_WITHDRAWALS_SUCCESS, data };
    }
    function failWithdrawals(error) {
        return { type: GET_INVESTMENTS_WITHDRAWALS_FAILED, error };
    }
}
function getAllInstallment(dataObject) {
    const paramsObject = { ...dataObject, modal: 1, type: 'Installment' };
    const queryParams = objectToQuery(paramsObject);
    const endpoint = `/users/investments?${queryParams}`;
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_ALL_INSTALLMENTS_REQUEST };
    }
    function success(data) {
        return { type: GET_ALL_INSTALLMENTS_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_ALL_INSTALLMENTS_FAIL, error };
    }
}
function getAllWithdrawal(dataObject) {
    const paramsObject = { ...dataObject, modal: 1, type: 'Withdrawal' };
    const queryParams = objectToQuery(paramsObject);
    const endpoint = `/users/investments?${queryParams}`;
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_ALL_WITHDRAWAL_REQUEST };
    }
    function success(data) {
        return { type: GET_ALL_WITHDRAWAL_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_ALL_WITHDRAWAL_FAIL, error };
    }
}

function instalmentClear() {
    return { type: GET_INSTALLMENT_CLEAR };
}
function withdrawalsClear() {
    return { type: GET_INVESTMENTS_WITHDRAWALS_CLEAR };
}

function updateProfile(user, userId) {
    const endpoint = `/users/${userId}`;
    return dispatch => {
        dispatch(request());
        userService.PatchInfo(endpoint, user).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data));
                dispatch(userActions.getCreatedUser(userId));
                dispatch(modalActions.close_modal());
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: UPDATE_USER_PROFILE_REQUEST };
    }
    function success(data) {
        return { type: UPDATE_USER_PROFILE_SUCCESS, data };
    }
    function fail(error) {
        return { type: UPDATE_USER_PROFILE_FAILED, error };
    }
}

function resendInvitation(userId) {
    return dispatch => {
        dispatch(requestLoader());
        userService
            .PostInfo(`/users/send-email-verification/${userId}`)
            .then(data => {
                if (data.status === 200) {
                    successLoader(data?.data?.message);
                    dispatch(userActions.getCreatedUser(userId));
                    dispatch(modalActions.close_modal());
                    dispatch(
                        modalActions.open_modal({
                            name: 'authSuccess',
                            props: {
                                message: 'We resend an invitation letter',
                                button: true,
                            },
                        }),
                    );
                } else {
                    dispatch(failureLoader(data?.data?.message));
                }
            });
    };

    function requestLoader() {
        return { type: ADD_USER_REQUEST };
    }

    function successLoader(data) {
        return { type: ADD_USER_SUCCESS, data };
    }

    function failureLoader(error) {
        return { type: ADD_USER_FAILURE, error };
    }
}

function deleteUser(userId) {
    return dispatch => {
        dispatch(requestLoader());
        userService.DeleteInfo(`/users/${userId}`).then(data => {
            if (data.status === 200) {
                successLoader(data?.data?.message);
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: {
                            message: 'The user has been deleted',
                            button: true,
                        },
                    }),
                );
            } else if (data.status === 403) {
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'forbidden',
                        props: {
                            text: 'You cannot delete this user, because he still has his own current holdings. You should sell his part before delete this profile ',
                        },
                    }),
                );
            } else {
                dispatch(failureLoader(data?.data?.message));
            }
        });
    };

    function requestLoader() {
        return { type: DELETE_USER_REQUEST };
    }

    function successLoader(user) {
        return { type: DELETE_USER_SUCCESS, user };
    }

    function failureLoader(error) {
        return { type: DELETE_USER_FAILURE, error };
    }
    function forbiddenLoader(error) {
        return { type: DELETE_USER_FORBIDDEN, error };
    }
}

function getUserHolding(id) {
    const endpoint = `/users/${id}/holding`;
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results.holding));
            } else {
                dispatch(fail(data.data.message));
                localStorage.clear();
            }
        });
    };
    function request() {
        return { type: GET_USER_HOLDING_REQUEST };
    }
    function success(data) {
        return { type: GET_USER_HOLDING_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_USER_HOLDING_FAILED, error };
    }
}

function getUserHoldingClear() {
    return { type: GET_USER_HOLDING_CLEAR };
}

function userClear() {
    return { type: GET_CREATED_USER_CLEAR };
}
