import {
    GET_CURRENCIES_FAILED,
    GET_CURRENCIES_REQUEST,
    GET_CURRENCIES_SUCCESS,
} from '../actions/actionTypes';

const initialCurrencies = {
    loading: false,
    currencies: [],
};

export function getCurrencies(state = initialCurrencies, action) {
    switch (action.type) {
        case GET_CURRENCIES_SUCCESS:
            return {
                loading: false,
                currencies: action.data,
            };
        case GET_CURRENCIES_FAILED:
            return {
                loading: false,
                currencies: action.error,
            };
        case GET_CURRENCIES_REQUEST:
            return {
                loading: true,
                currencies: [],
            };

        default:
            return state;
    }
}
