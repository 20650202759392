import { modalActions } from '../../../../actions/modal.actions';
import { ChangePassword } from '../../../../store/project/project.selectors';
import AppModal from '../../../AppModal';
import AppForm from '../../../AppForm';
import AppButton from '../../../AppButton';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import PasswordInput from '../../../AppForm/PasswordInput';
import { useForm } from 'react-hook-form';
import { personalSettingsActions } from '../../../../actions/pesonalSettings.actions';

const PasswordChangeModal = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({ mode: 'onChange' });
    const dispatch = useDispatch();
    const { data, type } = ChangePassword();

    useEffect(() => {
        dispatch(personalSettingsActions.changePasswordClear());
    }, []);

    useEffect(() => {
        if (data && type === 'danger') {
            setError('password', { type: 'custom', message: `${data}` });
        }
    }, [data, type]);

    const onSubmit = userData => {
        return dispatch(personalSettingsActions.changePassword(userData));
    };

    const handleCancel = () => {
        return dispatch(modalActions.close_modal());
    };

    return (
        <AppModal>
            <AppForm
                className="max-w-[400px] w-full"
                onSubmit={handleSubmit(onSubmit)}
                text="Please, enter your current password and then we send a confirmation letter to your email"
                title="Password change"
            >
                <div className="flex flex-col gap-3">
                    <PasswordInput
                        error={errors}
                        id="password"
                        label="Current password"
                        register={register}
                    />
                </div>
                <div className="flex flex-wrap items-center md:justify-self-auto justify-center gap-2.5">
                    <AppButton
                        className="btn-primary-height sm:w-fit w-full"
                        type=""
                        variant="primary"
                    >
                        Send confirmation
                    </AppButton>
                    <AppButton
                        className="sm:hidden btn-primary-height w-full"
                        onClick={handleCancel}
                        type=""
                        variant="btn-secondary"
                    >
                        Cancel
                    </AppButton>
                </div>
            </AppForm>
        </AppModal>
    );
};

export default PasswordChangeModal;
