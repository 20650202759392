//auth
export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILED = 'LOG_IN_FAILED';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const LOG_IN_CLEAR = 'LOG_IN_CLEAR';
export const PASSWORD_RECOVERY_REQUEST = 'PASSWORD_RECOVERY_REQUEST';
export const PASSWORD_RECOVERY_SUCCESS = 'PASSWORD_RECOVERY_SUCCESS';
export const PASSWORD_RECOVERY_FAILURE = 'PASSWORD_RECOVERY_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_CLEAR = 'RESET_PASSWORD_CLEAR';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';
export const GET_USER_CLEAR = 'GET_USER_CLEAR';
export const CHANGE_USER_EMAIL_REQUEST = 'CHANGE_USER_EMAIL_REQUEST';
export const CHANGE_USER_EMAIL_SUCCESS = 'CHANGE_USER_EMAIL_SUCCESS';
export const CHANGE_USER_EMAIL_FAILED = 'CHANGE_USER_EMAIL_FAILED';
export const CHANGE_CLEAR = 'CHANGE_CLEAR';
export const CHANGE_USER_PASSWORD_REQUEST = 'CHANGE_USER_PASSWORD_REQUEST';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAILED = 'CHANGE_USER_PASSWORD_FAILED';
export const CHANGE_USER_PASSWORD_CLEAR = 'CHANGE_USER_PASSWORD_CLEAR';
export const CHANGE_PERSONAL_DATA_REQUEST = 'CHANGE_PERSONAL_DATA_REQUEST';
export const CHANGE_PERSONAL_DATA_SUCCESS = 'CHANGE_PERSONAL_DATA_SUCCESS';
export const CHANGE_PERSONAL_DATA_FAILED = 'CHANGE_PERSONAL_DATA_FAILED';
export const CONFIRM_NEW_PASSWORD_REQUEST = 'CONFIRM_NEW_PASSWORD_REQUEST';
export const CONFIRM_NEW_PASSWORD_SUCCESS = 'CONFIRM_NEW_PASSWORD_SUCCESS';
export const CONFIRM_NEW_PASSWORD_FAILED = 'CONFIRM_NEW_PASSWORD_FAILED';
export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';

//user
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';
export const ADD_USER_CLEAR = 'ADD_USER_CLEAR';
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';
export const GET_INVESTMENTS_REQUEST = 'GET_INVESTMENTS_REQUEST';
export const GET_INVESTMENTS_SUCCESS = 'GET_INVESTMENTS_SUCCESS';
export const GET_INVESTMENTS_FAILED = 'GET_INVESTMENTS_FAILED';
export const GET_INSTALLMENT_CLEAR = 'GET_INSTALLMENT_CLEAR';
export const GET_INVESTMENTS_WITHDRAWALS_CLEAR =
    'GET_INVESTMENTS_WITHDRAWALS_CLEAR';
export const GET_INVESTMENTS_WITHDRAWALS_REQUEST =
    'GET_INVESTMENTS_WITHDRAWALS_REQUEST';
export const GET_INVESTMENTS_WITHDRAWALS_SUCCESS =
    'GET_INVESTMENTS_WITHDRAWALS_SUCCESS';
export const GET_INVESTMENTS_WITHDRAWALS_FAILED =
    'GET_INVESTMENTS_WITHDRAWALS_FAILED';
export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILED = 'UPDATE_USER_PROFILE_FAILED';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FORBIDDEN = 'DELETE_USER_FORBIDDEN';
export const GET_CREATED_USER_REQUEST = 'GET_CREATED_USER_REQUEST';
export const GET_CREATED_USER_SUCCESS = 'GET_CREATED_USER_SUCCESS';
export const GET_CREATED_USER_FAILED = 'GET_CREATED_USER_FAILED';
export const GET_CREATED_USER_CLEAR = 'GET_CREATED_USER_CLEAR';
export const GET_ALL_INSTALLMENTS_REQUEST = 'GET_ALL_INSTALLMENTS_REQUEST';
export const GET_ALL_INSTALLMENTS_SUCCESS = 'GET_ALL_INSTALLMENTS_SUCCESS';
export const GET_ALL_INSTALLMENTS_FAIL = 'GET_ALL_INSTALLMENTS_FAIL';
export const GET_ALL_WITHDRAWAL_REQUEST = 'GET_ALL_WITHDRAWAL_REQUEST';
export const GET_ALL_WITHDRAWAL_SUCCESS = 'GET_ALL_WITHDRAWAL_SUCCESS';
export const GET_ALL_WITHDRAWAL_FAIL = 'GET_ALL_WITHDRAWAL_FAIL';
//cash
export const CASH_OUT_REQUEST = 'CASH_OUT_REQUEST';
export const CASH_OUT_SUCCESS = 'CASH_OUT_SUCCESS';
export const CASH_OUT_FAILED = 'CASH_OUT_FAILED';
export const CLEAR_CASH_OUT = 'CLEAR_CASH_OUT';
//fund
export const GET_TOTAL_FUND_REQUEST = 'GET_TOTAL_FUND_REQUEST';
export const GET_TOTAL_FUND_SUCCESS = 'GET_TOTAL_FUND_SUCCESS';
export const GET_TOTAL_FUND_FAILED = 'GET_TOTAL_FUND_FAILED';
export const GET_INDUSTRIES_REQUEST = 'GET_INDUSTRIES_REQUEST';
export const GET_INDUSTRIES_SUCCESS = 'GET_INDUSTRIES_SUCCESS';
export const GET_INDUSTRIES_FAILED = 'GET_INDUSTRIES_FAILED';
export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED';
export const GET_STYLES_GROUP_REQUEST = 'GET_STYLES_GROUP_REQUEST';
export const GET_STYLES_GROUP_SUCCESS = 'GET_STYLES_GROUP_SUCCESS';
export const GET_STYLES_GROUP_FAILED = 'GET_STYLES_GROUP_FAILED';
//add investment
export const ADD_INVESTMENT_REQUEST = 'ADD_INVESTMENT_REQUEST';
export const ADD_INVESTMENT_SUCCESS = 'ADD_INVESTMENT_SUCCESS';
export const ADD_INVESTMENT_FAILED = 'ADD_INVESTMENT_FAILED';
//user holding
export const GET_USER_HOLDING_REQUEST = 'GET_USER_HOLDING_REQUEST';
export const GET_USER_HOLDING_SUCCESS = 'GET_USER_HOLDING_SUCCESS';
export const GET_USER_HOLDING_FAILED = 'GET_USER_HOLDING_FAILED';
export const GET_USER_HOLDING_CLEAR = 'GET_USER_HOLDING_CLEAR';
//storage
export const GET_STORED_DATA = 'GET_STORED_DATA';
//chart
export const GET_CHART_REQUEST = 'GET_CHART_REQUEST';
export const GET_CHART_SUCCESS = 'GET_CHART_SUCCESS';
export const GET_CHART_FAIL = 'GET_CHART_FAIL';
export const GET_CHART_CLEAR = 'GET_CHART_CLEAR';
//main
export const GET_TOP_HOLDINGS_REQUEST = 'GET_TOP_HOLDINGS_REQUEST';
export const GET_TOP_HOLDINGS_SUCCESS = 'GET_TOP_HOLDINGS_SUCCESS';
export const GET_TOP_HOLDINGS_FAILED = 'GET_TOP_HOLDINGS_FAILED';

//applications
export const GET_APPLICATIONS_REQUEST = 'GET_APPLICATIONS_REQUEST';
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_FAILED = 'GET_APPLICATIONS_FAILED';
