import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
export default function ({ navigationLink, name }) {
    const location = useLocation();
    const [url, setUrl] = useState(null);

    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    return (
        <Link
            className={` sm:text-[15px] text-base text-black font-roboto ${
                `${url}`?.includes(navigationLink)
                    ? 'font-bold'
                    : 'sm:font-normal font-bold'
            }`}
            to={navigationLink}
        >
            {name}
        </Link>
    );
}
