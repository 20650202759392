import { useEffect, useMemo, useState } from 'react';
import useTotalCash from '../../../../../hooks/useTotalCash';
import {
    CASH_OUT_OPERATION_TYPE,
    CASH_OUT_TYPE,
} from '../../../../../../manager/components/AppCash/cashConstants';
import AppCashOutForm from '../../../../AppCashOutForm';
import AppModal from '../../../../AppModal';
import AppInput from '../../../../AppInput';

const CashOutSelected = ({ investmentType = '' }) => {
    const [cashOutType, setCashOutType] = useState('');
    const [cash] = useTotalCash();

    const selectedType = useMemo(
        () =>
            CASH_OUT_OPERATION_TYPE.find(item => item.value == investmentType)
                .label,
        [CASH_OUT_OPERATION_TYPE],
    );

    useEffect(() => {
        setCashOutType(
            investmentType === '1'
                ? CASH_OUT_TYPE.Inside
                : CASH_OUT_TYPE.Outside,
        );
    }, [investmentType]);

    return (
        <AppModal>
            <AppCashOutForm
                selectedInvestmentType={cashOutType}
                title="Cash out"
            >
                <>
                    <AppInput
                        id="cashout_type"
                        label="Operation"
                        readOnly={true}
                        value={selectedType}
                    />
                    <AppInput
                        id="total_cash"
                        label="Total cash"
                        readOnly={true}
                        value={cash}
                    />

                    <p className="font-normal text-[15px] text-center text-[#0B1F35] font-roboto">
                        Please, choose investor and add transaction information
                    </p>
                </>
            </AppCashOutForm>
        </AppModal>
    );
};

export default CashOutSelected;
