import { useState } from 'react';
import AppInput from '../../AppInput';

function PasswordInput({ type = 'password', ...rest }) {
    const [show, setShow] = useState(false);
    const handleToggle = () => {
        setShow(!show);
    };
    return (
        <div className="relative">
            <AppInput type={`${show ? 'specialType' : 'password'}`} {...rest} />
            <div
                className="absolute inset-y-0 right-0 pr-[22.96px] py-[22.96px] flex items-center text-sm leading-5 h-14"
                onClick={handleToggle}
            >
                <div className={`${show ? 'hidden' : 'block'}`}>
                    <svg
                        fill="none"
                        height="20"
                        viewBox="0 0 24 24"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213Z"
                            stroke="#9A99A2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                        <path
                            d="M2.45801 12C3.73201 7.943 7.52301 5 12 5C16.478 5 20.268 7.943 21.542 12C20.268 16.057 16.478 19 12 19C7.52301 19 3.73201 16.057 2.45801 12Z"
                            stroke="#9A99A2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                    </svg>
                </div>
                <div className={`${show ? 'block' : 'hidden'}`}>
                    <svg
                        fill="none"
                        height="16"
                        viewBox="0 0 22 20"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12.875 16.825C12.2568 16.9419 11.6291 17.0005 11 17C6.52197 17 2.73197 14.057 1.45697 10C1.80024 8.9081 2.32896 7.88346 3.01997 6.971M8.87797 7.879C9.44063 7.31634 10.2038 7.00025 10.9995 7.00025C11.7952 7.00025 12.5583 7.31634 13.121 7.879C13.6836 8.44166 13.9997 9.20478 13.9997 10.0005C13.9997 10.7962 13.6836 11.5593 13.121 12.122M8.87797 7.879L13.121 12.122M8.87797 7.879L13.12 12.12M13.121 12.122L16.412 15.412M8.87997 7.88L5.58997 4.59M5.58997 4.59L1.99997 1M5.58997 4.59C7.20233 3.54957 9.08107 2.9974 11 3C15.478 3 19.268 5.943 20.543 10C19.839 12.2305 18.3773 14.1446 16.411 15.411M5.58997 4.59L16.411 15.411M16.411 15.411L20 19"
                            stroke="#9A99A2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default PasswordInput;
