export const CREATE_PASSWORD_REQUEST = 'CREATE_PASSWORD_REQUEST';
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS';
export const CREATE_PASSWORD_FAILURE = 'CREATE_PASSWORD_FAILURE';
export const CREATE_PASSWORD_CLEAR = 'CREATE_PASSWORD_CLEAR';

//applications
export const CREATE_APPLICATION_REQUEST = 'CREATE_APPLICATION_REQUEST';
export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
export const CREATE_APPLICATION_FAILED = 'CREATE_APPLICATION_FAILED';
export const CLEAR_APPLICATION = 'CLEAR_APPLICATION';

//investor currency
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_CURRENCY_CLEAR = 'SET_CURRENCY_CLEAR';
export const GET_CURRENCY = 'GET_CURRENCY';
