import { modalActions } from '../../../../actions/modal.actions';
import {
    ChangeEmail,
    UserData,
} from '../../../../store/project/project.selectors';
import AppModal from '../../../AppModal';
import AppForm from '../../../AppForm';
import AppInput from '../../../AppInput';
import AppButton from '../../../AppButton';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import PasswordInput from '../../../AppForm/PasswordInput';
import { useForm } from 'react-hook-form';
import { personalSettingsActions } from '../../../../actions/pesonalSettings.actions';

const EmailChangeModal = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ mode: 'onChange' });
    const dispatch = useDispatch();
    const { user } = UserData();
    const { data, type } = ChangeEmail();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (data && type && type === 'danger') {
            return () => {
                dispatch(personalSettingsActions.clearChange());
            };
        }
    }, [data, type]);

    useEffect(() => {
        if (data && type && type === 'danger') {
            setErrorMessage(data);
        }
    }, [data, type]);

    useEffect(() => {
        if (user) {
            setValue('current_email', `${user.email}`);
        }
    }, [user]);

    const onSubmit = userData => {
        return dispatch(personalSettingsActions.changeEmail(userData));
    };

    const handleChange = () => {
        if (data && type && type === 'danger') {
            setErrorMessage('');
        }
    };
    const handleCancel = () => {
        return dispatch(modalActions.close_modal());
    };

    return (
        <AppModal>
            <AppForm
                className="max-w-[400px] w-full"
                onSubmit={handleSubmit(onSubmit)}
                text="Please, enter your email and current password in the fields below"
                title="Email change"
            >
                <div className="flex flex-col gap-3">
                    <AppInput
                        id="current_email"
                        label="Enter email"
                        readOnly={true}
                        register={register}
                        type="email"
                    />
                    <PasswordInput
                        id="current_password"
                        label="Passcode"
                        optionObject={{
                            onChange: handleChange,
                        }}
                        register={register}
                    />
                </div>
                <p className="text-[15px] leading-[18px] font-normal text-[#0B1F35] text-center font-roboto">
                    Here enter your new email address and then we’ll send a
                    confirmation letter
                </p>
                <div className="flex flex-col gap-[12px]">
                    <AppInput
                        errorMessage={errorMessage}
                        id="email"
                        label="Enter email"
                        optionObject={{
                            onChange: handleChange,
                        }}
                        register={register}
                        type="email"
                    />
                    {errorMessage && (
                        <p className="text-sm text-[#C90000] dark:text-red-500 font-[500]">
                            {errorMessage}
                        </p>
                    )}
                </div>
                <AppButton
                    className="btn-primary-height m-auto sm:w-fit w-full"
                    type=""
                    variant="primary"
                >
                    Send confirmation
                </AppButton>
                <AppButton
                    className="sm:hidden btn-primary-height w-full"
                    onClick={handleCancel}
                    type=""
                    variant="btn-secondary"
                >
                    Cancel
                </AppButton>
            </AppForm>
        </AppModal>
    );
};

export default EmailChangeModal;
