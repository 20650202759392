import {
    DELETE_TRANSACTION_FAILED,
    DELETE_TRANSACTION_REQUEST,
    DELETE_TRANSACTION_SUCCESS,
    GET_TRANSACTIONS_CLEAR,
    GET_TRANSACTIONS_FAIL,
    GET_TRANSACTIONS_REQUEST,
    GET_TRANSACTIONS_SUCCESS,
} from '../actions/actionTypes';

const initialTransactions = {
    loading: false,
    transactionList: [],
    total: 0,
    page_count: 0,
    next_page: 0,
};

const initialTransaction = {
    lotsData: '',
    loading: false,
    type: '',
};

export function transactions(state = initialTransactions, action) {
    switch (action.type) {
        case GET_TRANSACTIONS_SUCCESS:
            return {
                loading: false,
                transactionList: action.data.data,
                total: action.data.total,
                page_count: action.data.page_count,
                next_page: action.data.next_page,
            };
        case GET_TRANSACTIONS_FAIL:
            return {
                loading: false,
                transactionList: action.error,
                total: 0,
                page_count: 0,
                next_page: 0,
            };
        case GET_TRANSACTIONS_REQUEST:
            return {
                loading: true,
                transactionList: [],
                total: 0,
                page_count: 0,
                next_page: 0,
            };
        case GET_TRANSACTIONS_CLEAR:
            return {
                loading: false,
                transactionList: [],
                total: 0,
                page_count: 0,
                next_page: 0,
            };

        default:
            return state;
    }
}

export function deleteTransaction(state = initialTransaction, action) {
    switch (action.type) {
        case DELETE_TRANSACTION_SUCCESS:
            return {
                lotsData: action.data,
                loading: false,
                type: 'success',
            };
        case DELETE_TRANSACTION_FAILED:
            return {
                lotsData: action.error,
                loading: false,
                type: 'danger',
            };
        case DELETE_TRANSACTION_REQUEST:
            return {
                lotsData: '',
                loading: true,
                type: '',
            };

        default:
            return state;
    }
}
