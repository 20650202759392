import {
    GET_TOP_HOLDINGS_FAILED,
    GET_TOP_HOLDINGS_REQUEST,
    GET_TOP_HOLDINGS_SUCCESS,
} from '../actions/actionTypes';

const initialHoldings = {
    loading: false,
    holdingData: [],
};

export function topHoldings(state = initialHoldings, action) {
    switch (action.type) {
        case GET_TOP_HOLDINGS_SUCCESS:
            return {
                loading: false,
                holdingData: action.data,
            };
        case GET_TOP_HOLDINGS_FAILED:
            return {
                loading: false,
                holdingData: action.error,
            };
        case GET_TOP_HOLDINGS_REQUEST:
            return {
                loading: true,
                holdingData: [],
            };

        default:
            return state;
    }
}
