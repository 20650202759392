import { useDispatch } from 'react-redux';
import { StoredCurrentCurrency } from '../../../../../investor/investor.selectors';
import { modalActions } from '../../../../actions/modal.actions';
import { userActions } from '../../../../actions/user.actions';
import { formatNumber, roundNumber } from '../../../../helpers/util';
import { AllInstallments } from '../../../../store/project/project.selectors';
import AppBadge from '../../../AppBadge';
import AppModal from '../../../AppModal';
import AppForm from '../../../AppForm';
import AppButton from '../../../AppButton';
import { useEffect, useState } from 'react';
import { CURRENCIES } from '../../../CurrencyComponent/currencyConstants';
import Pagination from '../../../Pagination';

const TotalResultsModal = ({ params, coefficient = 1, symbol = '$' }) => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [paramsObject, setParamsObject] = useState(params);
    const [disableLeftArrow, setDisableLeftArrow] = useState(true);
    const [disableRightArrow, setDisableRightArrow] = useState(false);
    const {
        installmentList,
        page_count_installment,
        next_page_instalments,
        totalAmount_instalments,
        currency_totalAmount_instalments,
    } = AllInstallments();
    const [installmentsList, setInstallmentsList] = useState([]);
    const [nextPageInstalments, setNextPageInstalments] = useState(0);
    const [installmentsPageCount, setInstallmentsPageCount] = useState(0);
    const [installmentsTotalAmount, setInstallmentsTotalAmount] = useState(0);
    const [
        currencyInstallmentsTotalAmount,
        setCurrencyInstallmentsTotalAmount,
    ] = useState(0);
    const { currency } = StoredCurrentCurrency();
    const isUsdCurrency = currency === CURRENCIES.USD;

    useEffect(() => {
        const data = { ...params, page: currentPage };
        setParamsObject(data);
    }, [currentPage]);

    useEffect(() => {
        setDisableLeftArrow(currentPage === 1);
    }, [currentPage]);

    useEffect(() => {
        setDisableRightArrow(nextPageInstalments === 0);
    }, [nextPageInstalments]);

    useEffect(() => {
        if (installmentList && installmentList.length) {
            setInstallmentsList(installmentList);
            setInstallmentsPageCount(page_count_installment);
            setNextPageInstalments(next_page_instalments);
            setInstallmentsTotalAmount(totalAmount_instalments);
            setCurrencyInstallmentsTotalAmount(
                currency_totalAmount_instalments,
            );
        }
    }, [installmentList]);

    useEffect(() => {
        dispatch(
            userActions.getAllInstallment({
                ...paramsObject,
                page: currentPage,
            }),
        );
    }, [currentPage]);

    const handleClose = () => {
        dispatch(modalActions.close_modal());
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < installmentsPageCount) {
            setCurrentPage(currentPage + 1);
        }
    };

    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
    };

    return (
        <AppModal>
            <AppForm
                className="max-w-[400px] w-full max-h-[calc(100vh_-_210px)] app-modal"
                title="Total instalments"
            >
                <AppBadge
                    className="w-fit m-auto"
                    size={21}
                    variant={'badge-green'}
                >
                    {`${formatNumber(
                        roundNumber(
                            isUsdCurrency
                                ? installmentsTotalAmount
                                : currencyInstallmentsTotalAmount,
                            2,
                        ),
                    )} ${symbol}`}
                </AppBadge>
                <div className="flex flex-col items-start gap-3 p-0">
                    {installmentsList.length > 0 &&
                        installmentsList.map((item, index) => (
                            <div
                                className="flex justify-between items-center gap-2.5 w-full bg-white sm:p-5 p-3 rounded-[10px] border-[1.5px] border-solid border-[#B5B2B2] sm:text-[15px] text-base text-black font-inter"
                                key={index}
                            >
                                <p className="font-normal">{item.created_at}</p>
                                <span className="font-bold ">{`${formatNumber(
                                    roundNumber(
                                        item[
                                            isUsdCurrency
                                                ? 'amount'
                                                : 'currency_amount'
                                        ],
                                        2,
                                    ),
                                )} ${symbol}`}</span>
                            </div>
                        ))}
                </div>
                <Pagination
                    className="m-auto"
                    count={installmentsPageCount}
                    currentPage={currentPage}
                    disableLeftArrow={disableLeftArrow}
                    disableRightArrow={disableRightArrow}
                    nextPage={nextPage}
                    paginate={paginate}
                    previousPage={previousPage}
                />
                <AppButton
                    className="m-auto sm:w-fit w-full"
                    onClick={handleClose}
                    type=""
                    variant="primary"
                >
                    Close
                </AppButton>
            </AppForm>
        </AppModal>
    );
};

export default TotalResultsModal;
