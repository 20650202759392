import {
    GET_COUNTRIES_FAILED,
    GET_COUNTRIES_REQUEST,
    GET_COUNTRIES_SUCCESS,
    GET_INDUSTRIES_FAILED,
    GET_INDUSTRIES_REQUEST,
    GET_INDUSTRIES_SUCCESS,
    GET_STYLES_GROUP_FAILED,
    GET_STYLES_GROUP_REQUEST,
    GET_STYLES_GROUP_SUCCESS,
    GET_TOTAL_FUND_FAILED,
    GET_TOTAL_FUND_REQUEST,
    GET_TOTAL_FUND_SUCCESS,
} from '../actions/actionTypes';

const initialFund = {
    loading: false,
    fundData: {},
};

const initialIndustry = {
    loading: false,
    industryData: [],
};

const initialCountry = {
    loading: false,
    countryData: [],
};

const initialStyleGroup = {
    loading: false,
    styleGroupData: [],
};

export function getTotalFund(state = initialFund, action) {
    switch (action.type) {
        case GET_TOTAL_FUND_SUCCESS:
            return {
                loading: false,
                fundData: action.data,
            };
        case GET_TOTAL_FUND_FAILED:
            return {
                loading: false,
                fundData: action.error,
            };
        case GET_TOTAL_FUND_REQUEST:
            return {
                loading: true,
                fundData: {},
            };

        default:
            return state;
    }
}
export function industryList(state = initialIndustry, action) {
    switch (action.type) {
        case GET_INDUSTRIES_SUCCESS:
            return {
                loading: false,
                industryData: action.data,
            };
        case GET_INDUSTRIES_FAILED:
            return {
                loading: false,
                industryData: action.error,
            };
        case GET_INDUSTRIES_REQUEST:
            return {
                loading: true,
                industryData: [],
            };

        default:
            return state;
    }
}
export function countryList(state = initialCountry, action) {
    switch (action.type) {
        case GET_COUNTRIES_SUCCESS:
            return {
                loading: false,
                countryData: action.data,
            };
        case GET_COUNTRIES_FAILED:
            return {
                loading: false,
                countryData: action.error,
            };
        case GET_COUNTRIES_REQUEST:
            return {
                loading: true,
                countryData: [],
            };

        default:
            return state;
    }
}
export function styleGroupList(state = initialStyleGroup, action) {
    switch (action.type) {
        case GET_STYLES_GROUP_SUCCESS:
            return {
                loading: false,
                styleGroupData: action.data,
            };
        case GET_STYLES_GROUP_FAILED:
            return {
                loading: false,
                styleGroupData: action.error,
            };
        case GET_STYLES_GROUP_REQUEST:
            return {
                loading: true,
                styleGroupData: [],
            };

        default:
            return state;
    }
}
