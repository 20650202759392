import { GET_STORED_DATA } from './actionTypes';

export const storedDataActions = {
    getStoredUser,
};

function getStoredUser() {
    return dispatch => {
        const data = JSON.parse(localStorage.getItem('userInfo'));
        dispatch(success(data));
    };
    function success(data) {
        return { type: GET_STORED_DATA, data };
    }
}
