import { useDispatch } from 'react-redux';
import { modalActions } from '../../../actions/modal.actions';
import AppButton from '../../AppButton';
import AppForm from '../../AppForm';

const UpdateForm = ({
    children,
    onSubmit,
    handleSubmit,
    title = '',
    text = '',
    btnText = '',
}) => {
    const dispatch = useDispatch();

    const handleBack = e => {
        e.preventDefault();
        dispatch(modalActions.close_modal());
    };

    return (
        <AppForm
            className="max-w-[400px] w-full"
            onSubmit={handleSubmit(onSubmit)}
            text={text}
            title={title}
        >
            <div className="flex flex-col gap-3">{children}</div>
            <div className="flex sm:flex-row flex-col items-center justify-center sm:gap-2.5 gap-2 sm:p-2.5">
                <AppButton
                    className="btn-small-height sm:w-fit w-full"
                    onClick={handleBack}
                    type=""
                    variant="btn-secondary"
                >
                    Back
                </AppButton>
                <AppButton
                    className="btn-small-height sm:w-fit w-full"
                    type=""
                    variant="primary"
                >
                    {btnText}
                </AppButton>
            </div>
        </AppForm>
    );
};

export default UpdateForm;
