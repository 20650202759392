import {
    CREATE_PASSWORD_CLEAR,
    CREATE_PASSWORD_FAILURE,
    CREATE_PASSWORD_REQUEST,
    CREATE_PASSWORD_SUCCESS,
} from '../actions/actionTypes';

const initialPassword = {
    data: {},
    loading: false,
    type: '',
};

export function createPasswordReducer(state = initialPassword, action) {
    switch (action.type) {
        case CREATE_PASSWORD_SUCCESS:
            return {
                data: action.data,
                loading: false,
                type: 'success',
            };
        case CREATE_PASSWORD_FAILURE:
            return {
                data: action.error,
                loading: false,
                type: 'danger',
            };
        case CREATE_PASSWORD_REQUEST:
            return {
                data: {},
                loading: true,
                type: 'loading',
            };
        case CREATE_PASSWORD_CLEAR:
            return {
                data: {},
                loading: false,
                type: 'clear',
            };

        default:
            return state;
    }
}
