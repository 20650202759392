import { useEffect, useMemo, useState } from 'react';
import AppInput from '../../../AppInput';
import AppModal from '../../../AppModal';
import { APPLICATION_OPERATION_TYPE } from '../../../../../investor/components/AppHome/applicationConstants';
import AppNewApplication from './component/AppNewApplication';

const SelectedApplicationModal = ({
    investmentType = '',
    title = 'New application',
    onSubmit = () => {},
    prevValues = {},
    rules,
}) => {
    const [cashType, setCashType] = useState('');
    const selectedType = useMemo(
        () =>
            APPLICATION_OPERATION_TYPE.find(
                item => item.direction === investmentType,
            ),
        [investmentType],
    );

    useEffect(() => {
        setCashType(selectedType.label);
    }, [investmentType]);

    return (
        <AppModal>
            <AppNewApplication
                cashLabel={selectedType.cash_type}
                cashType={cashType}
                direction={selectedType.direction}
                onSubmit={onSubmit}
                prevValues={prevValues}
                rules={rules}
                title={title}
            >
                <>
                    <AppInput
                        id="operation_type"
                        label="Operation"
                        readOnly={true}
                        value={cashType}
                    />
                    <p className="font-normal text-[15px] text-center text-[#0B1F35] font-roboto">
                        Please add transaction information
                    </p>
                </>
            </AppNewApplication>
        </AppModal>
    );
};

export default SelectedApplicationModal;
