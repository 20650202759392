export const BagOfMoney = ({ color = 'currentColor', style }) => (
    <svg
        fill="none"
        height="338"
        style={style}
        viewBox="0 0 200 338"
        width="200"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M151.995 21.4803C123.961 20.8141 137.459 0.177429 99.9992 0.177429C62.5399 0.177429 76.0373 20.8141 48.0035 21.4803C19.9697 22.1459 72.3625 80.7281 72.3625 80.7281H127.635C127.635 80.7281 180.028 22.1459 151.995 21.4803Z"
            fill="#FFDBAA"
        />
        <path
            d="M127.639 80.7261H113.047C113.047 80.7261 155.884 32.832 145.906 20.9355C147.689 21.2349 149.703 21.4256 151.998 21.4797C180.031 22.1472 127.639 80.7261 127.639 80.7261Z"
            fill="#FFC26D"
        />
        <path
            d="M108.897 85.8244H91.1015C88.8297 85.8244 86.8324 84.3211 86.2038 82.1377C85.903 81.0922 78.8066 56.3427 76.3429 38.6526C75.9543 35.865 77.8996 33.2898 80.6879 32.9019C83.4795 32.5114 86.0507 34.4593 86.4386 37.2476C88.2592 50.3176 92.899 68.0466 94.9723 75.6311H105.026C107.099 68.0446 111.74 50.3084 113.559 37.2476C113.947 34.4593 116.525 32.5134 119.31 32.9019C122.097 33.2905 124.043 35.865 123.655 38.6526C121.192 56.3427 114.096 81.0922 113.794 82.1377C113.166 84.3211 111.169 85.8244 108.897 85.8244Z"
            fill="#FFC26D"
        />
        <path
            d="M174.171 144.895C158.797 128.757 145.639 110.67 135.48 90.8983H64.5189C54.3592 110.671 41.2008 128.757 25.828 144.895C9.8246 161.694 0 184.431 0 209.464V282.615C0 302.167 15.8504 318.018 35.4033 318.018H164.596C184.148 318.018 199.999 302.168 199.999 282.615V209.464C199.999 184.431 190.174 161.694 174.171 144.895Z"
            fill="#FFDBAA"
        />
        <path
            d="M200 209.462V282.613C200 302.166 184.149 318.017 164.595 318.017H147.805C167.359 318.017 183.21 302.166 183.21 282.613V209.462C183.21 184.429 173.388 161.69 157.381 144.893C142.006 128.756 128.85 110.673 118.689 90.9016H135.479C145.641 110.673 158.796 128.757 174.171 144.893C190.172 161.69 200 184.429 200 209.462Z"
            fill="#FFC26D"
        />
        <path
            d="M134.311 98.7401H65.6879C59.1588 98.7401 53.8652 93.4472 53.8652 86.9175C53.8652 80.3884 59.1581 75.0948 65.6879 75.0948H134.311C140.84 75.0948 146.134 80.3877 146.134 86.9175C146.134 93.4472 140.84 98.7401 134.311 98.7401Z"
            fill="#ED6264"
        />
        <path
            d="M146.134 86.9208C146.134 90.1827 144.809 93.1385 142.668 95.2724C140.528 97.4129 137.579 98.7381 134.31 98.7381H121.82C125.089 98.7381 128.038 97.4129 130.178 95.2724C132.319 93.1385 133.644 90.1827 133.644 86.9208C133.644 80.3904 128.35 75.0968 121.82 75.0968H134.31C140.841 75.0968 146.134 80.3904 146.134 86.9208Z"
            fill="#E84649"
        />
        <path
            d="M127.297 227.885V331.013C127.297 336.504 121.181 339.786 116.601 336.755L100 325.746L83.3991 336.755C78.8193 339.786 72.7031 336.504 72.7031 331.013V227.885C72.7031 224.087 75.7882 221.001 79.5871 221.001H120.407C124.212 221.001 127.297 224.087 127.297 227.885Z"
            fill="#F37D7E"
        />
        <path
            d="M127.297 227.885V249.903C119.604 255.427 110.172 258.682 100 258.682C89.8279 258.682 80.3951 255.428 72.7031 249.903V227.885C72.7031 224.087 75.7882 221.001 79.5871 221.001H120.407C124.212 221.001 127.297 224.087 127.297 227.885Z"
            fill="#ED6264"
        />
        <path
            d="M99.9994 249.171C120.658 249.171 137.405 232.424 137.405 211.765C137.405 191.107 120.658 174.359 99.9994 174.359C79.3407 174.359 62.5935 191.107 62.5935 211.765C62.5935 232.424 79.3407 249.171 99.9994 249.171Z"
            fill="#FFC26D"
        />
        <path
            d="M137.405 211.767C137.405 232.424 120.656 249.173 99.9994 249.173C97.6036 249.173 95.2559 248.949 92.9822 248.513C110.29 245.232 123.378 230.028 123.378 211.767C123.378 193.5 110.29 178.302 92.9822 175.022C95.2553 174.586 97.6036 174.361 99.9994 174.361C120.656 174.362 137.405 191.104 137.405 211.767Z"
            fill="#FFA730"
        />
        <path
            d="M108.578 219.535H106.574V199.144C106.574 197.378 105.66 195.737 104.157 194.809C102.654 193.881 100.777 193.797 99.1979 194.586L90.9314 198.72C88.4142 199.979 87.3937 203.04 88.6523 205.558C89.9109 208.076 92.9729 209.094 95.4901 207.837L96.3806 207.392V219.536H94.3766C91.562 219.536 89.2803 221.817 89.2803 224.632C89.2803 227.447 91.562 229.728 94.3766 229.728H108.578C111.393 229.728 113.674 227.447 113.674 224.632C113.674 221.817 111.393 219.535 108.578 219.535Z"
            fill="#FFA730"
        />
        <path
            d="M109.967 137.868H91.6913C88.8767 137.868 86.595 135.586 86.595 132.771C86.595 129.957 88.8767 127.675 91.6913 127.675H109.967C112.782 127.675 115.063 129.957 115.063 132.771C115.063 135.586 112.782 137.868 109.967 137.868Z"
            fill="#FFC26D"
        />
        <path
            d="M122.409 156.531H79.2492C76.4345 156.531 74.1528 154.249 74.1528 151.434C74.1528 148.619 76.4345 146.338 79.2492 146.338H122.409C125.224 146.338 127.505 148.62 127.505 151.434C127.505 154.249 125.224 156.531 122.409 156.531Z"
            fill="#FFC26D"
        />
    </svg>
);
export const EditIcon = ({ color = 'currentColor', width, height }) => (
    <svg
        fill="none"
        height={height || '21'}
        viewBox="0 0 20 21"
        width={width || '20'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.16659 4.8441H4.99992C4.55789 4.8441 4.13397 5.01969 3.82141 5.33225C3.50885 5.64481 3.33325 6.06874 3.33325 6.51077V15.6774C3.33325 16.1195 3.50885 16.5434 3.82141 16.8559C4.13397 17.1685 4.55789 17.3441 4.99992 17.3441H14.1666C14.6086 17.3441 15.0325 17.1685 15.3451 16.8559C15.6577 16.5434 15.8333 16.1195 15.8333 15.6774V11.5108M14.6549 3.66577C14.8087 3.50658 14.9926 3.37961 15.1959 3.29226C15.3993 3.20491 15.618 3.15894 15.8393 3.15701C16.0605 3.15509 16.28 3.19726 16.4848 3.28106C16.6897 3.36486 16.8758 3.48862 17.0322 3.64511C17.1887 3.80159 17.3125 3.98768 17.3963 4.19251C17.4801 4.39734 17.5223 4.6168 17.5203 4.8381C17.5184 5.0594 17.4724 5.2781 17.3851 5.48144C17.2977 5.68478 17.1708 5.86869 17.0116 6.02243L9.85659 13.1774H7.49992V10.8208L14.6549 3.66577Z"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        />
    </svg>
);
export const IDCardIcon = ({ color = 'currentColor', style }) => (
    <svg
        fill="none"
        height="167"
        style={style}
        viewBox="0 0 166 167"
        width="166"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M100.172 40.1475V53.8273C100.172 55.9434 98.4895 57.6595 96.4145 57.6595H69.5826C67.5105 57.6595 65.8276 55.9434 65.8276 53.8273V40.1475H10.5267C4.71383 40.1475 0 44.9545 0 50.8824V146.16C0 152.088 4.71383 156.895 10.5267 156.895H155.473C161.286 156.895 166 152.088 166 146.16V50.8824C166 44.9545 161.286 40.1475 155.473 40.1475H100.172Z"
            fill="white"
        />
        <path
            d="M96.4146 11.6257H69.5827C67.5105 11.6257 65.8276 13.3086 65.8276 15.3836V53.6638C65.8276 55.7359 67.5105 57.4188 69.5855 57.4188H96.4174C98.4896 57.4188 100.172 55.7359 100.172 53.6609V15.3836C100.172 13.3086 98.4896 11.6257 96.4146 11.6257ZM83 37.3843C78.2576 37.3843 74.4138 33.5406 74.4138 28.7981C74.4138 24.0557 78.2576 20.2119 83 20.2119C87.7425 20.2119 91.5863 24.0557 91.5863 28.7981C91.5863 33.5406 87.7425 37.3843 83 37.3843Z"
            fill="#424A60"
        />
        <path
            d="M63.4978 120.831L52.7164 115.324C51.9808 114.946 51.5172 114.191 51.5172 113.364V110.064C57.0496 107.68 60.2293 103.049 61.7777 97.2559C64.1103 96.5318 65.8275 94.3738 65.8275 91.8123V88.9502C65.8275 86.7579 64.5654 84.8661 62.7422 83.9044C61.4801 73.1402 54.3649 68.7841 42.931 68.7841C42.3901 68.7841 41.8577 68.8041 41.3368 68.8471C37.4501 69.1562 33.5176 68.4263 30.2835 66.2454C28.9383 65.341 27.8078 64.3765 27.0122 63.3662C26.6487 62.9054 25.9246 63.2002 25.9904 63.7812C26.1049 64.7829 26.2766 65.9506 26.5285 67.2271C27.4729 71.9782 26.9091 71.2855 25.0717 75.7675C24.0556 78.2461 23.4002 80.9965 23.0968 83.9101C21.288 84.8804 20.0344 86.7665 20.0344 88.9502V91.8123C20.0344 94.3738 21.7517 96.5318 24.0843 97.2559C25.6326 103.046 28.8095 107.68 34.3448 110.064V113.341C34.3448 114.179 33.8697 114.944 33.1169 115.316L22.0665 120.756C19.0499 122.402 17.1724 125.565 17.1724 128.999V131.621C17.1724 131.621 17.1724 132.391 17.1724 132.791C17.1724 136.026 28.7036 137.605 42.931 137.605C57.1583 137.605 68.6896 136.026 68.6896 132.791C68.6896 132.391 68.6896 131.621 68.6896 131.621V129.228C68.6896 125.673 66.6804 122.422 63.4978 120.831Z"
            fill="#9169F6"
        />
        <path
            d="M146.795 124.076C146.277 124.617 145.965 125.364 145.965 126.109C145.965 126.853 146.252 127.597 146.795 128.141C147.336 128.656 148.083 128.971 148.827 128.971C149.572 128.971 150.316 128.656 150.859 128.141C151.375 127.597 151.689 126.853 151.689 126.109C151.689 125.364 151.375 124.617 150.859 124.076C149.801 123.046 147.883 123.017 146.795 124.076Z"
            fill="#AFB6BB"
        />
        <path
            d="M85.8621 80.3669H114.483C116.065 80.3669 117.345 79.0876 117.345 77.5048C117.345 75.9221 116.065 74.6428 114.483 74.6428H85.8621C84.2793 74.6428 83 75.9221 83 77.5048C83 79.0876 84.2793 80.3669 85.8621 80.3669Z"
            fill="#AFB6BB"
        />
        <path
            d="M88.7241 123.298H85.8621C84.2793 123.298 83 124.577 83 126.16C83 127.743 84.2793 129.022 85.8621 129.022H88.7241C90.3069 129.022 91.5862 127.743 91.5862 126.16C91.5862 124.577 90.3069 123.298 88.7241 123.298Z"
            fill="#AFB6BB"
        />
        <path
            d="M105.897 123.298H100.173C98.5899 123.298 97.3105 124.577 97.3105 126.16C97.3105 127.743 98.5899 129.022 100.173 129.022H105.897C107.479 129.022 108.759 127.743 108.759 126.16C108.759 124.577 107.479 123.298 105.897 123.298Z"
            fill="#AFB6BB"
        />
        <path
            d="M120.207 123.298H117.345C115.762 123.298 114.483 124.577 114.483 126.16C114.483 127.743 115.762 129.022 117.345 129.022H120.207C121.79 129.022 123.069 127.743 123.069 126.16C123.069 124.577 121.79 123.298 120.207 123.298Z"
            fill="#AFB6BB"
        />
        <path
            d="M137.379 123.298H131.655C130.072 123.298 128.793 124.577 128.793 126.16C128.793 127.743 130.072 129.022 131.655 129.022H137.379C138.962 129.022 140.241 127.743 140.241 126.16C140.241 124.577 138.962 123.298 137.379 123.298Z"
            fill="#AFB6BB"
        />
        <path
            d="M85.8621 97.5393H148.828C150.41 97.5393 151.69 96.26 151.69 94.6773C151.69 93.0945 150.41 91.8152 148.828 91.8152H85.8621C84.2793 91.8152 83 93.0945 83 94.6773C83 96.26 84.2793 97.5393 85.8621 97.5393Z"
            fill="#AFB6BB"
        />
        <path
            d="M148.828 108.988H85.8621C84.2793 108.988 83 110.267 83 111.85C83 113.432 84.2793 114.712 85.8621 114.712H148.828C150.41 114.712 151.69 113.432 151.69 111.85C151.69 110.267 150.41 108.988 148.828 108.988Z"
            fill="#AFB6BB"
        />
        <g clipPath="url(#clip0_297_304)">
            <path
                d="M142.516 13.6566L148.379 25.538C148.787 26.3643 149.575 26.9369 150.487 27.0692L163.6 28.9746C165.897 29.3086 166.813 32.1304 165.152 33.7495L155.664 42.9978C155.004 43.6409 154.703 44.5681 154.859 45.4758L157.098 58.5348C157.491 60.8219 155.09 62.5658 153.036 61.4867L141.309 55.3216C140.493 54.8932 139.518 54.8932 138.703 55.3216L126.975 61.4867C124.921 62.5668 122.52 60.8219 122.913 58.5348L125.152 45.4758C125.308 44.5681 125.007 43.6409 124.348 42.9978L114.86 33.7495C113.198 32.1293 114.115 29.3075 116.412 28.9746L129.524 27.0692C130.436 26.9369 131.224 26.3643 131.632 25.538L137.496 13.6566C138.522 11.5755 141.489 11.5755 142.516 13.6566Z"
                fill="#ED8A19"
            />
        </g>
        <defs>
            <clipPath id="clip0_297_304">
                <rect
                    fill="white"
                    height="51.989"
                    transform="translate(114.011 10.9606)"
                    width="51.989"
                />
            </clipPath>
        </defs>
    </svg>
);
export const DeleteIcon = ({ color = 'currentColor' }) => (
    <svg
        fill="none"
        height="18"
        viewBox="0 0 16 18"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.3335 8.16667V13.1667M9.66683 8.16667V13.1667M1.3335 4.83333H14.6668M13.8335 4.83333L13.111 14.9517C13.0811 15.3722 12.8929 15.7657 12.5844 16.053C12.2759 16.3403 11.87 16.5 11.4485 16.5H4.55183C4.13028 16.5 3.72439 16.3403 3.4159 16.053C3.10742 15.7657 2.91926 15.3722 2.88933 14.9517L2.16683 4.83333H13.8335ZM10.5002 4.83333V2.33333C10.5002 2.11232 10.4124 1.90036 10.2561 1.74408C10.0998 1.5878 9.88784 1.5 9.66683 1.5H6.3335C6.11248 1.5 5.90052 1.5878 5.74424 1.74408C5.58796 1.90036 5.50016 2.11232 5.50016 2.33333V4.83333H10.5002Z"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        />
    </svg>
);
