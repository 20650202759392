import classNames from 'classnames';
import './index.css';

export default function AppButton({
    disabled = false,
    className = '',
    variant = 'primary',
    size = '',
    loading = false,
    loadingMessage = '',
    children,
    ...rest
}) {
    const classes = classNames(
        'btn flex',
        variant,
        className,
        `${disabled ? 'disabled' : ''}`,
    );
    return (
        <button
            className={`${classes}`}
            {...rest}
            disabled={disabled || loading}
        >
            {children}
        </button>
    );
}
