import { userService } from '../../common/services';

import { applicationsActions } from '../../common/actions/applications.actions';
import { modalActions } from '../../common/actions/modal.actions';
import {
    CLEAR_APPLICATION,
    CREATE_APPLICATION_FAILED,
    CREATE_APPLICATION_REQUEST,
    CREATE_APPLICATION_SUCCESS,
} from './actionTypes';

export const applicationActions = {
    createApplication,
    clearApplication,
};

function createApplication(data) {
    const endpoint = '/applications';
    return dispatch => {
        dispatch(request());
        userService.PostInfo(endpoint, data).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: {
                            message:
                                'Your operation has been created, our manager will contact with you during the day',
                            button: true,
                        },
                    }),
                );
                dispatch(applicationsActions.getApplications());
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: CREATE_APPLICATION_REQUEST };
    }
    function success(data) {
        return { type: CREATE_APPLICATION_SUCCESS, data };
    }
    function fail(error) {
        return { type: CREATE_APPLICATION_FAILED, error };
    }
}

function clearApplication() {
    return { type: CLEAR_APPLICATION };
}
