import { useAppSelector } from '../common/store/store.helper';

export const StoredCurrentCurrency = () =>
    useAppSelector(state => state.investor.storedCurrentCurrency);
export const CreateApplication = () =>
    useAppSelector(state => state.investor.createApplication);
export const CreatePasswordReducer = () =>
    useAppSelector(state => state.investor.createPasswordReducer);
export const SetCurrentCurrency = () =>
    useAppSelector(state => state.investor.setCurrentCurrency);
