import classnames from 'classnames';
import { roundNumber } from '../../helpers/util';
import AppAvatar from '../AppAvatar';

export default function HoldersList({ list = [], selectedItems = [] }) {
    const classes = classnames(['flex flex-col items-start w-full']);
    return (
        <div className={classes}>
            {Array.isArray(selectedItems) &&
                selectedItems?.map((item, index) => (
                    <div
                        className={`flex flex-row flex-wrap w-full items-center justify-between px-0 py-[9px] ${
                            index !== list.length - 1 ? 'border-b' : ''
                        } border-solid font-inter`}
                        key={index}
                    >
                        <div className="flex flex-row flex-wrap items-center gap-2 ">
                            <AppAvatar name={item.full_name} />
                            <div className="font-inter">
                                <p className="font-semibold text-base text-gray-900">
                                    {item.full_name}
                                </p>
                                <p className="font-normal text-xs text-gray-500">
                                    {item.email}
                                </p>
                            </div>
                        </div>
                        <p className="text-base font-semibold text-gray-900 font-inter">
                            {`${roundNumber(item.structure)}%`}
                        </p>
                    </div>
                ))}
        </div>
    );
}
