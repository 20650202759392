import { modalActions } from '../../common/actions/modal.actions';
import { objectToQuery } from '../../common/helpers/queryHelper';
import { userService } from '../../common/services';
import {
    DELETE_TRANSACTION_FAILED,
    DELETE_TRANSACTION_REQUEST,
    DELETE_TRANSACTION_SUCCESS,
    GET_TRANSACTIONS_FAIL,
    GET_TRANSACTIONS_REQUEST,
    GET_TRANSACTIONS_SUCCESS,
} from './actionTypes';

export const managerCashActions = {
    getTransactions,
    deleteTransaction,
};

function getTransactions(dataObject) {
    const paramsObject = { ...dataObject };
    const queryParams = objectToQuery(paramsObject);
    const endpoint = `/cash/transactions?${queryParams}`;
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results.transactions));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_TRANSACTIONS_REQUEST };
    }
    function success(data) {
        return { type: GET_TRANSACTIONS_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_TRANSACTIONS_FAIL, error };
    }
}

function deleteTransaction(dataId) {
    return dispatch => {
        dispatch(request());
        userService.DeleteInfo(`/cash/${dataId}`).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: {
                            message: `${data.data.message}`,
                            button: true,
                        },
                    }),
                );
                dispatch(managerCashActions.getTransactions());
            } else {
                dispatch(fail(data.data.message));
                dispatch(modalActions.close_modal());
                dispatch(
                    modalActions.open_modal({
                        name: 'forbidden',
                        props: { text: `${data.data.message}` },
                    }),
                );
            }
        });
    };
    function request() {
        return { type: DELETE_TRANSACTION_REQUEST };
    }
    function success(data) {
        return { type: DELETE_TRANSACTION_SUCCESS, data };
    }
    function fail(error) {
        return { type: DELETE_TRANSACTION_FAILED, error };
    }
}
