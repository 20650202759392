import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { groupsActions } from '../../../../../manager/actions/groups.actions';
import AppModal from '../../../AppModal';
import UpdateForm from '../../ModalFormComponents/updateForm';

const DeleteGroupModal = ({ groupName, groupId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleSubmit } = useForm({ mode: 'onChange' });
    const onSubmit = () => {
        return dispatch(groupsActions.deleteGroup(groupId, navigate));
    };

    return (
        <AppModal>
            <UpdateForm
                btnText="Yes, delete"
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                title="Hey!"
            >
                <p className="font-normal text-[15px] text-center text-[#0B1F35] font-roboto">
                    Are you sure, that you want to delete group{' '}
                    <strong>{groupName}</strong> ?
                </p>
            </UpdateForm>
        </AppModal>
    );
};

export default DeleteGroupModal;
