import {
    CHANGE_USER_PASSWORD_CLEAR,
    CHANGE_USER_PASSWORD_FAILED,
    CHANGE_USER_PASSWORD_REQUEST,
    CHANGE_USER_PASSWORD_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
    loading: false,
    data: '',
    type: '',
};
export function changePassword(state = initialState, action) {
    switch (action.type) {
        case CHANGE_USER_PASSWORD_SUCCESS:
            return {
                data: action.data,
                loading: false,
                type: 'success',
            };
        case CHANGE_USER_PASSWORD_FAILED:
            return {
                loading: false,
                data: action.error,
                type: 'danger',
            };
        case CHANGE_USER_PASSWORD_REQUEST:
            return {
                loading: true,
                data: '',
                type: '',
            };
        case CHANGE_USER_PASSWORD_CLEAR:
            return {
                loading: false,
                data: '',
                type: '',
            };
        default:
            return state;
    }
}
