import { useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useController, useForm } from 'react-hook-form';
import { cashActions } from '../../actions/cash.action';
import { modalActions } from '../../actions/modal.actions';
import { preventValue, roundNumber } from '../../helpers/util';
import useUserHoldings from '../../hooks/useUserHoldings';
import {
    CashOut,
    UserHolding,
    Users,
} from '../../store/project/project.selectors';
import AppButton from '../AppButton';
import { CASH_OUT_TYPE } from '../../../manager/components/AppCash/cashConstants';
import { AppDatePicker } from '../AppDatePicker';
import AppForm from '../AppForm';
import AppInput from '../AppInput';
import AppSelect from '../AppSelect';

const AppCashOutForm = ({
    children,
    selectedInvestmentType,
    text = '',
    title = '',
}) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        clearErrors,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onChange' || '',
    });
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const { user } = Users();
    const { holdingData } = UserHolding();
    const { cashOutData, type } = CashOut();
    const { field } = useController({ name: 'seller_id', control });
    const { value: sellerValue, onChange: sellerOnChange } = field;
    const [amountValue, setAmountValue] = useState('');
    const [userId, setUserId] = useState('');
    const [roundedHolding] = useUserHoldings(userId);

    const {
        field: { value: buyerValue, onChange: buyerOnChange },
    } = useController({ name: 'buyer_id', control });

    useEffect(() => {
        dispatch(cashActions.clearCashOut());
    }, []);

    useEffect(() => {
        if (cashOutData && type === 'danger') {
            setErrorMessage(cashOutData);
        }
    }, [cashOutData, type]);

    useEffect(() => {
        if (cashOutData && type === 'danger') {
            setErrorMessage('');
        }
    }, []);

    const onSubmit = userData => {
        return dispatch(
            cashActions.cashOut({
                ...userData,
                cashout_type: selectedInvestmentType,
            }),
        );
    };

    const currentHoldings = useMemo(() => {
        return roundedHolding.current_holdings;
    }, [roundedHolding]);

    useEffect(() => {
        setAmountValue(currentHoldings);
    }, [currentHoldings]);

    const handleBack = () => {
        dispatch(modalActions.close_modal());
    };

    const handleSelect = selected => {
        sellerOnChange(selected);
        setUserId(selected);
    };

    const userList = useMemo(
        () =>
            user.length > 0 &&
            user.map(item => {
                return {
                    value: item.id,
                    label: item.full_name,
                };
            }),
        [user],
    );

    const handleChange = () => {
        setErrorMessage('');
    };

    const preventMinus = e => {
        preventValue(e);
    };
    const handleAllOut = e => {
        e.preventDefault();
        clearErrors('amount');
        if (Object.keys(holdingData).length > 0) {
            const currentHoldings = roundNumber(holdingData.current_holdings);
            setValue('amount', parseFloat(currentHoldings));
            setAmountValue(currentHoldings);
        }
    };

    return (
        <AppForm
            className="max-w-[400px] w-full max-h-[calc(100vh_-_210px)]"
            onSubmit={handleSubmit(onSubmit)}
            text={text}
            title={title}
        >
            <>
                {children}
                <div className="flex flex-col gap-[12px]">
                    <AppSelect
                        id="seller_id"
                        list={userList}
                        onChange={handleSelect}
                        placeholder="Investor (seller)"
                        selectedValue={sellerValue}
                    />
                    {selectedInvestmentType === CASH_OUT_TYPE.Inside && (
                        <AppSelect
                            id="buyer_id"
                            list={userList}
                            onChange={buyerOnChange}
                            placeholder="Investor (buyer)"
                            selectedValue={buyerValue}
                        />
                    )}
                    <AppInput
                        error={errors}
                        id="amount"
                        label="Sum of cash, $"
                        onKeyPress={preventMinus}
                        optionObject={{
                            required: true,
                            validate: value => {
                                return (
                                    Object.keys(holdingData).length === 0 ||
                                    parseFloat(value) <=
                                        parseFloat(amountValue) ||
                                    'The sum can not be bigger, than total cash meaning'
                                );
                            },
                            onChange: handleChange,
                        }}
                        register={register}
                        step="any"
                        type="number"
                    />
                    <p className="font-bold text-[15px] text-black font-roboto text-end">
                        <button onClick={handleAllOut} type="submit">
                            All out
                        </button>
                    </p>
                    <AppDatePicker
                        error={errors}
                        handleChange={handleChange}
                        id="created_at"
                        label="Date"
                        register={register}
                    />
                    {errorMessage && (
                        <p className="text-sm text-[#C90000] dark:text-red-500 font-[500]">
                            {errorMessage}
                        </p>
                    )}
                </div>
                <div className="flex flex-wrap items-center md:justify-self-auto justify-center p-2.5 gap-2.5 h-[79px]">
                    <AppButton
                        className="max-w-[134px] btn-small-height"
                        onClick={handleBack}
                        type=""
                        variant="btn-secondary"
                    >
                        Back
                    </AppButton>
                    <AppButton
                        className="max-w-fit btn-small-height"
                        disabled={!isValid}
                        type=""
                        variant="primary"
                    >
                        Save
                    </AppButton>
                </div>
            </>
        </AppForm>
    );
};

export default AppCashOutForm;
