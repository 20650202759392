import { useDispatch } from 'react-redux';
import { modalActions } from '../../../../actions/modal.actions';
import { Users } from '../../../../store/project/project.selectors';
import AppButton from '../../../AppButton';
import AppForm from '../../../AppForm';
import AppModal from '../../../AppModal';
import HoldersList from '../../../DashboardContainer/HoldersList';

const HoldersListModal = () => {
    const dispatch = useDispatch();
    const { user } = Users();

    const handleClose = () => {
        dispatch(modalActions.close_modal());
    };

    return (
        <AppModal>
            <AppForm
                className="max-w-[400px] w-full max-h-[calc(100vh_-_210px)] app-modal"
                title="Holders list"
            >
                <HoldersList list={user} selectedItems={user} />
                <div className="py-[10px]">
                    <AppButton
                        className="btn-small-height sm:m-auto sm:w-fit w-full"
                        onClick={handleClose}
                        type=""
                        variant="primary"
                    >
                        Close
                    </AppButton>
                </div>
            </AppForm>
        </AppModal>
    );
};

export default HoldersListModal;
