import {
    LOG_IN_CLEAR,
    LOG_IN_FAILED,
    LOG_IN_REQUEST,
    LOG_IN_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
    data: {},
    loading: false,
    type: '',
    token: '',
    role: 'Public_user',
};

export function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOG_IN_SUCCESS:
            localStorage.setItem(
                'userInfo',
                JSON.stringify({
                    loading: false,
                    type: 'success',
                    token: action.token,
                    role: action.role,
                    id: action.data.id,
                    currency: action.data.currency,
                }),
            );
            return {
                data: action.data,
                loading: false,
                type: 'success',
                token: action.token,
                role: action.role,
                currency: action.data.currency,
            };
        case LOG_IN_FAILED:
            localStorage.setItem(
                'userInfo',
                JSON.stringify({
                    loading: false,
                    type: 'danger',
                    token: '',
                    role: 'Public_user',
                    id: '',
                }),
            );
            return {
                data: action.error,
                loading: false,
                type: 'danger',
                token: '',
                role: '',
            };
        case LOG_IN_REQUEST:
            localStorage.setItem(
                'userInfo',
                JSON.stringify({
                    loading: true,
                    type: 'loading',
                    token: '',
                    role: 'Public_user',
                }),
            );
            return {
                data: {},
                loading: true,
                type: 'loading',
                token: '',
                role: '',
            };
        case LOG_IN_CLEAR:
            localStorage.setItem(
                'userInfo',
                JSON.stringify({
                    loading: false,
                    type: 'success',
                    token: '',
                    role: 'Public_user',
                    id: '',
                }),
            );
            return {
                data: {},
                loading: false,
                type: 'success',
                token: '',
                role: 'Public_user',
            };

        default:
            return state;
    }
}
