import {
    GET_APPLICATIONS_FAILED,
    GET_APPLICATIONS_REQUEST,
    GET_APPLICATIONS_SUCCESS,
} from '../actions/actionTypes';

const initialApplicationList = {
    applicationLoading: false,
    applicationList: [],
};

export function getApplications(state = initialApplicationList, action) {
    switch (action.type) {
        case GET_APPLICATIONS_SUCCESS:
            return {
                applicationLoading: false,
                applicationList: action.data,
            };
        case GET_APPLICATIONS_FAILED:
            return {
                applicationLoading: false,
                applicationList: action.error,
            };
        case GET_APPLICATIONS_REQUEST:
            return {
                applicationLoading: true,
                applicationList: [],
            };

        default:
            return state;
    }
}
