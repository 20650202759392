import { CLOSE_MODAL, OPEN_MODAL } from './actionTypes';

export const modalActions = {
    open_modal,
    close_modal,
};
function open_modal(data) {
    return {
        type: OPEN_MODAL,
        name: data.name,
        props: data?.props,
    };
}

function close_modal() {
    return {
        type: CLOSE_MODAL,
    };
}
