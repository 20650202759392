import {
    GET_NOTIFICATIONS_FAILED,
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_SUCCESS,
    UPDATE_NOTIFICATIONS_FAILED,
    UPDATE_NOTIFICATIONS_REQUEST,
    UPDATE_NOTIFICATIONS_SUCCESS,
} from '../actions/actionTypes';

const initialNotifications = {
    loading: false,
    notifications: [],
};

const initialData = {
    loading: false,
    data: '',
    type: '',
};

export function getNotifications(state = initialNotifications, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                loading: false,
                notifications: action.data,
            };
        case GET_NOTIFICATIONS_FAILED:
            return {
                loading: false,
                notifications: action.error,
            };
        case GET_NOTIFICATIONS_REQUEST:
            return {
                loading: true,
                notifications: [],
            };
        default:
            return state;
    }
}

export function updateNotifications(state = initialData, action) {
    switch (action.type) {
        case UPDATE_NOTIFICATIONS_SUCCESS:
            return {
                loading: false,
                data: action.data,
                type: 'success',
            };
        case UPDATE_NOTIFICATIONS_FAILED:
            return {
                loading: false,
                data: action.error,
                type: 'danger',
            };
        case UPDATE_NOTIFICATIONS_REQUEST:
            return {
                loading: true,
                data: '',
                type: '',
            };
        default:
            return state;
    }
}
