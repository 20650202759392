import AppForm from '../AppForm';

export default function ({ title, text, Icon, button }) {
    return (
        <div className="flex flex-col justify-center  items-center sm:gap-[32px] gap-5 w-full">
            <span>{Icon}</span>
            <AppForm className="sm:w-[400px]" text={text} title={title}>
                {button && <div className="flex justify-center">{button}</div>}
            </AppForm>
        </div>
    );
}
