import { useEffect, useMemo, useState } from 'react';
import {
    CASH_IN_OPERATION_TYPE,
    INVESTMENT_TYPE,
} from '../../../../../../manager/components/AppCash/cashConstants';
import AppCashInForm from '../../../../AppCashInForm';
import AppModal from '../../../../AppModal';
import AppInput from '../../../../AppInput';

const CashInSelected = ({ investmentType = '' }) => {
    const [cashInType, setCashInType] = useState('');

    const selectedType = useMemo(
        () =>
            CASH_IN_OPERATION_TYPE.find(item => item.value == investmentType)
                .label,
        [CASH_IN_OPERATION_TYPE],
    );

    useEffect(() => {
        setCashInType(
            investmentType === '1'
                ? INVESTMENT_TYPE.Installment
                : INVESTMENT_TYPE.Dividend,
        );
    }, [investmentType]);

    return (
        <AppModal>
            <AppCashInForm selectedInvestmentType={cashInType} title="Cash in">
                <>
                    <AppInput
                        id="operation_type"
                        label="Operation"
                        readOnly={true}
                        value={selectedType}
                    />
                    <p className="font-normal text-[15px] text-center text-[#0B1F35] font-roboto">
                        Please add transaction information
                    </p>
                </>
            </AppCashInForm>
        </AppModal>
    );
};

export default CashInSelected;
