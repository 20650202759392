import { useDispatch } from 'react-redux';
import { modalActions } from '../../../../actions/modal.actions';
import { ForbidIcon } from '../../../../assets/icons/Users';
import AppAuthSuccessForm from '../../../AppAuthSuccessForm';
import AppButton from '../../../AppButton';
import AppModal from '../../../AppModal';

const OopsModal = ({ text = '' }) => {
    const dispatch = useDispatch();
    const handleOkey = () => {
        dispatch(modalActions.close_modal());
    };
    return (
        <AppModal>
            <AppAuthSuccessForm
                button={
                    <AppButton
                        className="btn-primary-height sm:w-fit w-full"
                        onClick={handleOkey}
                        type=""
                        variant="primary"
                    >
                        Okey
                    </AppButton>
                }
                Icon={<ForbidIcon />}
                text={text}
                title="Oops!"
            />
        </AppModal>
    );
};

export default OopsModal;
