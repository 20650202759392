import {
    GET_CHART_CLEAR,
    GET_CHART_FAIL,
    GET_CHART_REQUEST,
    GET_CHART_SUCCESS,
} from '../actions/actionTypes';

const initialChart = {
    loading: false,
    data: {},
};

export function chart(state = initialChart, action) {
    switch (action.type) {
        case GET_CHART_SUCCESS:
            return {
                loading: false,
                data: action.data,
            };
        case GET_CHART_FAIL:
            return {
                loading: false,
                data: action.error,
            };
        case GET_CHART_REQUEST:
            return {
                loading: true,
                data: {},
            };
        case GET_CHART_CLEAR:
            return {
                loading: false,
                data: {},
            };

        default:
            return state;
    }
}
