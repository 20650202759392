import { objectToQuery } from '../../common/helpers/queryHelper';
import { userService } from '../../common/services';
import {
    GET_PORTFOLIO_CHART_FAIL,
    GET_PORTFOLIO_CHART_REQUEST,
    GET_PORTFOLIO_CHART_SUCCESS,
} from './actionTypes';

export const dayChartActions = { getDayChart };

function getDayChart(dataObject) {
    const paramsObject = { ...dataObject };
    const queryParams = objectToQuery(paramsObject);
    const endpoint = `/funds/chart?${queryParams}`;
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results.chart));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_PORTFOLIO_CHART_REQUEST };
    }
    function success(data) {
        return { type: GET_PORTFOLIO_CHART_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_PORTFOLIO_CHART_FAIL, error };
    }
}
