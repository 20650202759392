import { userService } from '../../common/services';
import {
    GET_CURRENCIES_FAILED,
    GET_CURRENCIES_REQUEST,
    GET_CURRENCIES_SUCCESS,
} from './actionTypes';

export const currencyActions = {
    getCurrencies,
};

function getCurrencies() {
    const endpoint = '/currencies';
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results.currencies));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_CURRENCIES_REQUEST };
    }
    function success(data) {
        return { type: GET_CURRENCIES_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_CURRENCIES_FAILED, error };
    }
}
