import { AppDatePicker } from '../AppDatePicker';
import AppInput from '../AppInput';

export function AppInputFromArray({
    id,
    type,
    label,
    optionObject,
    register,
    errorMessage,
    onKeyPress,
    min,
    step,
    rest,
    handleChange = () => {},
}) {
    return (
        <div>
            {type === 'date' ? (
                <AppDatePicker
                    errorMessage={errorMessage}
                    handleChange={handleChange}
                    id={id}
                    label={label}
                    register={register}
                />
            ) : (
                <AppInput
                    errorMessage={errorMessage}
                    id={id}
                    label={label}
                    min={min}
                    onKeyPress={onKeyPress}
                    optionObject={optionObject}
                    register={register}
                    step={step}
                    type={type}
                    {...rest}
                />
            )}
            {errorMessage && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500 font-[500]">
                    {errorMessage}
                </p>
            )}
        </div>
    );
}
