import {
    PASSWORD_RECOVERY_FAILURE,
    PASSWORD_RECOVERY_REQUEST,
    PASSWORD_RECOVERY_SUCCESS,
    RESET_PASSWORD_CLEAR,
} from '../actions/actionTypes';

export function passwordRecoveryReducer(state = {}, action) {
    switch (action.type) {
        case PASSWORD_RECOVERY_SUCCESS:
            return {
                data: action.data,
                loading: false,
                type: 'success',
            };
        case PASSWORD_RECOVERY_FAILURE:
            return {
                data: action.error,
                loading: false,
                type: 'danger',
            };
        case PASSWORD_RECOVERY_REQUEST:
            return {
                data: {},
                loading: true,
                type: 'loading',
            };
        case RESET_PASSWORD_CLEAR:
            return {
                data: {},
                loading: false,
                type: 'clear',
            };

        default:
            return state;
    }
}
