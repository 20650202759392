import { useAppSelector } from '../store.helper';

export const LoginReducer = () =>
    useAppSelector(state => state.project.loginReducer);
export const PasswordRecoveryReducer = () =>
    useAppSelector(state => state.project.passwordRecoveryReducer);
export const ResetPasswordReducer = () =>
    useAppSelector(state => state.project.resetPasswordReducer);
export const UserInfo = () => useAppSelector(state => state.project.userInfo);
export const UserData = () => useAppSelector(state => state.project.userData);
export const ChangeEmail = () =>
    useAppSelector(state => state.project.changeEmail);
export const ChangePassword = () =>
    useAppSelector(state => state.project.changePassword);
export const ConfirmPassword = () =>
    useAppSelector(state => state.project.confirmPassword);
export const NewUser = () => useAppSelector(state => state.project.newUser);
export const Users = () => useAppSelector(state => state.project.users);
export const Installments = () =>
    useAppSelector(state => state.project.installments);
export const Withdrawals = () =>
    useAppSelector(state => state.project.withdrawals);
export const UpdateProfile = () =>
    useAppSelector(state => state.project.updateProfile);
export const DeleteUser = () =>
    useAppSelector(state => state.project.deleteUser);
export const CreatedUser = () =>
    useAppSelector(state => state.project.createdUser);
export const AllInstallments = () =>
    useAppSelector(state => state.project.allInstallments);
export const AllWithdrawals = () =>
    useAppSelector(state => state.project.allWithdrawals);
export const GetTotalFund = () =>
    useAppSelector(state => state.project.getTotalFund);
export const CashIn = () => useAppSelector(state => state.project.cashIn);
export const CashOut = () => useAppSelector(state => state.project.cashOut);
export const UserHolding = () =>
    useAppSelector(state => state.project.userHolding);
export const IndustryList = () =>
    useAppSelector(state => state.project.industryList);
export const CountryList = () =>
    useAppSelector(state => state.project.countryList);
export const StyleGroupList = () =>
    useAppSelector(state => state.project.styleGroupList);
export const StoredData = () =>
    useAppSelector(state => state.project.storedData);
export const Chart = () => useAppSelector(state => state.project.chart);
export const TopHoldings = () =>
    useAppSelector(state => state.project.topHoldings);
export const GetApplications = () =>
    useAppSelector(state => state.project.getApplications);
