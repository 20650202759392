import Select from 'react-select';

export default function ({
    id,
    placeholder = '',
    list,
    onChange,
    selectedValue = '',
    errorMessage,
    variant = 'primary',
    rest,
}) {
    const selectStyles = {
        control: styles => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '10px',
            height: '58px',
            background: '#FFFFFF',
            border: `1.5px solid ${errorMessage ? '#E84649' : '#B5B2B2'} `,
            borderRadius: '10px',
            fontWeight: '400',
            fontSize: '15px',
            fontFamily: 'Roboto',
        }),
        input: ({ ...css }) => ({
            ...css,
            'input:focus': {
                width: 0,
                height: 0,
                borderWidth: 0,
                boxShadow: 'none',
            },
        }),
        menu: ({ ...css }) => ({
            ...css,
            marginLeft: '2px',
            maxWidth: '390px',
            width: '100%',
        }),
        option: (styles, { isDisabled, isSelected }) => {
            return {
                ...styles,
                background: isSelected ? '#EBEBEB' : '#FFFFFF',
                fontWeight: '400',
                fontSize: '15px',
                color: '#707070',
                fontFamily: 'Roboto',
                width: '100%',
                cursor: isDisabled ? 'not-allowed' : 'default',
                zIndex: '99',
            };
        },
        singleValue: defaultStyles => ({
            ...defaultStyles,
            color: '#707070',
        }),
    };
    const selectSmall = {
        control: styles => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px',
            height: '34px',
            background: '#FFFFFF',
            border: `1.5px solid ${errorMessage ? '#E84649' : '#B5B2B2'} `,
            borderRadius: '10px',
            fontWeight: '400',
            fontSize: '15px',
            fontFamily: 'Roboto',
            cursor: 'pointer',
        }),
        input: ({ ...css }) => ({
            ...css,
            'input:focus': {
                width: 0,
                height: 0,
                borderWidth: 0,
                boxShadow: 'none',
            },
        }),
        menu: ({ ...css }) => ({
            ...css,
            marginLeft: '2px',
            maxWidth: '390px',
            width: '100%',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: '0px',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            width: '13px',
            right: '10px',
            position: 'relative',
        }),
        option: (styles, { isDisabled, isSelected }) => {
            return {
                ...styles,
                background: isSelected ? '#EBEBEB' : '#FFFFFF',
                fontWeight: '400',
                fontSize: '15px',
                color: '#707070',
                fontFamily: 'Roboto',
                width: '100%',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                zIndex: '99',
            };
        },
        singleValue: defaultStyles => ({
            ...defaultStyles,
            color: '#707070',
        }),
    };

    return (
        <div>
            <Select
                components={{
                    IndicatorSeparator: () => null,
                }}
                id={id}
                onChange={option => onChange(option ? option.value : option)}
                options={list}
                placeholder={placeholder}
                styles={variant === 'primary' ? selectStyles : selectSmall}
                value={
                    selectedValue
                        ? list.find(x => x.value === selectedValue)
                        : selectedValue
                }
                {...rest}
            />
            {errorMessage && (
                <p className="mt-3 text-sm text-red-600 dark:text-red-500 font-[500]">
                    {errorMessage}
                </p>
            )}
        </div>
    );
}
