import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../../actions/user.actions';
import AppModal from '../../../AppModal';
import UpdateForm from '../../ModalFormComponents/updateForm';

const ResendInvitationModal = ({ email, userId }) => {
    const dispatch = useDispatch();
    const { handleSubmit } = useForm({ mode: 'onChange' });

    const onSubmit = () => {
        return dispatch(userActions.resendInvitation(userId));
    };

    return (
        <AppModal>
            <UpdateForm
                btnText="Yes, send"
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                title="Hey!"
            >
                <p className="font-normal text-[15px] text-center text-[#0B1F35] font-roboto">
                    Do you want to resend an invitation letter to{' '}
                    <strong>{`${email} ?`} </strong>
                </p>
            </UpdateForm>
        </AppModal>
    );
};

export default ResendInvitationModal;
