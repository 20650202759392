import AppModal from '../../../AppModal';
import AppAuthSuccessForm from '../../../AppAuthSuccessForm';
import { useDispatch } from 'react-redux';
import AppButton from '../../../AppButton';
import { EmailIcon } from '../../../../assets/icons/AuthSvg';
import { useEffect, useState } from 'react';
import { personalSettingsActions } from '../../../../actions/pesonalSettings.actions';

const CheckEmailModal = ({ user, type }) => {
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsDisabled(true);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsDisabled(false);
        }, 60000);

        return () => clearTimeout(timeout);
    }, [isDisabled]);

    const handleClick = e => {
        e.preventDefault();
        setIsDisabled(true);
        if (user) {
            if (type === 'email') {
                dispatch(personalSettingsActions.changeEmail(user));
            } else if (type === 'password') {
                dispatch(personalSettingsActions.changePassword(user));
            }
        }
    };

    return (
        <AppModal>
            <AppAuthSuccessForm
                button={
                    <AppButton
                        className="btn-primary-height sm:w-fit w-full"
                        disabled={isDisabled}
                        onClick={handleClick}
                        variant="primary"
                    >
                        Send again
                    </AppButton>
                }
                Icon={<EmailIcon />}
                text="We have sent you password recovery instruction, if your inbox is empty please check spam folder and if still nothing press the button below"
                title="Check your email"
            />
        </AppModal>
    );
};

export default CheckEmailModal;
