import { userService } from '../../common/services';

import { modalActions } from '../../common/actions/modal.actions';
import { fundActions } from '../../common/actions/fund.action';
import {
    ADD_GROUP_CLEAR,
    ADD_GROUP_FAILED,
    ADD_GROUP_REQUEST,
    ADD_GROUP_SUCCESS,
    DELETE_GROUP_FAILURE,
    DELETE_GROUP_REQUEST,
    DELETE_GROUP_SUCCESS,
    GET_GROUP_CLEAR,
    GET_GROUP_CLEAR_SYMBOLS,
    GET_GROUP_FAILED,
    GET_GROUP_FAILED_SYMBOLS,
    GET_GROUP_REQUEST,
    GET_GROUP_REQUEST_SYMBOLS,
    GET_GROUP_SUCCESS,
    GET_GROUP_SUCCESS_SYMBOLS,
    GET_GROUPS_FAILED,
    GET_GROUPS_REQUEST,
    GET_GROUPS_SUCCESS,
    GET_REALIZED_GAIN_FAILED,
    GET_REALIZED_GAIN_REQUEST,
    GET_REALIZED_GAIN_SUCCESS,
    UPDATE_GROUP_CLEAR,
    UPDATE_GROUP_FAILED,
    UPDATE_GROUP_REQUEST,
    UPDATE_GROUP_SUCCESS,
} from './actionTypes';

export const groupsActions = {
    addGroup,
    updateGroup,
    deleteGroup,
    getGroup,
    addGroupClear,
    getGroups,
    getGroupSymbols,
    updateGroupClear,
    getGroupClear,
    getRealizedGain,
    getGroupsClear,
    getGroupSymbolsClear,
};

function addGroup(data) {
    const endpoint = '/funds/groups';
    return dispatch => {
        dispatch(request());
        userService.PostInfo(endpoint, data).then(data => {
            if (data.status === 200) {
                dispatch(groupsActions.getGroups());
                dispatch(modalActions.close_modal());
                dispatch(fundActions.getStyleGroupList());
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: ADD_GROUP_REQUEST };
    }
    function success(data) {
        return { type: ADD_GROUP_SUCCESS, data };
    }
    function fail(error) {
        return { type: ADD_GROUP_FAILED, error };
    }
}

function addGroupClear() {
    return { type: ADD_GROUP_CLEAR };
}

function updateGroup(data, groupId) {
    const endpoint = `/funds/groups/${groupId}`;
    return dispatch => {
        dispatch(request());
        userService.PatchInfo(endpoint, data).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data));
                dispatch(modalActions.close_modal());
                dispatch(groupsActions.getGroup(groupId));
                dispatch(groupsActions.getGroups());
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: UPDATE_GROUP_REQUEST };
    }
    function success(data) {
        return { type: UPDATE_GROUP_SUCCESS, data };
    }
    function fail(error) {
        return { type: UPDATE_GROUP_FAILED, error };
    }
}

function updateGroupClear() {
    return { type: UPDATE_GROUP_CLEAR };
}

function deleteGroup(groupId, navigate) {
    return dispatch => {
        dispatch(requestLoader());
        userService.DeleteInfo(`/funds/groups/${groupId}`).then(data => {
            if (data.status === 200) {
                successLoader(data?.data?.message);
                dispatch(groupsActions.getGroups());
                dispatch(modalActions.close_modal());
                navigate('/admin/fund/assets');
                dispatch(
                    modalActions.open_modal({
                        name: 'authSuccess',
                        props: { button: true, isNavigated: true },
                    }),
                );
            } else {
                dispatch(failureLoader(data?.data?.message));
            }
        });
    };

    function requestLoader() {
        return { type: DELETE_GROUP_REQUEST };
    }

    function successLoader(user) {
        return { type: DELETE_GROUP_SUCCESS, user };
    }

    function failureLoader(error) {
        return { type: DELETE_GROUP_FAILURE, error };
    }
}

function getGroupClear() {
    return { type: GET_GROUP_CLEAR };
}

function getGroup(id) {
    const endpoint = `/funds/groups/${id}`;
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_GROUP_REQUEST };
    }
    function success(data) {
        return { type: GET_GROUP_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_GROUP_FAILED, error };
    }
}

function getGroups() {
    const endpoint = '/funds/groups';
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_GROUPS_REQUEST };
    }
    function success(data) {
        return { type: GET_GROUPS_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_GROUPS_FAILED, error };
    }
}

function getGroupsClear() {
    return { type: GET_GROUP_CLEAR };
}

function getGroupSymbols(id) {
    const endpoint = `/funds/groups/${id}/symbols`;
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_GROUP_REQUEST_SYMBOLS };
    }
    function success(data) {
        return { type: GET_GROUP_SUCCESS_SYMBOLS, data };
    }
    function fail(error) {
        return { type: GET_GROUP_FAILED_SYMBOLS, error };
    }
}

function getGroupSymbolsClear() {
    return { type: GET_GROUP_CLEAR_SYMBOLS };
}

function getRealizedGain(groupId) {
    const endpoint = `/funds/groups/${groupId}/realized-gain`;
    return dispatch => {
        dispatch(request());
        userService.GetInfo(endpoint).then(data => {
            if (data.status === 200) {
                dispatch(success(data.data.results.group));
            } else {
                dispatch(fail(data.data.message));
            }
        });
    };
    function request() {
        return { type: GET_REALIZED_GAIN_REQUEST };
    }
    function success(data) {
        return { type: GET_REALIZED_GAIN_SUCCESS, data };
    }
    function fail(error) {
        return { type: GET_REALIZED_GAIN_FAILED, error };
    }
}
