import { Component } from 'react';
import uniqid from 'uniqid';
import '../../assets/styles/index.css';

class AppInput extends Component {
    render() {
        const {
            id,
            label,
            value,
            optionObject,
            type = 'text',
            error = {},
            placeholder = '',
            register,
            errorMessage,
            readOnly,
            ...rest
        } = this.props;

        let register_method = register;
        const uid = uniqid();
        let error_message = errorMessage;

        if (register === undefined) {
            register_method = () => ({});
        }

        if (error && error[id]?.message) {
            error_message = true;
        }
        return (
            <div className="relative">
                <input
                    {...rest}
                    className={`custom-input px-5 ${
                        error_message ? 'border-[#E84649]' : 'border-[#B5B2B2]'
                    }  ${
                        error_message
                            ? 'focus:border-[#E84649]'
                            : ' focus:border-[#B5B2B2]'
                    } peer`}
                    defaultValue={value}
                    readOnly={readOnly}
                    {...register_method(`${id}`, {
                        ...optionObject,
                        required: 'This field is required.',
                    })}
                    id={uid}
                    name={id}
                    placeholder={!label ? placeholder : ' '}
                    type={type}
                />
                <label
                    className="font-normal font-helvetica absolute sm:text-[15px] text-base text-[#707070] dark:text-gray-400
                            duration-300 transform -translate-y-2.5 scale-75 top-4  origin-[0] left-[21px] peer-placeholder-shown:scale-100
                            peer-placeholder-shown:translate-y-0
                            peer-focus:scale-75 peer-focus:-translate-y-2.5 peer-focus:left-[21px] floating-label"
                    htmlFor={uid}
                >
                    {label}
                </label>
                {error && error[id]?.message && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500 font-[500]">
                        {error[id]?.message}
                    </p>
                )}
            </div>
        );
    }
}

export default AppInput;
