export const TIMESPAN = {
    DAY_1: '1D',
    DAY_5: '5D',
    MONTH_1: '1M',
    MONTH_6: '6M',
    YTD: 'YTD',
    YEAR_1: '1Y',
    YEAR_3: '3Y',
    YEAR_5: '5Y',
};

export const PERIOD_LIST = [
    { value: 'DAY_1', label: '1D' },
    { value: 'DAY_5', label: '5D' },
    {
        value: 'MONTH_1',
        label: '1M',
    },
    {
        value: 'MONTH_6',
        label: '6M',
    },
    { value: 'YTD', label: 'YTD' },
    {
        value: 'YEAR_1',
        label: '1Y',
    },
    {
        value: 'YEAR_3',
        label: '3Y',
    },
    {
        value: 'YEAR_5',
        label: '5Y',
    },
];

export const PERIOD_BUTTONS = {
    adiam: 'adiam',
    nasdaq: 'nasdaq',
};

export const PERIOD_BUTTON_LABELS = {
    adiam: 'Adiaman',
    nasdaq: 'Nasdaq',
};

export const PERIOD_BUTTON_COLORS = {
    adiam: '#1C64F2',
    nasdaq: '#9169F6',
};
