import {
    CURRENCIES,
    CURRENCY_COEFFICIENT,
} from '../../common/components/CurrencyComponent/currencyConstants';
import {
    GET_CURRENCY,
    SET_CURRENCY,
    SET_CURRENCY_CLEAR,
} from '../actions/actionTypes';

const initialCurrency = {
    currency: CURRENCIES.USD,
    coefficient: CURRENCY_COEFFICIENT.DEFAULT,
    active: '',
};

export function setCurrentCurrency(state = initialCurrency, action) {
    switch (action.type) {
        case SET_CURRENCY:
            localStorage.setItem(
                'currentCurrency',
                JSON.stringify({
                    currency: action.data.currency,
                    coefficient: action.data.coefficient,
                    active: action.data.currency,
                }),
            );
            return {
                currency: action.data.currency,
                coefficient: action.data.coefficient,
                active: action.data.currency,
            };
        case SET_CURRENCY_CLEAR:
            localStorage.removeItem('currentCurrency');
            return {
                currency: CURRENCIES.USD,
                coefficient: CURRENCY_COEFFICIENT.DEFAULT,
                active: '',
            };

        default:
            return state;
    }
}

export function storedCurrentCurrency(state = initialCurrency, action) {
    switch (action.type) {
        case GET_CURRENCY:
            return {
                currency: action?.data?.currency,
                coefficient: action?.data?.coefficient,
                active: action?.data?.currency,
            };
        default:
            return state;
    }
}
