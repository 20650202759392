import { INVESTMENT_TYPE } from '../../../../../../manager/components/AppCash/cashConstants';
import AppCashInForm from '../../../../AppCashInForm';
import AppModal from '../../../../AppModal';

const AddInvestmentModal = () => {
    return (
        <AppModal>
            <AppCashInForm
                selectedInvestmentType={INVESTMENT_TYPE.Installment}
                text="Please, choose user and sum of invest"
                title="Adding investment"
            ></AppCashInForm>
        </AppModal>
    );
};

export default AddInvestmentModal;
