import { combineReducers } from 'redux';
import {
    addGroups,
    addLot,
    addSymbol,
    deleteApplication,
    deleteGroup,
    deleteLots,
    deleteSymbol,
    getApplicationRef,
    getCash,
    getCurrencies,
    getGroup,
    getGroups,
    getGroupSymbol,
    getLotsList,
    getNotifications,
    getRealizedGain,
    getSymbols,
    portfolioChart,
    sellLot,
    transactions,
    updateApplication,
    updateGroup,
    updateNotifications,
} from './index';

const managerReducer = combineReducers({
    getCurrencies,
    addGroups,
    updateGroup,
    getGroup,
    getGroups,
    getGroupSymbol,
    getRealizedGain,
    deleteGroup,
    getLotsList,
    deleteLots,
    addLot,
    sellLot,
    getCash,
    deleteApplication,
    updateApplication,
    getApplicationRef,
    getNotifications,
    updateNotifications,
    addSymbol,
    getSymbols,
    deleteSymbol,
    portfolioChart,
    transactions,
});

export default managerReducer;
