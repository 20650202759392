import './common/fonts/coolvetica rg.otf';
import './common/fonts/FreeSans.otf';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './common/store';
import AppRouter from './common/components/AppRouter';
import ModalsWrapper from './common/components/ModalsWrapper';
import Layout from './common/layouts/PageWithNavbars';

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Layout>
                    <ModalsWrapper>
                        <AppRouter />
                    </ModalsWrapper>
                </Layout>
            </Router>
        </Provider>
    );
}

export default App;
