import { doFetch, doFetchWithToken } from '../helpers/apiHelpers';

const Login = (url, user) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(user),
    };
    return doFetch(url, requestOptions);
};

const LogOut = url => {
    const requestOptions = {
        method: 'POST',
    };
    return doFetchWithToken(url, requestOptions);
};
const GetInfo = url => {
    const requestOptions = {
        method: 'GET',
    };
    return doFetchWithToken(url, requestOptions);
};

const PostInfo = (url, data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
    };
    return doFetchWithToken(url, requestOptions);
};

const PatchInfo = (url, data) => {
    const requestOptions = {
        method: 'PATCH',
        body: JSON.stringify(data),
    };
    return doFetchWithToken(url, requestOptions);
};

const DeleteInfo = url => {
    const requestOptions = {
        method: 'DELETE',
    };
    return doFetchWithToken(url, requestOptions);
};

const VerifyEmail = url => {
    const requestOptions = {
        method: 'GET',
    };
    return doFetchWithToken(url, requestOptions);
};

export const userService = {
    GetInfo,
    PostInfo,
    Login,
    LogOut,
    PatchInfo,
    VerifyEmail,
    DeleteInfo,
};
